import React from 'react'

export default class Page4 extends React.Component {
  render() {
    return (
      <>
          <div class="sbInf-child-box2 animated fadeIn">
              <div class="sbInf-imageBg image4"></div>
              <div class="sbInf-darkGlass2">
                <h4 className="header2" style={{ textAlign: 'left' }}>SPECIAL TRAINING, CONFRENCES AND SEMINARS</h4>
                
                <h5 className="header2" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9}}>
                SCHOOL OF LEADERS AND THE MIRACULOUS
                </h5>
                <p class="sbInf-child-p2">
                This is a three day Ministers conference that holds 
                first week of July every year to mark the beginning of 
                the academic Year, attendants is open for all Ministers 
                of the Gospel and  compulsory for all Members of the Network 
                </p>

                <h5 className="header2" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9}}>
                SCHOOL OF DELIVERANCE 
                </h5>
                <p class="sbInf-child-p2">
                This is a three day Spiritual warfare and prayer conference that 
                holds on first week of November every year to mark the Matriculation 
                ceremony of the new Students. It shall be a prayer conference for all 
                Ministers of the Gospel and compulsory for all Members of the 
                Network. At the end of this program registration of new students 
                will end and all registered students will have their School badge 
                with registration Number of the school
                </p>

                <h5 className="header2" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9}}>
                SPECIAL CLASSES
                </h5>
                <p class="sbInf-child-p2">
                Benin has French Language as her official Language but is surrounded 
                by English Speaking countries, with most of their citizens and other 
                English Speaking foreigners in Benin for commercial activities. This 
                has created the need to understanding English and French in Benin, not 
                only for social life but also for the Spread of the gospel, for this 
                reason IMES has created an opportunity for mission language classes 
                in her curriculum English and French 
                </p>


                <p class="sbInf-child-p2">
                RESUMPTION DATE  IMES ACCADEMIC YEAR   (second week of July)
                </p>
                <p class="sbInf-child-p2">
                Registration forms and further enquiries about the school are obtainable at our <br />
                administrative offices at BERAKA AND GOSPEL HOUSE
                </p>
                


                  <p class="sbInf-child-p2"></p>
                  <p class="sbInf-child-p2"></p>
                  <p class="sbInf-child-p2" style={{fontSize: 10,}}>
                    INSTITUTE OF MISSIONS AND STRATEGIC STUDIES
                  </p>
                  <p class="sbInf-child-p2" style={{fontSize: 10}}>
                     Established in the year, 2000
                  </p>
              </div>
            </div>
      </>
    )
  }
}