import React from 'react'

export default class Page1 extends React.Component {
  render() {
    return (
      <>
          <div class="sbInf-child-box2 animated fadeIn">
              <div class="sbInf-imageBg image1"></div>
              <div class="sbInf-darkGlass2">
              <h4 className="header2" style={{ textAlign: 'left'}}>OUR MISSION</h4>
                <p class="sbInf-child-p2">
                  We are saddled with the responsibility of raisingan army for 
                  the kingdom, this we do by mobilizing, recruiting, and training 
                  Missionaries and Ministers of the Gospel, Militants in Evangelism, 
                  Aggressivein Soul winning. 
                </p>
                <h5 className="header2" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9}}>
                  EQUIPPING ALL MEN WITH ALL GOSPEL FOR ALL GENERATION
                </h5>
                <h6 className="header2" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9}}>
                  SCHOOL OF WORKERS AND CHURCH LEADERS
                </h6>
                <p class="sbInf-child-p2">
                  You can now register with us in our School of Workers and Church Leaders, 
                  and be trained to be useful in your local Churches. It is also an opportunity 
                  for pastors to train their workers for effective services intheir Churches :- 
                  classes hold two (2) Mondays every Month, from 7PM  to 10 PM. call our office 
                  now for registration. 
                </p>
                <h6 className="header2" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9}}>
                  SCHOOL OF MINISTRY AND BIBLICAL / THEOLOGICAL STUDIES 
                </h6>
                <p class="sbInf-child-p2">
                  For those who have a special call for service into ministry,and service of the 
                  Lord, you can register with us now for ministerial training and preparation for 
                  full operation into fivefold ministries, prophetic andPower gift. Call our 
                  office now for registration and other information concerning schedule of Classes.
                </p>
                  <p class="sbInf-child-p2"></p>
                  <p class="sbInf-child-p2"></p>
                  <p class="sbInf-child-p2" style={{fontSize: 10,}}>
                    INSTITUTE OF MISSIONS AND STRATEGIC STUDIES
                  </p>
                  <p class="sbInf-child-p2" style={{fontSize: 10}}>
                     Established in the year, 2000
                  </p>
              </div>
            </div>
      </>
    )
  }
}