import React from 'react'
// import logo from './logo.svg';
import '../css/overall.css';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
// import { MGB } from '../ministersNetwork/mgb'
import { words } from '../../trans'
import NavigationBar from '../navigationBar'
import ExtraInfo from '../extraInfo'
import { withRouter } from "react-router";
import { FedaCheckoutButton, FedaCheckoutContainer } from 'fedapay-reactjs';
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";



class FinishSetingAccount extends React.Component {


    constructor() {
        super();
        this.state = {
            onScreen: false,
            showInfo: false,
            showInfo_ext: false,
            slideIn: false,
            item: [],
            showNave: true,
            showMore: false,
            accountVerified: false,
            amount: 0,
            plan: "No Plan Chosen",
            planExp: "",
            checkLoaded: false,
            loadingCOTdiv: true,
            userInfo: [],
        }
        // this.PUBLIC_KEY = 'pk_sandbox_0tHBqOzyZeFhzm5E3YRSzNWR';
        this.PUBLIC_KEY = 'pk_live_R3Vi7V28eBCpEG1A4-XAznns';
        this.checkoutButtonOptions = {
            public_key: this.PUBLIC_KEY,
            transaction: {
                amount: this.state.amount,
                description: 'Airtime'
            },
            currency: {
                iso: 'XOF'
            },
            button: {
                class: 'btn btn-primary',
                text: this.state.amount
                // 'Payer 100 FCFA'
            },
            onComplete(resp) {
                const FedaPay = window['FedaPay'];
                if (resp.reason === FedaPay.DIALOG_DISMISSED) {
                    alert('Vous avez fermé la boite de dialogue');
                } else {
                    alert('Transaction terminée: ' + resp.reason);
                }

                console.log(resp.transaction);
            }
        };

        this.checkoutEmbedOptions = {
            public_key: this.PUBLIC_KEY,
            transaction: {
                amount: this.state.amount,
                description: 'Airtime'
            },
            currency: {
                iso: 'XOF'
            }
        };
    }

    makeOnscreenTrue() {
        setTimeout(() => {
            this.setState({
                onScreen: true
            })
        }, 10);
        setTimeout(() => {
            this.setState({
                slideIn: true
            })
        }, 600);
    }

    showInfo(item) {
        const screenWidth = window.innerWidth
        if (screenWidth > 991) {
            this.setState({
                showInfo: true,
                showInfo_ext: true,
                item
            })
        }
    }

    hideInfo() {
        this.setState({
            showInfo_ext: false,
        })
        setTimeout(() => {
            this.setState({
                showInfo: false,
                item: []
            })
        }, 600);
    }

    componentDidMount() {
        this.makeOnscreenTrue();

        setTimeout(() => {
            this.showNave()
            this.getInfo()
        }, 200);
    }

    showNave() {
        this.setState({
            showNave: !this.state.showNave
        })
    }

    showMore() {
        this.setState({
            showMore: !this.state.showMore
        })
    }

    setPlan = (data) => {
        const { amount, plan } = data;
        // plan: "Changing Plan",
        this.setState({
            checkLoaded: false,
            loadingCOTdiv: true,
        })
        setTimeout(() => {
            this.setState({
                amount,
                plan: "Changing Plan",
            })
        }, 400);

        setTimeout(() => {
            this.setState({
                amount,
                plan: "Checking Out...",
                planExp: plan
            })
        }, 1000);
        setTimeout(() => {
            this.setState({
                checkLoaded: true
            })
        }, 1300);

    }

    endCheckOutLoudLoading = () => {
        this.setState({
            loadingCOTdiv: false
        })
    }

    getInfo = (e) => {
        const { user, userData } = this.props
        const data = { da: user };
        fetch("https://www.imesbenin.com/server/getInfo.php", { // URL
            body: JSON.stringify(data), // data you send.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // headers: {
            //   'content-type': 'application/json'
            // },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then((response) => response.json())
            .then(async (responseJson) => {
                // alert(JSON.stringify(responseJson));
                // this.setState({
                //     userInfo: responseJson
                // })
                if (responseJson === "") {
                    userData.logOut()
                    this.props.history.push("/schoolPortal");
                } else {
                    this.setState({
                        userInfo: responseJson
                    })
                    if (responseJson.email_verified === "yes") {
                        this.setState({
                            accountVerified: true
                        })
                    } else {
                        this.setState({
                            accountVerified: false
                        })
                    }
                }
            })
            .catch((error) => {
                // alert(error);
                // })
            });
    }

    completePlan = (e) => {
        const { user, userData } = this.props
        const { amount } = this.state;
        const plan = amount === 250 ? "HALF" : "FULL";
        const data = { da: user, amount: amount, name: userData.surname, plan: plan};
        fetch("https://www.imesbenin.com/server/setPlan.php", { // URL
          body: JSON.stringify(data), // data you send.
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          // headers: {
          //   'content-type': 'application/json'
          // },
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, cors, *same-origin
          redirect: 'follow', // *manual, follow, error
          referrer: 'no-referrer', // *client, no-referrer
        })
          .then((response) => response.json())
          .then(async (responseJson) => {
            // alert(responseJson);
            if (responseJson === "email not found") {
                alert("email not found, please screenshot this page and show our support team");
            } else if (responseJson === "done") {
                this.props.history.push("/onlineClasses");
            } else {
                alert("we encountered some errors, please screenshot this page and show our support team");
            }
          })
          .catch((error) => {
            alert("we encountered some errors, please screenshot this page and show our support team");
          });
      }
 
    // updatevalue = (e, i) => {
    //     const value = e.target.value
    //     const { form } = this.state;
    //     const newF = form;
    //     newF.feild_1 = e.target.value;
    //     this.setState({ form: newF })
    // }

    render() {
        const { showSpeech, showRegBox, showMIFBox, windowHeight, lang, user, userData } = this.props
        const { onScreen, showInfo, showInfo_ext, slideIn, accountVerified, userInfo, amount, planExp } = this.state
        const webHeight = window.innerWidth > 991 ? window.innerHeight - 60 : window.innerHeight - 0;
        const percent43 = webHeight * 43 / 100;
        const hotlineBoxHeight = percent43 * 60 / 100;
        const hotline_hotline_dpHeight = hotlineBoxHeight * 48 / 100
        const col_md = onScreen === true ? "col-md-10" : "col-md-7"
        const shifAnim = showInfo_ext === true ? "fadeIn" : "fadeOut"
        const slideInLeft = slideIn === true ? "slideInLeft" : ""
        const item2 = this.state.item
        const checkoutButtonOptions = {
            public_key: this.PUBLIC_KEY,
            transaction: {
                amount: this.state.amount,
                description: 'Airtime'
            },
            currency: {
                iso: 'XOF'
            },
            button: {
                class: 'btn btn-primary',
                text: this.state.amount
                // 'Payer 100 FCFA'
            },
            onComplete(resp) {
                const FedaPay = window['FedaPay'];
                if (resp.reason === FedaPay.DIALOG_DISMISSED) {
                    // alert('Vous avez fermé la boite de dialogue');
                    this.endCheckOutLoudLoading()
                } else {
                    alert('Transaction terminée: ' + resp.reason);
                }

                console.log(resp.transaction);
            }
        };

        const checkoutEmbedOptions = {
            public_key: this.PUBLIC_KEY,
            transaction: {
                amount: 327978,
                description: 'Airtime'
            },
            currency: {
                iso: 'XOF'
            },
            customer: {
                email: user,
                lastname: userInfo.name,
                firstname: userInfo.surname,
              },
            onComplete: (resp) => {
                const FedaPay = window['FedaPay'];
                if (resp.reason === FedaPay.DIALOG_DISMISSED) {
                    // alert('Vous avez fermé la boite de dialogue');
                    this.endCheckOutLoudLoading()
                } else {
                    this.completePlan()
                    // alert('Transaction terminée: ' + resp.reason);
                }

                console.log(resp.transaction);
            }
        };

        const checkoutEmbedOptions2 = {
            public_key: this.PUBLIC_KEY,
            transaction: {
                amount: 163989,
                description: 'Airtime'
            },
            currency: {
                iso: 'XOF'
            },
            customer: {
                email: user,
                lastname: userInfo.name,
                firstname: userInfo.surname,
              },
            onComplete: (resp) => {
                const FedaPay = window['FedaPay'];
                if (resp.reason === FedaPay.DIALOG_DISMISSED) {
                    // alert('Vous avez fermé la boite de dialogue');
                    this.endCheckOutLoudLoading()
                } else {
                    this.completePlan()
                    // alert('Transaction terminée: ' + resp.reason);
                }

                console.log(resp.transaction);
            }
        };

        const { showMore, showNave, } = this.state;
        const icnClass = showNave === true ? "moreOptionsHolder_Icon_rot" : "";
        const icnMore = showMore === true ? "moreOptionsHolder_Icon_rot2" : "";
        const navHeight = showNave === true ? webHeight : 40;
        const navOpacity = showNave === true ? 1 : 0;
        const navBlocker = showNave === true ? "none" : "flex";
        const topMr = showMore === true ? 0 : webHeight;
        const topMore = window.innerWidth > 991 ? 0 : topMr;
        const topOpac = showMore === true ? 1 : 0;
        const topOpacity = window.innerWidth > 991 ? 1 : topOpac;
        const spouseCss = this.state.martalSta === "Legally married" ? "fadeIn" : "fadeOut";
        const spouseHeight = this.state.martalSta === "Legally married" ? 60 : 0;
        const ckout1 = this.state.checkLoaded === false ? "zoomInLeft" : "zoomOutLeft";
        const ckout2 = this.state.checkLoaded === false ? "zoomOutRight" : "zoomInRight";

        const trans = {
            home: lang === "en" ? words.home.en : words.home.fr,
            Sp: lang === "en" ? words.Sp.en : words.Sp.fr,
            MN: lang === "en" ? words.MN.en : words.MN.fr,
            about: lang === "en" ? words.about.en : words.about.fr,
            onlineCls: lang === "en" ? words.onlineCls.en : words.onlineCls.fr,
            gallery: lang === "en" ? words.gallery.en : words.gallery.fr,
            copyrite: lang === "en" ? words.copyrite.en : words.copyrite.fr,
            establisd_y: lang === "en" ? words.establisd_y.en : words.establisd_y.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
        }

        return (
            <>
                <div class="extra_l cool_bg"></div>
                <div class="extra_l"></div>

                <div class="row body-row">
                    <div class="col-md-12 newPageCorrector">

                        <NavigationBar
                            Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
                            active={{ a: "", b: "", c: "", d: "", e: "" }}
                            clas="col-md-2 navigation-bar"
                            style={{ height: webHeight }}
                            user={user} userData={userData}
                        />

                        <div className={"child-body animeTransition " + col_md + " finishAcountSt"} style={{ height: webHeight, alignItems: "center", }}>


                            <NavigationBar
                                Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
                                active={{ a: "", b: "active", c: "", d: "", e: "" }}
                                clas="col-md-2 navigation-bar-iPhone"
                                style={{ height: navHeight, opacity: navOpacity }}
                                user={user} userData={userData}
                            />

                            {
                                accountVerified === false ?
                                    <div class="verifyEmail">
                                        <h4><i class="fa fa-clock-o"></i> Verify your Email Address</h4>
                                        <p>An mail was sent to the email address you provided, click on the link found in the mail to Veriify your Email address </p>
                                        <p style={{ color: "blue", fontSize: 10 }}>check your email</p>
                                        <p>{user}</p>
                                    </div>
                                    :
                                    null
                            }

                            <div class="activateAccount">
                                <h4>Acount Not Activated</h4>
                                <p>Choose an Account Plan to get access to the online cources, membership programmes, student QRcode and other School privilages </p>
                                <div class="plansHolder">

                                    <div class="col-md-6 plans"
                                        onClick={
                                            () => {
                                                const data = { amount: 250, plan: "you are about to check out on the HALF PAYMENT plan" };
                                                this.setPlan(data);
                                            }
                                        }
                                    >
                                        <div class="plans_">
                                            <i class="fa fa-trophy fa_big"></i>
                                            <p>HALF PAYMENT</p>
                                            <div class="privilages_holder">
                                                <p class="privilages"><i class="fa fa-calendar-o"></i> expires after a month</p>
                                                <p class="privilages"><i class="fa fa-eye fa_red"></i> access to all online lessons, except latest lessons</p>
                                                <p class="privilages"><i class="fa fa-user fa_red"></i> limited student privilages</p>
                                                <p class="privilages"><i class="fa fa-bank fa_red"></i> no issued cirtificated untill full payment</p>
                                            </div>
                                            <p class="price">250 EUROS</p>
                                        </div>
                                    </div>

                                    <div class="col-md-6 plans"
                                        onClick={
                                            () => {
                                                const data = { amount: 500, plan: "you are about to check out on the FULL PAYMENT plan" };
                                                this.setPlan(data);
                                            }
                                        }
                                    >
                                        <div class="plans_">
                                            <i class="fa fa-diamond fa_big"></i>
                                            <p>FULL PAYMENT</p>
                                            <div class="privilages_holder">
                                                <p class="privilages"><i class="fa fa-calendar"></i> expires after current accademic year</p>
                                                <p class="privilages"><i class="fa fa-eye fa_green"></i> access to all online lessons, including latest lessons</p>
                                                <p class="privilages"><i class="fa fa-user fa_green"></i> unlimited student privilages</p>
                                                <p class="privilages"><i class="fa fa-bank fa_green"></i> guranteed issued cirtificated untill full payment</p>
                                            </div>
                                            <p class="price">500 EUROS</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div>
                                <div class="checkOutDiv">

                                    <div class="col-md-6 chckOtDv_">
                                        <div class={"chckOtDv_opps animated " + ckout1}>
                                            <div class="cool_ckot_div">
                                                <p>{this.state.plan}</p>
                                            </div>
                                        </div>
                                        <div class={"chckOtDv_opps animated " + ckout2}>
                                            {
                                                this.state.plan === "No Plan Chosen" || this.state.plan === "Changing Plan" ?
                                                    null
                                                    :
                                                    // <FedaCheckoutButton options={checkoutButtonOptions} />
                                                    <div class="cool_ckot_div_">
                                                        <FedaCheckoutContainer
                                                            options={amount === 250 ? checkoutEmbedOptions2 : checkoutEmbedOptions}
                                                            style={{ height: 450, width: "100%", backgroundColor: 'rgba(0,0,0,0)' }}
                                                        />
                                                    </div>
                                            }
                                        </div>
                                    </div>

                                    <div class="col-md-6 chckOtDv_2">
                                        <div class="chckOtDv_2_div">
                                            <h3>Your Information</h3>
                                            <p class="chk_1 chckOtDv_2_div_p">{userInfo.surname} {userInfo.name}</p>
                                            <p class="chk_2 chckOtDv_2_div_p">{userInfo.email_adress}</p>
                                            <p class="chk_3 chckOtDv_2_div_p">{userInfo.phone_contacts}</p>
                                            <p class="chk_4 chckOtDv_2_div_p">{userInfo.city_of_residence}</p>
                                            <p class="chk_5 chckOtDv_2_div_p">{userInfo.country_of_residence}</p>
                                            <p class="checkOut_new_info">{planExp}</p>
                                            <p class="checkOut_new_">{this.state.plan}</p>
                                            <p class="checkOut_new">This student, by completing this payment confirms the agreement he took while registering</p>
                                        </div>
                                    </div>


                                </div>
                                {/* <FedaCheckoutContainer options={checkoutEmbedOptions}
                                    style={{ height: 500, width: "100%", backgroundColor: '#eee' }} /> */}
                            </div>

                        </div>



                    </div>
                </div>

                <ExtraInfo
                    Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
                    clas="col-md-3 extra-info"
                    style={{ height: webHeight, top: topMore, opacity: topOpacity, width: "100%" }}
                />


                <div class="moreOptionsHolder" style={{ boxShadow: "0px 0px 10px 10px rgba(0, 0, 0, 0.38)" }}>
                    <div class={"moreOptionsHolder_Icon " + icnClass + " andPlus"} onClick={() => { this.showNave() }}></div>
                    <p>|</p>
                    <div class={"moreOptionsHolder_Icon " + icnMore + " andMenu"} onClick={() => { this.showMore() }}></div>
                </div>

            </>
        );
    }

}

export default withRouter(windowSize(FinishSetingAccount));