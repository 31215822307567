import React from 'react'
// import logo from './logo.svg';
import '../../../css/overall.css';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
const link = "https://www.localhost:80"
// import { MGB } from './mgb'
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";



class Thumbnail extends React.Component {

    constructor() {
        super();
        this.state = {
            imgHeight: 0,
            imgWidth: 0,
            textTshoe: "Fetching Images...",
        }
        // this.imgRef = ""
    }

    setImageSize(){
        this.setState({
            imgHeight: this.imgRef.height,
            imgWidth: this.imgRef.width,
        })
    }

    render() {
        const { item, link } = this.props
        const { imgHeight, imgWidth } = this.state

        return (
            <Item
                original={link + item.url}
                thumbnail={link + item.url + "?size=100"}
                width={imgWidth}
                height={imgHeight}
            >
                {({ ref, open }) => (
                    <>
                    <img ref={ref} onClick={open} src={link + item.url} class="gthumbnail" />
                    <img 
                        ref={ ref2 => this.imgRef = ref2} src={link + item.url} 
                        style={{ display: "none" }}
                        onLoad={() => { this.setImageSize() }}
                    />
                    </>
                )}
            </Item>
        );
    }

}

export default windowSize(Thumbnail);