import React from 'react';
import { render } from 'react-dom'
import "./css/alt.css"
import QrReader from 'react-qr-reader'
import { words } from '../trans'

export default class QrCodeScanner extends React.Component {

    constructor() {
        super();
        this.state = {
            rendered: false,
            result: 'No result',
            showScanner: false
        }
    }

    componentDidMount() {
        this.setState({
            rendered: true,
            showScanner: true,
        })
    }

    startScan = () => {
        this.setState({
            showScanner: true,
        })
    }

    hideMIFBox() {
        this.setState({
            rendered: false
        })
        setTimeout(() => {
            this.props.hideQRCBox()
        }, 1000);
    }

    handleScan = data => {
        if (data) {
            this.setState({
                result: data
            })
        }
        this.setState({
            showScanner: false,
        })
    }
    handleError = err => {
        alert(err)
        // alert("Faild to scan document")
    }

    render() {
        const { rendered, showScanner } = this.state;
        const scroller = rendered ? "scroller2" : "";
        const fadeAnim = rendered ? "fadeIn" : "fadeOut";
        const { lang } = this.props
        const trans = {
            place_certificate: lang === "en" ? words.place_certificate.en : words.place_certificate.fr,
            Cancel: lang === "en" ? words.Cancel.en : words.Cancel.fr,
        }
        return (
            <div className={"container animated " + fadeAnim}>
                <div className="absolute_closer" onClick={() => { this.hideMIFBox() }}></div>
                <div className={"col-md-6 dialog-box " + scroller}>
                    <h3 className="header">{trans.place_certificate}</h3>
                    <div className="line"></div>
                    {showScanner ?
                        < QrReader
                            delay={4300}
                            style={{height: "100%"}}
                            onError={this.handleError}
                            onScan={this.handleScan}
                            style={{ width: '100%' }}
                        />
                        :
                        <>
                            <p>{this.state.result}</p>
                            <a onClick={() => {this.startScan()}}>Rescan</a>
                        </>
                    }

                </div>
                <p onClick={() => { this.hideMIFBox() }} className="cancel-aa animated pulse"><i className={"fa fa-remove cancel-aaa"}></i> {trans.Cancel}</p>
            </div>
        )
    }
}