import React from 'react'
// import logo from './logo.svg';
import '../../../css/overall.css';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import { Gallery, Item } from 'react-photoswipe-gallery'
import Thumbnail from './thumbnail'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import Card from './card'
import photos from './photos';
const link = "https://www.imesbenin.com"
const fn_link = "http://www.imesbenin.com/server/feeds.php"

// import { MGB } from './mgb'
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";



class Album extends React.Component {

  constructor() {
    super();
    this.state = {
      albums: [],
      mediaFiles: [],
      newAlbumName: "",
      schoolPaswod: "",
      creatingAlbum: false,
      strtCrtAlbm: false,
    }
  }

  fetchAlbums() {
    fetch(link + "/server/fetchAlbums.php", { // URL
      body: JSON.stringify(this.state), // data you send.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // headers: {
      //   'content-type': 'application/json'
      // },
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      redirect: 'follow', // *manual, follow, error
      referrer: 'no-referrer', // *client, no-referrer
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        // alert(responseJson);
        this.setState({
          albums: responseJson
        })
      })
      .catch((error) => {
        // alert(error);
      });
  }

  fetchImages() {
    fetch(link + "/server/feeds.php", { // URL
      body: JSON.stringify(this.state), // data you send.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // headers: {
      //   'content-type': 'application/json'
      // },
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      redirect: 'follow', // *manual, follow, error
      referrer: 'no-referrer', // *client, no-referrer
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        this.setState({
          mediaFiles: responseJson
        })
        // alert(JSON.stringify(services))
      })
      .catch((error) => {
        // alert(error);
      });
  }

  createAlbum() {
    fetch(link + "/server/createAlbum.php", { // URL
      body: JSON.stringify(this.state), // data you send.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // headers: {
      //   'content-type': 'application/json'
      // },
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      redirect: 'follow', // *manual, follow, error
      referrer: 'no-referrer', // *client, no-referrer
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        alert(responseJson);
        this.closeCreatingAlbum()
        this.fetchAlbums()
      })
      .catch((error) => {
        // alert(error);
      });
  }

  openCreatingAlbum() {
    this.setState({
      creatingAlbum: true,
      strtCrtAlbm: true,
    })
  }

  closeCreatingAlbum() {
    this.setState({
      creatingAlbum: false,
    })
    setTimeout(() => {
      this.setState({
        newAlbumName: "",
        schoolPaswod: "",
        creatingAlbum: false,
        strtCrtAlbm: false,
      })
    }, 600);
  }

  componentDidMount() {
    this.fetchAlbums()
    this.fetchImages()
  }

  render() {
    const { 
      showAlbums, showPhotos, showVideos, showAll, 
      showExtraAlbum, albums_, photos_, videos_, all_,
      private_, graduation_photos_, school_of_the_mir_ 
    } = this.props
    const { schoolPaswod, newAlbumName, creatingAlbum, strtCrtAlbm, albums, mediaFiles } = this.state
    const number = 0
    const amntVideos = mediaFiles.filter(i => i.format === "vid").length
    const amntPhotos = mediaFiles.filter(i => i.format === "img").length
    const amntGrdPcs = mediaFiles.filter(i => i.type === "graduation").length
    const amntSchlOM = mediaFiles.filter(i => i.type === "som").length
    const shw_crtAlbum = creatingAlbum ? "fadeIn" : "fadeOut";

    return (
      <>
        <div class="row TabScreen" style={{ overflowY: "scroll" }}>

          <div class="albumHolder">

            {albums.map((item) =>
              <Card item={item} showExtraAlbum={(config) => { showExtraAlbum(config) }}/>
            )}
            <div class="an_album">
              <div class="aAlum_card addAB" onClick={() => { this.openCreatingAlbum() }}>
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </div>

          <div class="Default_albmHldr">

            <p class="indicator">General</p>
            <div class="row"></div>
            <div class="DAH_bar col-md-3" onClick={() => { showVideos() }}>
              <div class="DAHB">
                <i class="fa fa-video-camera"></i>
                <p>{videos_}</p>
              </div>
              <p>{amntVideos}</p>
            </div>

            <div class="DAH_bar col-md-3" onClick={() => { showPhotos() }}>
              <div class="DAHB">
                <i class="fa fa-photo"></i>
                <p>{photos_}</p>
              </div>
              <p>{amntPhotos}</p>
            </div>

            <div class="DAH_bar col-md-3" onClick={() => { showAll() }}>
              <div class="DAHB">
                <i class="fa fa-th-large"></i>
                <p>{all_}</p>
              </div>
              <p>{mediaFiles.length}</p>
            </div>

            <div class="DAH_bar col-md-3" onClick={() => { showExtraAlbum({type: "static", albumName: "Private", activeTab: 5}) }}>
              <div class="DAHB">
                <i class="fa fa-lock"></i>
                <p>{private_}</p>
              </div>
              <p>{number}</p>
            </div>

            <div class="row"></div>
            <p class="indicator">Specified</p>
            <div class="row"></div>
            <div class="DAH_bar col-md-3" onClick={() => { showExtraAlbum({type: "static", albumName: "graduation", activeTab: 6}) }}>
              <div class="DAHB">
                <i class="fa fa-graduation-cap"></i>
                <p>{graduation_photos_}</p>
              </div>
              <p>{amntGrdPcs}</p>
            </div>

            <div class="DAH_bar col-md-3" onClick={() => { showExtraAlbum({type: "static", albumName: "som", activeTab: 7}) }}>
              <div class="DAHB">
                <i class="fa fa-institution"></i>
                <p>{school_of_the_mir_}</p>
              </div>
              <p>{amntSchlOM}</p>
            </div>

          </div>

          <div class="row"></div>
          <p style={{ opacity: 0 }}>.</p>

        </div>

        {
          strtCrtAlbm === true ?
            <div class={"createAlbum animated " + shw_crtAlbum}>
              <div class="CA_H">
                <h4>Create an Album</h4>
                <p>school password</p>
                <input type="password" onChange={e => this.setState({ schoolPaswod: e.target.value })} value={schoolPaswod}></input>
                <p>album name</p>
                <input type="text" onChange={e => this.setState({ newAlbumName: e.target.value })} value={newAlbumName}></input>
                <p style={{ opacity: 0 }}>.</p>
                <a class="CA_Hcancle" onClick={() => { this.closeCreatingAlbum() }}>cancel</a>
                <a class="CA_Hcontnue" onClick={() => { this.createAlbum() }}>create</a>
              </div>
            </div>
            :
            <div></div>
        }
      </>
    );
  }

}

export default windowSize(Album);