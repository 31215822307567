import React from 'react'
// import logo from './logo.svg';
import '../css/overall.css';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import TimeAgo from 'timeago-react';
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";
// const d = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
class NextToPlay extends React.Component {
    constructor() {
        super();
        this.state = {
            d: []
        }
    }
    render() {
        const { d } = this.state;
        const empty = d.length === 0 ? true : false;
        const now = new Date();
        return (
            <div class="NextToPlay">
                <h5 style={{ color: "rgba(255,255,255,0.8)" }}> {d.length} Related Videos</h5>
                {d.map((item) =>
                    <div class="p_cmt_holder__">
                        <div class="PCDp_holder__">
                            <div class="PCDp___">
                                <i class="fa fa-user"></i>
                            </div>
                        </div>
                        <div class="PCInfo_holder__">
                            <h5 class="PCInfo_e__">
                                Title
                            </h5>
                            <p class="PCInfo_c__">
                                Subtitle
                            </p>
                            <TimeAgo
                                datetime={'2016-08-08 08:08:08'}
                                locale='en-US'
                                style={{ fontSize: 8, color: "rgba(255,255,255,0.5)" }}
                            />
                        </div>

                    </div>
                )}
                {
                    empty ?
                        <div class="p_cmt_holder__">
                            <div class="PCDp_holder__">
                                <div class="PCDp___">
                                    <i class="fa fa-user"></i>
                                </div>
                            </div>
                            <div class="PCInfo_holder__">
                                <h5 class="PCInfo_e__">
                                    No related Video found
                                </h5>
                                <p class="PCInfo_c__">
                                    related videos would apear here
                                </p>
                                <TimeAgo
                                    datetime={now}
                                    locale='en-US'
                                    style={{ fontSize: 8, color: "rgba(255,255,255,0.5)" }}
                                />
                            </div>

                        </div>
                        :
                        null
                }

            </div>
        )
    }
}

export default windowSize(NextToPlay);