import React from 'react';
import "./css/registerBox.css"

export default class RegisterBox extends React.Component {

    constructor() {
        super();
        this.state = {
            rendered: false,
        }
    }

    componentDidMount() {
        this.setState({
            rendered: true
        })
    }


    hideRegBox() {
        this.setState({
            rendered: false
        })
        setTimeout(() => {
            this.props.hideRegBox()
        }, 1000);
    }

    render() {
        const { rendered } = this.state;
        const fadeInAnim = rendered ? "fadeInAnim" : "fadeOutAnim";
        const fadeAnim = rendered ? "fadeIn" : "fadeOut";
        return (
            <div className={"regContainer animated " + fadeAnim}>
                <div className="absolute_closer" onClick={() => { this.hideRegBox() }} style={{backgroundColor: "rgba(0,0,0,0.8)"}}></div>
                <div className="col-md-2" onClick={() => { this.hideRegBox() }}></div>
                
                <div className="col-md-8 hotline_box2_">
                    <h3 className="header" style={{textAlign: 'center'}}>
                        REGISTRATION PROCESS
                    </h3>
                    <p style={{color: "rgba(255,255,255,0.7)", padding: 15}}>
                        Greetings future soldier of the gospel, to begin your registration process you will need
                        professional guidance from executive school personnels. We have provided you with three
                        from which you can select. If you already know the necessary steps you have to take
                        then close this page and click on "Portals" button, which can be found in the navigation
                        bar
                    </p>
                    <div class="hotline_box2">

                        <div class={"col-md-4  animated " + fadeInAnim + " hotelineA"}>
                        <a class="hotline_hotline2" href="https://wa.ma/22996810072?text=hello,%20i%20was%20directed%20from%20the%20official%20imes%20website%20to%20your%20contact,%20i%20would%20like%20to%20make%20some%20inquiries">
                            <div class="hotline_hotline2_dp">
                                <i class="fa fa-user hotline_hotline2_dp_image"></i>
                            </div>
                            <p class="hotline_hotline2_name_p">Prophetesse Excel</p>
                            <p class="hotline_hotline2_name">the Assistant Director</p>
                            <p class="hotline_hotline2_name_pp">Click Here</p>
                            </a>
                        </div>

                        <div class={"col-md-4  animated " + fadeInAnim + " hotelineB"}>
                        <a class="hotline_hotline2" href="https://wa.ma/22997389848?text=hello,%20i%20was%20directed%20from%20the%20official%20imes%20website%20to%20your%20contact,%20i%20would%20like%20to%20make%20some%20inquiries">
                            <div class="hotline_hotline2_dp">
                                <i class="fa fa-user hotline_hotline2_dp_image"></i>
                            </div>
                            <p class="hotline_hotline2_name_p">Pastor Line Eliel</p>
                            <p class="hotline_hotline2_name">the Director of Operations</p>
                            <p class="hotline_hotline2_name_pp">Click Here</p>
                        </a>
                        </div>
                        
                        <div class={"col-md-4  animated " + fadeInAnim + " hotelineC"}>
                        <a class="hotline_hotline2" href="https://wa.ma/22966645928?text=hello,%20i%20was%20directed%20from%20the%20official%20imes%20website%20to%20your%20contact,%20i%20would%20like%20to%20make%20some%20inquiries">
                            <div class="hotline_hotline2_dp">
                                <i class="fa fa-user hotline_hotline2_dp_image"></i>
                            </div>
                            <p class="hotline_hotline2_name_p">Pastor Clement</p>
                            <p class="hotline_hotline2_name">General Surveilant </p>
                            <p class="hotline_hotline2_name_pp">Click Here</p>
                        </a>
                        </div>
                       
                    </div>
                    
                </div>
                {/* <div className="cancel-aa"></div> */}
                <p onClick={() => { this.hideRegBox() }} className="cancel-aa animated pulse"><i className={"fa fa-remove cancel-aaa"}></i> cancel</p>
            </div>
        )
    }
}