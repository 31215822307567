import React from 'react'
// import logo from './logo.svg';
import '../css/overall.css';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import TimeAgo from 'timeago-react';
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";
// const cmts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
class Comments extends React.Component {
    constructor() {
        super();
        this.state = {
            feild_1: "",
            cmts: [],
        }
    }
    render() {
        const { feild_1, cmts } = this.state
        // var timedifference = new Date().getTimezoneOffset();
        const commentText = cmts.length === 1 ? "Comment" : "Comments"
        return (
            <div class="WV_comnt_">
                <div class="comt_input_holder">
                    {/* <input class="log_in_box_input real_login comt_input_" type="text" onChange={(e) => { this.setState({ feild_1: e.target.value }) }} value={feild_1}></input> */}
                    <textarea class=" comt_input_" type="text" onChange={(e) => { this.setState({ feild_1: e.target.value }) }} value={feild_1} placeholder="Type your Comment here"></textarea>
                    <div class="comt_input_button">
                        <i class="fa fa-send"></i>
                    </div>
                </div>

                <h5 style={{color:"rgba(255,255,255,0.8)", marginTop:20}}> {cmts.length} {commentText}</h5>

                {cmts.map((item) =>
                    <div class="p_cmt_holder">
                        <div class="PCDp_holder">
                            <div class="PCDp_">
                                <i class="fa fa-user"></i>
                            </div>
                        </div>
                        <div class="PCInfo_holder">
                            <h5 class="PCInfo_e">
                                jerrysplendour62074@gmail.com
                            </h5>
                            <p class="PCInfo_c">
                                this is a random comment, and has nothing to do with this post.
                                this is a random comment, and has nothing to do with this post.
                                this is a random comment, and has nothing to do with this post.
                                this is a random comment, and has nothing to do with this post.
                                this is a random comment, and has nothing to do with this post
                            </p>
                            <TimeAgo
                                datetime={'2016-08-08 08:08:08'}
                                locale='en-US'
                                style={{ fontSize: 8 }}
                            />
                        </div>

                    </div>
                )}
            </div>
        )
    }
}

export default windowSize(Comments);