import React from 'react';
import { render } from 'react-dom'
import "./css/about.css"
import { words } from '../trans'

export default class PresdentsSpeech extends React.Component {

    constructor() {
        super();
        this.state = {
            rendered: false
        }
    }

    componentDidMount() {
        this.setState({
            rendered: true
        })
    }

    hideSpeech() {
        this.setState({
            rendered: false
        })
        setTimeout(() => {
            this.props.hideSpeech()
        }, 1000);
    }

    render() {
        const { rendered } = this.state;
        const scroller = rendered ? "scroller_" : "";
        const fadeAnim = rendered ? "fadeIn" : "fadeOut";
        const { lang } = this.props
        const trans = {
            about: lang === "en" ? words.about.en : words.about.fr,
            aboutSchool_p1: lang === "en" ? words.aboutSchool_p1.en : words.aboutSchool_p1.fr,
            aboutSchool_p2: lang === "en" ? words.aboutSchool_p2.en : words.aboutSchool_p2.fr,
            aboutSchool_p3: lang === "en" ? words.aboutSchool_p3.en : words.aboutSchool_p3.fr,
            ourMission: lang === "en" ? words.ourMission.en : words.ourMission.fr,
            we_a_s: lang === "en" ? words.we_a_s.en : words.we_a_s.fr,
            equiping_a_m: lang === "en" ? words.equiping_a_m.en : words.equiping_a_m.fr,
            school_o_w_a_l: lang === "en" ? words.school_o_w_a_l.en : words.school_o_w_a_l.fr,
            you_c_n_register: lang === "en" ? words.you_c_n_register.en : words.you_c_n_register.fr,
            school_o_m_a: lang === "en" ? words.school_o_m_a.en : words.school_o_m_a.fr,
            for_t_w_h: lang === "en" ? words.for_t_w_h.en : words.for_t_w_h.fr,
            benefit: lang === "en" ? words.benefit.en : words.benefit.fr,
            institute_o_m_affli: lang === "en" ? words.institute_o_m_affli.en : words.institute_o_m_affli.fr,
            accademic_session: lang === "en" ? words.accademic_session.en : words.accademic_session.fr,
            our_a_s_b: lang === "en" ? words.our_a_s_b.en : words.our_a_s_b.fr,
            matriculation_o_n: lang === "en" ? words.matriculation_o_n.en : words.matriculation_o_n.fr,
            graduation_o_s_a: lang === "en" ? words.graduation_o_s_a.en : words.graduation_o_s_a.fr,
            programme_w_o: lang === "en" ? words.programme_w_o.en : words.programme_w_o.fr,
            p_W_O_1: lang === "en" ? words.p_W_O_1.en : words.p_W_O_1.fr,
            p_W_O_2: lang === "en" ? words.p_W_O_2.en : words.p_W_O_2.fr,
            p_W_O_3: lang === "en" ? words.p_W_O_3.en : words.p_W_O_3.fr,
            p_W_O_4: lang === "en" ? words.p_W_O_4.en : words.p_W_O_4.fr,
            licence_o_f: lang === "en" ? words.licence_o_f.en : words.licence_o_f.fr,
            campuses: lang === "en" ? words.campuses.en : words.campuses.fr,
            we_r_t_c: lang === "en" ? words.we_r_t_c.en : words.we_r_t_c.fr,
            beraka_m_m_c: lang === "en" ? words.beraka_m_m_c.en : words.beraka_m_m_c.fr,
            for_R_s: lang === "en" ? words.for_R_s.en : words.for_R_s.fr,
            duration: lang === "en" ? words.duration.en : words.duration.fr,
            thier_c_i_f: lang === "en" ? words.thier_c_i_f.en : words.thier_c_i_f.fr,
            the_m_a_r: lang === "en" ? words.the_m_a_r.en : words.the_m_a_r.fr,
            a_one_y: lang === "en" ? words.a_one_y.en : words.a_one_y.fr,
            CDC_1: lang === "en" ? words.CDC_1.en : words.CDC_1.fr,
            CDC_2: lang === "en" ? words.CDC_2.en : words.CDC_2.fr,
            CDC_3: lang === "en" ? words.CDC_3.en : words.CDC_3.fr,
            CDC_4: lang === "en" ? words.CDC_4.en : words.CDC_4.fr,
            First_YRS: lang === "en" ? words.First_YRS.en : words.First_YRS.fr,
            FYRS_1: lang === "en" ? words.FYRS_1.en : words.FYRS_1.fr,
            FYRS_2: lang === "en" ? words.FYRS_2.en : words.FYRS_2.fr,
            FYRS_3: lang === "en" ? words.FYRS_3.en : words.FYRS_3.fr,
            FYRS_4: lang === "en" ? words.FYRS_4.en : words.FYRS_4.fr,
            second_YRS: lang === "en" ? words.second_YRS.en : words.second_YRS.fr,
            third_YRS: lang === "en" ? words.third_YRS.en : words.third_YRS.fr,
            advanced_p_a_l: lang === "en" ? words.advanced_p_a_l.en : words.advanced_p_a_l.fr,
            lecture_o_in: lang === "en" ? words.lecture_o_in.en : words.lecture_o_in.fr,
            for_m_t_w: lang === "en" ? words.for_m_t_w.en : words.for_m_t_w.fr,
            compulsory_f_a_l: lang === "en" ? words.compulsory_f_a_l.en : words.compulsory_f_a_l.fr,
            Sikekigi_c: lang === "en" ? words.Sikekigi_c.en : words.Sikekigi_c.fr,
            being_f_a_t: lang === "en" ? words.being_f_a_t.en : words.being_f_a_t.fr,
            special_training: lang === "en" ? words.special_training.en : words.special_training.fr,
            school_o_leaders_a_t_m: lang === "en" ? words.school_o_leaders_a_t_m.en : words.school_o_leaders_a_t_m.fr,
            this_is_a: lang === "en" ? words.this_is_a.en : words.this_is_a.fr,
            school_o_deliverance: lang === "en" ? words.school_o_deliverance.en : words.school_o_deliverance.fr,
            this_i_s_three: lang === "en" ? words.this_i_s_three.en : words.this_i_s_three.fr,
            special_classes: lang === "en" ? words.special_classes.en : words.special_classes.fr,
            benin_has_f: lang === "en" ? words.benin_has_f.en : words.benin_has_f.fr,
            resumption_date: lang === "en" ? words.resumption_date.en : words.resumption_date.fr,
            registration_forms_a: lang === "en" ? words.registration_forms_a.en : words.registration_forms_a.fr,
            administrative_o: lang === "en" ? words.administrative_o.en : words.administrative_o.fr,
            Cancel: lang === "en" ? words.Cancel.en : words.Cancel.fr,
        }
        return (
            <div className={"container_ animated " + fadeAnim}>
                <div className="absolute_closer_" onClick={() => { this.hideSpeech() }}></div>
                <div className={"dialog-box_ "}>
                    <h3 style={{ textTransform: "uppercase", paddingLeft: 20 }}>{trans.about}</h3>


                    <div className={"col-md-6 clmd6"}>
                        <div class="sbInf-imageBg image1"></div>
                        <div class="sbInf-imageGlass"></div>
                        <p className="paragraph2_">
                            <br />
                            {trans.aboutSchool_p1}
                        </p>
                        <p className="paragraph2_">
                            {trans.aboutSchool_p2}
                        </p>

                        <h4 className="header2_" style={{ textAlign: 'left', paddingTop: 20 }}>{trans.ourMission}</h4>
                        <p class="sbInf-child-p2">
                            {trans.we_a_s}
                        </p>
                    </div>

                    <div className={"col-md-6 clmd6"}>
                        <div class="sbInf-imageBg image2"></div>
                        <div class="sbInf-imageGlass"></div>
                        {/* image1 */}
                        <p className="sbInf-child-p2">
                            {trans.aboutSchool_p3}
                        </p>
                        <h5 className="header2_" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9 }}>
                            {trans.equiping_a_m}
                        </h5>
                        <h6 className="header2_" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9 }}>
                            {trans.school_o_w_a_l}
                        </h6>
                        <p class="sbInf-child-p2">
                            {trans.you_c_n_register}
                        </p>
                        <h6 className="header2_" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9 }}>
                            {trans.school_o_m_a}
                        </h6>
                        <p class="sbInf-child-p2">
                            {trans.for_t_w_h}
                        </p>
                        <p class="sbInf-child-p2"></p>
                    </div>

                    <div class="row"></div>

                    <div className={"col-md-6 clmd6"} style={{ paddingTop: 20, paddingBottom: 30 }}>
                        <div class="sbInf-imageBg image3"></div>
                        <div class="sbInf-imageGlass"></div>
                        {/* image1 */}
                        <h4 className="header2_" style={{ textAlign: 'left', paddingBottom: 0, marginBottom: 0 }}>{trans.benefit}</h4>
                        <p class="sbInf-child-p2">
                            {trans.institute_o_m_affli}
                        </p>
                        <h5 className="header2_" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9, marginTop: 0, paddingTop: 0 }}>
                            {trans.accademic_session}
                        </h5>
                        <p class="sbInf-child-p2">
                            * {trans.our_a_s_b}<br />
                            * {trans.matriculation_o_n} <br />
                            * {trans.graduation_o_s_a} <br />
                        </p>
                        <h5 className="header2_" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9 }}>
                            {trans.programme_w_o}
                        </h5>
                        <p class="sbInf-child-p2">
                            1. {trans.p_W_O_1}<br />
                            2. {trans.p_W_O_2} <br />
                            3. {trans.p_W_O_3} <br />
                            4. {trans.p_W_O_4} <br />
                            {trans.licence_o_f}
                        </p>
                        {/* <p class="sbInf-child-p2">
                            
                        </p> */}
                        {/* <p class="sbInf-child-p2"></p> */}
                        <p class="sbInf-child-p2" style={{ paddingBottom: 0, marginBottom: 0 }}>
                            {trans.campuses}
                        </p>
                        <p class="sbInf-child-p2" style={{ paddingBottom: 0, marginBottom: 0 }}>
                            {trans.we_r_t_c}
                        </p>

                        <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, marginTop: 0, paddingTop: 0 }}>
                            <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0, }}>
                                {trans.beraka_m_m_c}
                            </p>
                            <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0 }}>
                                * {trans.for_R_s}
                            </p>
                        </p>

                        <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0 }}>
                            <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0 }}>
                                {trans.duration}
                            </p>
                            <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0 }}>
                                * {trans.thier_c_i_f} <br />
                                {trans.the_m_a_r}
                            </p>
                        </p>

                        <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, }}>
                            <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0 }}>
                                COURSE DE CADRE
                            </p>
                            <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0 }}>
                                {trans.a_one_y} <br />
                                •	{trans.CDC_1} <br />
                                •	{trans.CDC_2} <br />
                                •	{trans.CDC_3} <br />
                                •	{trans.CDC_4}
                            </p>
                        </p>



                    </div>

                    <div className={"col-md-6 clmd6"} style={{ paddingTop: 20, paddingBottom: 30 }}>
                        <div class="sbInf-imageBg image4"></div>
                        <div class="sbInf-imageGlass"></div>

                        <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, }}>
                            <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0 }}>
                                {trans.First_YRS}
                            </p>
                            <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0 }}>
                                •	{trans.FYRS_1} <br />
                                •	{trans.FYRS_2} <br />
                                •	{trans.FYRS_3} <br />
                                •	{trans.FYRS_4}
                            </p>
                        </p>

                        <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, }}>
                            <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0 }}>
                                {trans.second_YRS}
                            </p>
                            <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0 }}>
                                •	{trans.FYRS_1} <br />
                                •	{trans.FYRS_2} <br />
                                {/* •	Classes runs every Second week of the month  Monday, Tuesday, Wednesday and Friday (7:00pm – 10:00pm) <br /> */}
                                •	{trans.FYRS_3} <br />
                                •	{trans.FYRS_4}
                            </p>
                        </p>

                        <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, }}>
                            <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0 }}>
                                {trans.third_YRS}
                            </p>
                            <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0 }}>
                                •	{trans.FYRS_1} <br />
                                •	{trans.FYRS_2} <br />
                                {/* •	Classes runs every Second week of the month  Monday, Tuesday, Wednesday and Friday (7:00pm – 10:00pm) <br /> */}
                                •	{trans.FYRS_3} <br />
                                •	{trans.FYRS_4}
                            </p>
                        </p>

                        <p class="sbInf-child-p2">
                            <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0 }}>
                                {trans.advanced_p_a_l}
                            </p>
                            <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0 }}>
                                •	{trans.FYRS_1} <br />
                                •	{trans.lecture_o_in} <br />
                                •	{trans.for_m_t_w} <br />
                                •	{trans.compulsory_f_a_l}
                            </p>
                        </p>

                        <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, }}>
                            <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0 }}>
                                {trans.Sikekigi_c}
                            </p>
                            <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0 }}>
                                {trans.being_f_a_t}
                            </p>
                        </p>

                    </div>

                    <div class="row"></div>

                    <div class="col-md-12 clmd6" style={{ paddingTop: 20, paddingBottom: 30, padding: 30 }}>
                        <div class="sbInf-imageBg image5"></div>
                        <div class="sbInf-imageGlass"></div>
                        <div className='sbInf-imageGlass-blur'>
                            <h4 className="header2_" style={{ textAlign: 'left' }}>{trans.special_training}</h4>

                            <h5 className="header2_" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9 }}>
                                {trans.school_o_leaders_a_t_m}
                            </h5>
                            <p class="sbInf-child-p2">
                                {trans.this_is_a}
                            </p>

                            <h5 className="header2_" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9 }}>
                                {trans.school_o_deliverance}
                            </h5>
                            <p class="sbInf-child-p2">
                                {trans.this_i_s_three}
                            </p>

                            <h5 className="header2_" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9 }}>
                                {trans.special_classes}
                            </h5>
                            <p class="sbInf-child-p2">
                                {trans.benin_has_f}
                            </p>


                            <p class="sbInf-child-p2">
                                {trans.resumption_date}
                            </p>
                            <p class="sbInf-child-p2">
                                {trans.registration_forms_a}<br />
                                {trans.administrative_o}
                            </p>
                        </div>
                    </div>

                </div>
                <p onClick={() => { this.hideSpeech() }} className="cancel-aa_ animated pulse"><i className={"fa fa-remove cancel-aaa"}></i> {trans.Cancel}</p>
            </div>
        )
    }
}