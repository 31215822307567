import React from 'react';
import { render } from 'react-dom'
import "./css/presdentsSpeech.css"

export default class MoreinfoBox extends React.Component {

    constructor(){
        super();
        this.state = {
            rendered: false
        }
    }

    componentDidMount(){
        this.setState({
            rendered: true
        })
    }

    hideMIFBox(){
        this.setState({
            rendered: false
        })
        setTimeout(() => {
            this.props.hideMIFBox()
        }, 1000);
    }

    render() {
        const { rendered } = this.state;
        const scroller = rendered ? "scroller" : "";
        const fadeAnim = rendered ? "fadeIn" : "fadeOut"; 
        return(
            <div className={"container animated " + fadeAnim}>
                <div className="absolute_closer" onClick={() => { this.hideMIFBox() }}></div>
                <div className={"col-md-6 dialog-box " + scroller}>
                    <h3 className="header">ABOUT THE SCHOOL</h3>
                    <div className="line"></div>
                    {/* <p className="paragraph">
                        On behalf of the Scientific Council, I welcome you to the official 
                        website of the Higher Institute of Communication and Management 
                        (ISCG-Benin). Here you will find information on the different training 
                        possibilities, the offers of the Institute and the work within the 
                        LMD (License-Master-Doctorate).
                    </p>
                    <p className="paragraph">
                        Dear students Welcome to the site of Cocotomey, Cotonou, located about 
                        ten kilometers from the famous international market DANTOKPA. The geographical 
                        location of the ISCG-Benin University offers a triple advantage to know: 
                        Affordable tuition fees and rents, easy accessibility to all at all levels 
                        and in a safe, convenient and quiet environment.
                    </p>
                    <p className="paragraph">
                        This is the whole authenticity of the Certificate issued by ISCG-Benin University 
                        which are co-signed by the Ministry of Higher Education and Scientific Research. 
                        We will do our utmost to allow each student to study in the best conditions in 
                        order to acquire not only the necessary knowledge, but also to develop social 
                        skills by participating in the life of the school by referring to different 
                        social programs Cultural of the school. We take seriously the seriousness of 
                        our potential students in their work and respect the rules necessary for all 
                        the collective life to achieve the objectives of the university. 
                    </p> */}
                    <p className="paragraph">
                        The Institute of Missions and Strategies Studies Benin an affiliate of Eagles 
                        wing faith center Arizona USA, is burdened with the vision of raising an Army 
                        for the kingdom. We mobilize; recruit and train Missionaries and Ministers of 
                        the gospel for the expansion of the Kingdom.
                    </p>
                </div>
                <p onClick={() => { this.hideMIFBox() }} className="cancel-aa animated pulse"><i className={"fa fa-remove cancel-aaa"}></i> cancel</p>
            </div>
        )
    }
}