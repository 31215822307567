
'use strict';

import React from 'react';
import FileUploadProgress from 'react-fileupload-progress';
import axios from 'axios';


class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            value: 0,
            error: "",
            settingsType: "",
            uploadPassWord: "imesuploadpassword",
            feild_1: "",
            hitRetry: false,
            progressTex1: "",
            progressTex2: "",
        }
    }

    updateUploadProgress = (v, ev) => {
        this.setState({
            value: v,
            progressTex1: ev.loaded,
            progressTex2: ev.total
        })
    }
    niceBytes = (a) => {
        let b = 0, c = parseInt(a, 10) || 0;
        for (; 1024 <= c && ++b;)c /= 1024;
        return c.toFixed(10 > c && 0 < b ? 1 : 0) + " " + ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][b]
    }
    fileSelect = (event) => {
        this.setState({ selectedFile: event.target.files[0] })
        // alert(event.target.files[0])\
        setTimeout(() => {
            this.fileUpload()
        }, 100);
    }
    fileUpload = () => {
        const { uploadPassWord, feild_1 } = this.state
        if (uploadPassWord === feild_1) {
            this.setState({
                error: "",
                hitRetry: false
            })
            const formData = new FormData();
            formData.append('image', this.state.selectedFile, this.state.selectedFile.name);
            const CancelToken = axios.CancelToken;
            this.source = CancelToken.source();
            axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: formData,
                cancelToken: this.source.token,
                url: 'https://www.imesbenin.com/server/upload.php',
                onUploadProgress: (ev: ProgressEvent) => {
                    const progress = ev.loaded / ev.total * 100;
                    this.updateUploadProgress(Math.round(progress), ev);
                },
            })
                .then((resp) => {
                    // our mocked response will always return true
                    // in practice, you would want to use the actual response object
                    // setUploadStatus(true);
                    // setUploading(false);
                    // getBase64(file, (uri: string) => {
                    //     setImageURI(uri);
                    // });
                    // alert(JSON.stringify(resp))
                    const { progressTex1, progressTex2} = this.state;
                    const ev = { loaded: progressTex1 , total: progressTex2 }
                    setTimeout(() => {
                        this.updateUploadProgress(0, ev);
                        this.setState({
                            error: "UPLOAD SUCCESFULL"
                        })
                    }, 600);
                })
                .catch((error) => {
                    this.setState({
                        error: error.message,
                        hitRetry: true
                    })
                    // console.error(err.message)
                });
        } else {
            this.setState({
                error: "wrong Gallery Password"
            })
        }


    }
    cancelUpload = () => {
        this.source.cancel("cancled")
    }

    openAdmin = () => {
        this.setState({
            settingsType: "admin"
        })
    }
    openUser = () => {
        this.setState({
            settingsType: "user"
        })
    }
    goBack = () => {
        this.setState({
            settingsType: ""
        })
    }

    renderAdmin = () => {
        const { value, error, feild_1, progressTex1, progressTex2 } = this.state
        return (
            <div>
                <h1>Admin Settings</h1>
                <input type="hidden" name='".ini_get("session.upload_progress.name"); ."' value="123" />
                {/* <input type="file" onChange={this.fileSelect} /> */}
                <label class="custom-file-upload">
                    <input type="file" onChange={this.fileSelect} />
                    Click to select file, or drag and drop file here
                </label>
                <div class="UP_progress" style={{ height: value === 0 ? 0 : 30 }}>
                    {/* <button onClick={this.cancelUpload}>cancle</button> */}
                    <div class="progress_bar" style={{ width: value + "%" }}></div>
                </div>
                <div class="UP_progress_">
                    {
                        value === 0 ?
                            null
                            :
                            <p>{value}%</p>
                    }
                    {
                        value === 0 ?
                            null
                            :
                            <p>{this.niceBytes(progressTex1)} of {this.niceBytes(progressTex2)}</p>
                    }
                    <p>{error}</p>
                </div>
                <h4 style={{marginBottom: 0, paddingBottom: 0, marginTop: 30}}>Gallery Password</h4>
                <p style={{marginTop: 0, paddingTop: 5, color: "black"}}>Type Gallery Password before attempting to upload</p>
                <input style={{width: "100%", marginLeft:0,}} class="log_in_box_input real_login" type="password" onChange={(e) => { this.setState({ feild_1: e.target.value }) }} value={feild_1}></input>
            </div>
        )
    }
    renderUser = () => {
        return (
            <div>
                <h1>User Settings</h1>
            </div>
        )
    }


    render() {
        const { settingsType } = this.state;
        const box1 = settingsType === "" ? "slideInLeft" : "slideOutLeft";
        const box2 = settingsType === "" ? "slideOutRight" : "slideInRight";
        return (
            <>
                <div class={"sett_box animated " + box1}>
                    <div onClick={() => { this.openUser() }} class="ST_btn"><p><i class="fa fa-user"></i> User Settings</p></div>
                    <div onClick={() => { this.openAdmin() }} class="ST_btn"><p><i class="fa fa-user-secret"></i> Admin Settings</p></div>
                </div>
                <div class={"sett_box animated " + box2} style={{ opacity: settingsType === "" ? 0 : 1 }}>
                    {
                        settingsType === "admin" ?
                            this.renderAdmin()
                            :
                            settingsType === "user" ?
                                this.renderUser()
                                :
                                <div></div>
                    }
                    <p onClick={() => { this.goBack() }} style={{ color: "rgb(177, 6, 23)", fontSize: 35, position: "absolute", top: 0, left: 7 }}><i class="fa fa-arrow-circle-left"></i></p>
                </div>
            </>
        )
    }
};



export default Settings;


