'use strict';

import React from 'react';
import './App.css';
import Home from './Site1/home'
import windowSize from 'react-window-size';
import Settings from './Site1/settings'


class App extends React.Component {
  constructor() {
    super();
    this.state = {
      lang: "en",
      user: "",
      userData: "",
      showDialog: false,
      showSettings: false
    }
  }

  componentDidMount() {
    this.getUserData()
  }


  setFn = () => {
    this.setState({
      lang: "fn"
    })
  }
  setEn = () => {
    this.setState({
      lang: "en"
    })
  }

  updateUserData = (data) => {
    const { user } = data
    localStorage.setItem("user", user);
    this.getUserData();
  }
  getUserData = () => {
    const user = localStorage.getItem("user");
    if (user === "" || user === null) {
      this.setState({
        user: ""
      })
    } else {
      this.setState({
        user
      })
    }
  }
  logOut = () => {
    localStorage.setItem("user", "");
    this.getUserData();
    this.hideDialog()
  }

  showDialog = () => {
    this.setState({
      showDialog: true
    })
  }
  hideDialog = () => {
    this.setState({
      showDialog: false
    })
  }
  showSettings = () => {
    this.setState({
      showSettings: true
    })
  }
  hideSettings = () => {
    this.setState({
      showSettings: false
    })
  }

  render() {
    const { lang, user, userData, showDialog, showSettings } = this.state;
    const halfWheight = window.innerHeight / 2;

    const newSDH = window.innerHeight > halfWheight ? window.innerHeight * 70 / 100 : window.innerHeight - 20;
    const newSDW = window.innerHeight > halfWheight ? window.innerWidth * 30 / 100 : window.innerWidth - 20;

    const newSetH = window.innerWidth > 991 ? newSDH : window.innerHeight - 20;
    const newSetW = window.innerWidth > 991 ? newSDW : window.innerWidth - 20;
    return (
      // <div style={{ padding: '16px', height: 600, width: '100%', display: "flex", justifyContent: 'center', alignItems: 'center' }}>
          
      //     <QRCode value=
      //     // "https://www.imesbenin.com/landingPage/%7B[actn]:[VE],[da]:[jerrysplendour2gmail.com]%7D" 
      //     // "https://www.imesbenin.com/landingPage/{[actn]:[QR],[da]:[LicenseAYOITO COMLAN LEOPOLD]}"
      //     // "http://localhost:3000/landingPage/%7B[actn]:[QR],[da]:[LicenseAYOITO%20COMLAN%20LEOPOLD]%7D"
      //     "https://www.imesbenin.com/landingPage/{[actn]:[QR],[da]:[LicenseAYOITO%20COMLAN%20LEOPOLD]}"
      //     // "http://localhost:3000/landingPage/{[actn]:[QR],[da]:[LicenseAYOITO%20COMLAN%20LEOPOLD]}"
      //     bgColor='rgba(0,0,0,0)'
      //     />
      //   </div>
      <>
        

        <Home lang={lang} user={user} userData={{ userData, updateUserData: this.updateUserData, logOut: this.logOut }} />
        <div
          style={{
            position: "fixed", top: 15, left: 15,
            borderRadius: 10, opacity: 0.8,
            height: 25, paddingLeft: 5, paddingRight: 5,
            overflow: 'hidden',
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0)",
            backgroundColor: "rgba(0,0,0,0.7)", display: "flex",
            alignItems: "center"
          }}
        >

          <div class="lang en en_" onClick={() => { this.setEn() }}></div>
          <div class="lang translator"></div>
          <div class="lang fr fr_" onClick={() => { this.setFn() }}></div>

        </div>
        {
          user === "" ?
            null
            :
            <div
              style={{
                position: 'fixed', bottom: 65, right: showDialog === true ? -100 : 10,
                height: 40, width: 40, backgroundColor: "rgb(177, 6, 23)", borderRadius: "100%",
                display: "flex", justifyContent: "center", alignItems: "center", transition: "0.6s",
                zIndex: 1000000
              }}
              onClick={() => { this.showDialog() }}
            >
              <p><i class="fa fa-power-off"></i></p>
            </div>
        }
        {
          user === "" ?
            null
            :
            <div
              style={{
                position: 'fixed', bottom: 15, right: showDialog === true ? -160 : 70,
                height: 40, width: 40, backgroundColor: "rgb(177, 6, 23)", borderRadius: "100%",
                display: "flex", justifyContent: "center", alignItems: "center", transition: "0.6s",
                zIndex: 1000000
              }}
              onClick={() => { this.showSettings() }}
            >
              <p><i class="fa fa-gear"></i></p>
            </div>
        }

        <div
          style={{
            position: 'fixed', bottom: 25, right: showDialog === true ? 10 : -210,
            height: 50, width: 200, backgroundColor: "rgb(177, 6, 23)", borderRadius: 6,
            display: "flex", justifyContent: "center", alignItems: "center", transition: "0.6s",
            zIndex: 10000000, boxShadow: "0px 0px 6px 4px rgba(0, 0, 0, 0.5)"
          }}
        >
          <p style={{ color: "whitesmoke", fontWeight: "bolder", fontSize: 16 }}>Log out</p>
          <p onClick={() => { this.logOut() }} style={{ color: "whitesmoke", marginLeft: 9, fontSize: 25 }}><i class="fa fa-check-circle"></i></p>
          <p onClick={() => { this.hideDialog() }} style={{ color: "whitesmoke", marginLeft: 9, fontSize: 25 }}><i class="fa fa-times-circle"></i></p>
        </div>

        <div
          style={{
            position: 'fixed',
            bottom: showSettings === true ? 10 : 30,
            right: showSettings === true ? 10 : 90,
            height: showSettings === true ? newSetH : 0,
            width: showSettings === true ? newSetW : 0,
            backgroundColor: "rgba(245, 245, 245, 0.95)",
            borderRadius: showSettings === true ? 6 : 400,
            display: "flex", justifyContent: "center",
            alignItems: "center", transition: "0.6s",
            zIndex: 20000000, boxShadow: "0px 0px 6px 4px rgba(0, 0, 0, 0.5)",
            overflow: "hidden"
          }}
          class="settingsBox"
        >
          <div
            style={{
              opacity: showSettings === true ? 1 : 0, transition: "0.6s",
              height: "100%", width: "100%", overflow: "hidden"
            }}
          >
            <Settings />
            <p onClick={() => { this.hideSettings() }} style={{ color: "rgb(177, 6, 23)", fontSize: 35, position: "absolute", top: 0, right: 7 }}><i class="fa fa-times-circle"></i></p>
          </div>
        </div>
      </>
    );
  }
}

export default windowSize(App);





// 'use strict';

// import React from 'react';
// import FileUploadProgress from 'react-fileupload-progress';
// import axios from 'axios';


// class App extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       selectedFile: null,
//       value: 0,
//       error: ""
//     }
//   }

//   updateUploadProgress =(v)=>{
//     this.setState({
//       value: v
//     })
//   }

//   fileSelect = (event) => {
//     this.setState({ selectedFile: event.target.files[0] })
//     console.log(event.target.files[0])
//   }
//   fileUpload = () => {
//     this.setState({
//       error: ""
//     })
//     const formData = new FormData();
//     formData.append('image', this.state.selectedFile, this.state.selectedFile.name);
//     const CancelToken = axios.CancelToken;
//     this.source = CancelToken.source();
//     axios({
//         method: 'post',
//         headers: {
//             'Content-Type': 'multipart/form-data',
//         },
//         data: formData,
//         cancelToken: this.source.token,
//         url: 'http://localhost:80/server/upload.php',
//         onUploadProgress: (ev: ProgressEvent) => {
//             const progress = ev.loaded / ev.total * 100;
//             this.updateUploadProgress(Math.round(progress));
//         },
//     })
//     .then((resp) => {
//         // our mocked response will always return true
//         // in practice, you would want to use the actual response object
//         // setUploadStatus(true);
//         // setUploading(false);
//         // getBase64(file, (uri: string) => {
//         //     setImageURI(uri);
//         // });
//         alert(JSON.stringify(resp))
//     })
//     .catch((error) => {
//       this.setState({
//         error: error.message
//       })
//       // console.error(err.message)
//     });


// }

// cancelUpload = () => {
//   this.source.cancel("cancled")
// }


// render() {
//   return (
//     <div>
//       <input type="hidden" name='".ini_get("session.upload_progress.name"); ."' value="123" />
//       <input type="file" onChange={this.fileSelect} />
//       <button onClick={this.fileUpload}>Upload</button>
//       <button onClick={this.cancelUpload}>cancle</button>
//       <p>{this.state.error}</p>
//       <h1>{this.state.value}</h1>
//     </div>
//   )
// }
// };



// export default App;