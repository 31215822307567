import React from 'react'

export default class Page2 extends React.Component {
  render() {
    return (
      <>
          <div class="sbInf-child-box2 animated fadeIn">
              <div class="sbInf-imageBg image2"></div>
              <div class="sbInf-darkGlass2">
              <h4 className="header2" style={{ textAlign: 'left'}}>ADMISSION CRITERIA</h4>
                <p class="sbInf-child-p2">
                  Only for born again Christians.
                </p>
                <h6 className="header2" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9}}>
                  ACADEMIC SESSION
                </h6>
                <ul>
                  <li class="sbInf-child-p2" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9}}>
                    Our academic session begins in July and ends in May of thefollowing year 
                  </li>
                  <li class="sbInf-child-p2" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9}}>
                    Graduation of Students are always in the month of May.
                  </li>
                </ul>
                  <p class="sbInf-child-p2"></p>
                  <p class="sbInf-child-p2"></p>
                  <p class="sbInf-child-p2" style={{fontSize: 10,}}>
                    INSTITUTE OF MISSIONS AND STRATEGIC STUDIES
                  </p>
                  <p class="sbInf-child-p2" style={{fontSize: 10}}>
                     Established in the year, 2000
                  </p>
              </div>
            </div>
      </>
    )
  }
}