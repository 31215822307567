import React from 'react'
import { Link } from "react-router-dom";
import { words } from '../../../trans'
import LoginRegister from '../../logInRegister'

export default class WebHome extends React.Component {
  render() {
    const showRegBox = this.props.showRegBox;
    const showMIFBox = this.props.showMIFBox;
    const lang = this.props.lang
    const user = this.props.user
    const userData = this.props.userData
    const cool_bg = window.innerWidth > 991 ? "cool_bg" : ""
    const trans = {
      institute_o: lang === "en" ? words.institute_o.en : words.institute_o.fr,
      eagles_w_f: lang === "en" ? words.eagles_w_f.en : words.eagles_w_f.fr,
      the_i_: lang === "en" ? words.the_i_.en : words.the_i_.fr,
      register: lang === "en" ? words.register.en : words.register.fr,
    }
    return (
      <>
        {
          user === "" ?
            <LoginRegister words={words} style={{ opacity: 1 }} />
            :
            null
        }
        <div class="picture-holder">
          <div class="picture picture-1"></div>
          {/* <div class="picture picture-2"></div> */}
          <div class="hoveringDiv">
            {/* <div class="excuse_child cool_bg">
              <div class="darkGlass"></div>
              <div class="lightGlass"></div>
            </div> */}
            <div class="child ">
              <div class="darkGlass" style={{ backgroundColor: "rgba(255,255,255,0.5)" }}></div>
              {/* <div class="lightGlass"></div> */}
              <h3 class="h3" style={{ fontSize: 15, marginBottom: 0, fontWeight: "bold", color: "black" }}> {trans.institute_o}</h3>
              <div class="uline12"></div>
              <p class="p" style={{ paddingRight: 10, paddingLeft: 10, fontWeight: "bold", color: "black" }}>
                {trans.eagles_w_f}
              </p>

              <p class="p" style={{ paddingRight: 10, paddingLeft: 10, color: "black" }}>
                {trans.the_i_}
              </p>
              <Link to="/schoolPortal" class="a" style={{ fontSize: 13, zIndex: 2 }}>{trans.register}</Link>
              {/* <a href="#" class="a" onClick={() => { showRegBox() }} style={{fontSize: 13, zIndex:2}}>{trans.register}</a> */}
            </div>
            {/* <div class="excuse_child cool_bg">
              <div class="darkGlass"></div>
              <div class="lightGlass"></div>
            </div> */}

            {/* <div class={"bubble " + cool_bg + " ba"}></div>
            <div class={"bubble " + cool_bg + " bb"}></div>
            <div class={"bubble " + cool_bg + " bc"}></div>
            <div class={"bubble " + cool_bg + " bd"}></div>
            <div class={"bubble " + cool_bg + " be"}></div> */}
          </div>
        </div>

        <div class="adds1">
          <div class="adds1_bg"></div>
          <div class="col-md-7 adds1_col1">
            <h1>Get the App!</h1>
          </div>
          <div class="col-md-5 adds1_col1" style={{ alignItems: "flex-start" }}>
            <div class="appInfo">
              <div class="appIconHolder">
                <div class="appIcon"></div>
                <div>
                  <p class="appName">imes online</p>
                  <p class="appName_">Available now on <br /> Google Play Store</p>
                </div>
              </div>
              <h4>Download our Android app now</h4>
              <p class="appDescription">
                Access educational and biblical courses through online streaming and
                track your progress. In the event of missing a live online class,
                rest assured that you can review recorded sessions within our
                dedicated application. These recordings will be accessible
                for a duration of one week, after which they will
                be automatically removed.
              </p>
            </div>
          </div>
        </div>



        {/* <div class="col-md-12 sub-info" style={{padding: 0}}>
            <div class="col-md-3 sub-info-child" style={{padding: 3}}>
              <Link to="/page1" class="sbInf-child-a" class="sbInf-child-box">
                <div class="sbInf-imageBg image1"></div>
                <div class="sbInf-darkGlass">
                  <p class="sbInf-child-p">
                    OUR MISSION We are saddled with the responsibility of 
                    raisingan army for the kingdom, this we do by mobilizing, 
                    recruiting, and training Missionaries and Ministers of 
                    the Gospel, Militants ... 
                  </p>
                  <a href="#" class="sbInf-child-a">continue reading</a>
                </div>
              </Link>
              <p class="sbInf-child-text"><i class="fa fa-institution icn-a"></i> OUR MISSION</p>
            </div>
            <div class="col-md-3 sub-info-child" style={{padding: 3}}>
              <Link to="/page2" class="sbInf-child-a" class="sbInf-child-box">
                <div class="sbInf-imageBg image2"></div>
                <div class="sbInf-darkGlass">
                  <p class="sbInf-child-p">
                    click here to read our entry requirements
                  </p>
                  <a href="#" class="sbInf-child-a">continue reading</a>
                </div>
              </Link>
              <p class="sbInf-child-text"><i class="fa fa-user-plus icn-a"></i> ADMISSION</p>
            </div>
            <div class="col-md-3 sub-info-child" style={{padding: 3}}>
              <Link to="/page3" class="sbInf-child-a" class="sbInf-child-box">
                <div class="sbInf-imageBg image3"></div>
                <div class="sbInf-darkGlass">
                  <p class="sbInf-child-p">
                    BENEFIT, Institute of Missions and Strategic Studies is an affiliation of Eagles 
              Wing Faith Center Arizona (USA) Address: 2655 W GuadalupeRD, Mesa, AZ 85202, 
              United States. We are also in collaboration with the International....
                  </p>
                  <a href="#" class="sbInf-child-a">continue reading</a>
                </div>
              </Link>
              <p class="sbInf-child-text"><i class="fa fa-graduation-cap icn-a-b"></i> BENEFIT</p>
            </div>
            <div class="col-md-3 sub-info-child" style={{padding: 3}}>
              <Link to="/page4" class="sbInf-child-a" class="sbInf-child-box">
                <div class="sbInf-imageBg image4"></div>
                <div class="sbInf-darkGlass">
                  <p class="sbInf-child-p">
                  SPECIAL TRAINING, CONFRENCES AND SEMINARS <br />
                    •	SCHOOL OF LEADERS AND THE MIRACULOUS <br />
                    •	SCHOOL OF DELIVERANCE <br />
                    •	SPECIAL  CLASSES ...
                  </p>
                  <a href="#" class="sbInf-child-a">continue reading</a>
                </div>
              </Link>
              <p class="sbInf-child-text"><i class="fa fa-dashboard icn-a"></i> SPECIAlS</p>
            </div>
          </div> */}

      </>
    )
  }
}