import React from 'react'
// import logo from './logo.svg';
import './css/overall.css';
import Screens from './screens';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import { words } from '../trans'
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";

class LoginRegister extends React.Component {
    render() {
        // const { showSpeech, webHeight, showQRCBox, trans } = this.props.Data;
        // const { a, b, c, d, e } = this.props.active;
        const { style, clas } = this.props;
        return (
            <div class="logInRegister" style={style}>
                <div class="LGN__" style={{zIndex: 100000}}>
                    <p>Already have an account? </p>
                    <Link to="/login" class={"LGN__-item "}>LOG IN</Link>
                </div>

                <p>|</p>

                <div class="LGN__" style={{zIndex: 100000}}>
                    <Link to="/schoolPortal" class={"LGN__-item "}>SIGN IN</Link>
                    {/* <a href='http://localhost:3000/landingPage/{[actn]:[VE],[da]:[jerrysplendour62074@gmail.com]}'>ggg</a> */}
                    {/* <a href="http://localhost:3000/landingPage/{action:'do_this_and_die',repeat:'i_refuse'}">ggg</a> */}
                    <p>If you don't have an account? </p>  
                </div>

            </div>
        )
    }
}

export default windowSize(LoginRegister);