import React from 'react'
// import logo from './logo.svg';
import '../../../css/overall.css';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
const link = "https://www.imesbenin.com"
// import { MGB } from './mgb'
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";



class Card extends React.Component {

    constructor() {
        super();
        this.state = {
            uri: "",
            images: [],
        }
        // this.imgRef = ""
    }

    fetchAlbums() {
        const ftdata = { albumName: this.props.item.albumName }
        fetch(link + "/server/ftcFromAlbum.php", { // URL
            body: JSON.stringify(ftdata), // data you send.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // headers: {
            //   'content-type': 'application/json'
            // },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then((response) => response.json())
            .then(async (responseJson) => {
                // alert(responseJson);
                this.setState({
                    images: responseJson,
                    uri: responseJson[0].url
                })
            })
            .catch((error) => {
                // alert(error);
            });
    }

    componentDidMount() {
        this.fetchAlbums()
    }

    render() {
        const { item, showExtraAlbum } = this.props
        const { images, uri } = this.state
        const heightof = 370 * 95 / 100

        return (
            <div class="an_album">
                <div class="aAlum_card albm" onClick={() => { showExtraAlbum({type: "dynamic", albumName: item.albumName, activeTab: 8}) }}>
                    {
                        uri ?
                        <img src={link + uri + "?size=100"} class="albumArt" />
                        :
                        <div class="alnArt_alt"><i class="fa fa-file-photo-o"></i></div>
                    }
                </div>
                <div class="aAlum_info">
                    <p class="AAI_name">{item.albumName}</p>
                    <p class="AAI_amnt">{images.length}</p>
                </div>
            </div>
        );
    }

}

export default windowSize(Card);