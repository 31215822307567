import React from 'react'

export default class Page3 extends React.Component {
  render() {
    return (
      <>
        <div class="sbInf-child-box2 animated fadeIn">
          <div class="sbInf-imageBg image3"></div>
          <div class="sbInf-darkGlass2">
            <h4 className="header2" style={{ textAlign: 'left' }}>BENEFIT</h4>
            <p class="sbInf-child-p2">
              Institute of Missions and Strategic Studies is an affiliation of Eagles
              Wing Faith Center Arizona (USA) Address: 2655 W GuadalupeRD, Mesa, AZ 85202,
              United States. We are also in collaboration with the International Curriculum
              of the Spirit and Video Bible School (P.O BOX 215Olean NY, USA) And international
              School of Ministries (Los. Amitos, CA, USA)i
            </p>
            <h5 className="header2" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9 }}>
              ACADEMIC SESSION
            </h5>
            <p class="sbInf-child-p2">
              * our academic session begins July and end may of the following year<br />
              * Matriculation of new Students shall always be first week of November <br />
              * Graduation of Students is always in the month of May. <br />
            </p>
            <h5 className="header2" style={{ textAlign: 'left', marginBottom: 0, paddingBottom: 0, opacity: 0.9 }}>
              PROGRAMMES WE OFFER
            </h5>
            <p class="sbInf-child-p2">
              1. Certificate in Ministerial Arts and Biblical Studies<br />
              2. Diploma in Divinity and Mission <br />
              3. Advanced Diploma in Divinity and Mission <br />
              4. Advanced Diploma in theology, Church Management and MinisterialArts.
            </p>
            <p class="sbInf-child-p2">
              License obtained from this Institute can not be subject to fraud, due to
              the fact that every certificate has a QR code inprint which can only be
              scanned via the official website
            </p>
            <p class="sbInf-child-p2"></p>
            <p class="sbInf-child-p2">
              CAMPUSES
            </p>
            <p class="sbInf-child-p2">
              We run two campuses, one at Beraka - menontin for regular Classes and another
              at Gospel House - Sikecodji for Certificate class only on Mondays
            </p>

            <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0,}}>
              <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0,  }}>
                BERAKA - MENONTIN MAIN CAMPUS
              </p>
              <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0}}>
              * For Regular Students
              </p>
            </p>

            <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0,}}>
              <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0 }}>
                DURATION
              </p>
              <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0}}>
              * Their Courses is for three years <br />
              The Minimum admission requirement for the courses is West African Examination certificate
              </p>
            </p>

            <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0,}}>
              <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0 }}>
              COURSE DE CADRE
              </p>
              <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0}}>
              a one year intensive course <br />
              •	DURATION - 10 months, (July to May of the following year) <br />
              •	DAYS OD STUDIES :- Sunday evening (7:00pm – 10:00pm) <br />
              •	Students must attend our Thursdays Spiritual emphasis and Practical From 7:00pm to 10:00pm <br />
              •	Students will be awarded Diploma in Divinity and Mission at the end of their Studies 
              </p>
            </p>

            <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0,}}>
              <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0 }}>
              FIRST YEAR REGULAR SCHOOL
              </p>
              <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0}}>
              •	DURATION - 10 months, (July to May of the following year) <br />
              •	Classes runs every first week of the month  Monday, Tuesday, Wednesday and Friday (7:00pm – 10:00pm) <br />
              •	Thursdays Spiritual emphasis and Practical From 7:00pm to 10:00pm <br />
              •	Saturdays lectures from 9:00 am to 1:00 pm
              </p>
            </p>

            <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0,}}>
              <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0 }}>
              SECOND YEAR REGULAR SCHOOL
              </p>
              <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0}}>
              •	DURATION - 10 months, (July to May of the following year) <br />
              •	Classes runs every Second week of the month  Monday, Tuesday, Wednesday and Friday (7:00pm – 10:00pm) <br />
              •	Thursdays Spiritual emphasis and Practical From 7:00pm to 10:00pm <br />
              •	Saturdays from 9:00 am to 1:00 pm
              </p>
            </p>

            <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0,}}>
              <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0 }}>
              THIRD YEAR REGULAR SCHOOL
              </p>
              <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0}}>
              •	DURATION - 10 months, (July to May of the following year) <br />
              •	Classes runs every third week of the month  Monday, Tuesday, Wednesday and Friday (7:00pm – 10:00pm) <br />
              •	Thursdays Spiritual emphasis and Practical From 7:00pm to 10:00pm <br />
              •	Saturdays from 9:00 am to 1:00 pm
              </p>
            </p>

            <p class="sbInf-child-p2">
              <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0 }}>
              ADVANCED PASTORAL AND LEADERSHIP CLASS 
              </p>
              <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0}}>
              •	DURATION - 10 months, (June to May of the following year)   <br />
              •	Lecture, once in a day in a Month from  (9:00am – 1:00 pm) <br />
              •	For ministers that want to improve themselves as special vessels for the master <br />
              •	Compulsory for all the lecturers 
              </p>
            </p>

            <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0,}}>
              <p class="sbInf-child-p2" style={{ fontWeight: "bold", marginBottom: 0, paddingBottom: 0 }}>
              SIKECODJI CAMPUS 
              </p>
              <p class="sbInf-child-p2" style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 0, marginTop: 0}}>
                being fully aware that there are people who are called and can be used 
                to do exploit at their levels, but they were not opportune to have west 
                African School Certificate which is our minimum admission 
                requirement. And teaching such people will need a different 
                approach, being that their rate of assimilation will not be 
                the same with those with higher academic qualification, therefore; we 
                decided to create a campus where we can meet this special need by helping 
                such people get the same quality of training but at their level. at 
                sikecodji campus. we have provided, one year Special course for them 
                and at the end of which, they will be awarded with Certificate in 
                Ministerial Arts and Biblical Studies, furthermore, those with 
                distinguished calling and excellent performance may be helped to go 
                to the level of getting a diploma. After screening 
              </p>
            </p>


            <p class="sbInf-child-p2"></p>
            <p class="sbInf-child-p2"></p>
            <p class="sbInf-child-p2" style={{ fontSize: 10, }}>
              INSTITUTE OF MISSIONS AND STRATEGIC STUDIES
            </p>
            <p class="sbInf-child-p2" style={{ fontSize: 10 }}>
              Established in the year, 2000
            </p>
          </div>
        </div>
      </>
    )
  }
}