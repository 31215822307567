export const words = {
    home: {
        en:"Home",
        fr:"Domicile"
    },
    Sp: {
        en:"School portal",
        fr:"Portail de l'école"
    },
    MN: {
        en:"Ministers network",
        fr:"Réseau des ministres"
    },
    about: {
        en:"About the School",
        fr:"À propos de l'école"
    },
    onlineCls: {
        en:"Online Classes",
        fr:"Cours en ligne"
    },
    gallery: {
        en:"Gallery",
        fr:"Galerie"
    },
    establisd_y: {
        en:"Established in the year, 2000",
        fr:"Fondée en l'an 2000"
    },
    copyrite: {
        en:"Copyright © Umbrella 2021",
        fr:"Copyright © Umbrella 2021"
    },
    institute_o: {
        en:"Institute of Missions and Strategies Studies Benin",
        fr:"Institut d'études des missions et stratégies Bénin"
    },
    eagles_w_f: {
        en:"Eagles Wings Faith Missions WORLD",
        fr:"Eagles Wings Faith Missions WORLD"
    },
    the_i_: {
        en:"The Institute of Missions and Strategies Studies Benin an affiliate of Eagles wing faith center Arizona USA, is burdened with the vision of raising an Army for the kingdom. We mobilize; recruit and train Missionaries and Ministers of the gospel for the expansion of the Kingdom.",
        fr:"L'Institut des Missions et des Etudes Stratégiques du Bénin, affilié au centre de foi Eagles wing Arizona USA, est chargé de la vision de lever une armée pour le royaume. Nous mobilisons, recrutons et formons des missionnaires et des ministres de l'évangile pour l'expansion du Royaume."
    },
    register: {
        en:"REGISTER",
        fr:"ENREGISTRER"
    },
    talk_t_s: {
        en:"Talk to someone",
        fr:"Parlez à quelqu'un"
    },
    you_can_now: {
        en:"You can talk to any one of these executive school personnel if you need guidance on the registration process, these are links to chat with them on whatapp. Feel at home.",
        fr:"Vous pouvez parler à l'un de ces membres du personnel de l'école exécutive si vous avez besoin de conseils sur le processus d'inscription. Ce sont des liens pour chatter avec eux sur whatapp. Sentez-vous chez vous."
    },
    click_h_t: {
        en:"click here to read more about the school",
        fr:"cliquez ici pour en savoir plus sur l'école"
    },
    get_i_t: {
        en:"GET IN TOUCH",
        fr:"CONTACTEZ-NOUS"
    },
    contact_u_share_y: {
        en:"contact us, share your concerns",
        fr:"contactez-nous, partagez vos préoccupations"
    },
    aboutSchool_p1: {
        en:"Institute of Missions and Strategic Studies is a Non-denominational Bible school Based in Republic of Benin in West Africa, this institution is burdened with the vision of training and raising quality spirit filled Pastors, Missionaries Christian Leaders, Church workers and Ministers of the gospel, Our mandate is to raise a special breed of end time army for end time Harvest.",
        fr:"L'Institut des Missions et des Etudes Stratégiques est une école biblique non confessionnelle basée en République du Bénin en Afrique de l'Ouest, cette institution est chargée de la vision de former et d'élever des pasteurs, des missionnaires, des leaders chrétiens, des travailleurs d'église et des ministres de l'évangile remplis d'esprit et de qualité, notre mandat est d'élever une race spéciale d'armée de la fin des temps pour la récolte de la fin des temps."
    },
    aboutSchool_p2: {
        en:"Our bible school is Strategic in approach, we help our Students to discover different mission strategies and approaches of accomplishing their divine assignment or calling. Through strategic bible coaching.",
        fr:"Notre école biblique a une approche stratégique, nous aidons nos étudiants à découvrir différentes stratégies et approches de mission pour accomplir leur mission ou leur appel divin. Grâce à un encadrement biblique stratégique."
    },
    aboutSchool_p3: {
        en:"Institute of Missions and Strategic Studies is an affiliate of Eagles Wing Faith Center Arizona (USA) We are also in collaboration with the international Curriculum of the Spirit and Video Bible School NY, USA).",
        fr:"L'Institut des Missions et des Etudes Stratégiques est une filiale du Eagles Wing Faith Center Arizona (USA). Nous sommes également en collaboration avec le Curriculum of the Spirit and Video Bible School international NY, USA)."
    },
    ourMission: {
        en:"OUR MISSION",
        fr:"NOTRE MISSION"
    },
    we_a_s: {
        en:"We are saddled with the responsibility of raisingan army for the kingdom, this we do by mobilizing, recruiting, and training Missionaries and Ministers of the Gospel, Militants in Evangelism, Aggressivein Soul winning.",
        fr:"Nous avons la responsabilité de lever une armée pour le royaume, ce que nous faisons en mobilisant, en recrutant et en formant des missionnaires et des ministres de l'Évangile, des militants de l'évangélisation, des personnes agressives qui gagnent des âmes."
    },
    equiping_a_m: {
        en:"EQUIPPING ALL MEN WITH ALL GOSPEL FOR ALL GENERATION",
        fr:"ÉQUIPER TOUS LES HOMMES DE TOUT L'ÉVANGILE POUR TOUTES LES GÉNÉRATIONS"
    },
    school_o_w_a_l: {
        en:"SCHOOL OF WORKERS AND CHURCH LEADERS",
        fr:"ÉCOLE DE TRAVAILLEURS ET DE RESPONSABLES D'ÉGLISE"
    },
    you_c_n_register: {
        en:"You can now register with us in our School of Workers and Church Leaders, and be trained to be useful in your local Churches. It is also an opportunity for pastors to train their workers for effective services intheir Churches :- classes hold two (2) Mondays every Month, from 7PM to 10 PM. call our office now for registration.",
        fr:"Vous pouvez maintenant vous inscrire à notre école de travailleurs et de dirigeants d'église, et être formés pour être utiles dans vos églises locales. C'est aussi une opportunité pour les pasteurs de former leurs ouvriers pour des services efficaces dans leurs églises : - les classes ont lieu deux (2) lundis par mois, de 19h00 à 22h00. Appelez notre bureau maintenant pour vous inscrire."
    },
    school_o_m_a: {
        en:"SCHOOL OF MINISTRY AND BIBLICAL / THEOLOGICAL STUDIES",
        fr:"ÉCOLE DU MINISTÈRE ET DES ÉTUDES BIBLIQUES / THÉOLOGIQUES"
    },
    for_t_w_h: {
        en:"For those who have a special call for service into ministry,and service of the Lord, you can register with us now for ministerial training and preparation for full operation into fivefold ministries, prophetic andPower gift. Call our office now for registration and other information concerning schedule of Classes.",
        fr:"Pour ceux qui ont un appel spécial pour le service dans le ministère, et le service du Seigneur, vous pouvez vous inscrire avec nous maintenant pour la formation ministérielle et la préparation pour la pleine opération dans les ministères quintuple, prophétique et le don de puissance. Appelez notre bureau dès maintenant pour vous inscrire et obtenir d'autres informations concernant le calendrier des cours."
    },
    admission_c: {
        en:"ADMISSION CRITERIA",
        fr:"CRITÈRES D'ADMISSION"
    },
    only_f_b: {
        en:"Only for born again Christians.",
        fr:"Seulement pour les chrétiens nés de nouveau."
    },
    accademic_session: {
        en:"ACADEMIC SESSION",
        fr:"SESSION ACADÉMIQUE"
    },
    our_a_s_b: {
        en:"Our academic session begins in July and ends in May of thefollowing year",
        fr:"Notre session académique commence en juillet et se termine en mai de l'année suivante."
    },
    graduation_o_s_a: {
        en:"Graduation of Students are always in the month of May.",
        fr:"La remise des diplômes des étudiants a toujours lieu au mois de mai."
    },
    benefit: {
        en:"BENEFIT",
        fr:"BENEFICE"
    },
    institute_o_m_affli: {
        en:"Institute of Missions and Strategic Studies is an affiliation of Eagles Wing Faith Center Arizona (USA) Address: 2655 W GuadalupeRD, Mesa, AZ 85202, United States. We are also in collaboration with the International Curriculum of the Spirit and Video Bible School (P.O BOX 215Olean NY, USA) And international School of Ministries (Los. Amitos, CA, USA)",
        fr:"Institute of Missions and Strategic Studies est une affiliation de Eagles Wing Faith Center Arizona (USA) Adresse : 2655 W GuadalupeRD, Mesa, AZ 85202, États-Unis. Nous sommes également en collaboration avec l'International Curriculum of the Spirit and Video Bible School (P.O BOX 215Olean NY, USA) et l'International School of Ministries (Los. Amitos, CA, USA)."
    },
    matriculation_o_n: {
        en:"Matriculation of new Students shall always be first week of November ",
        fr:"La matriculation des nouveaux étudiants a toujours lieu la première semaine de novembre. "
    },
    programme_w_o: {
        en:"PROGRAMMES WE OFFER",
        fr:"LES PROGRAMMES QUE NOUS PROPOSONS"
    },
    p_W_O_1: {
        en:"Certificate in Ministerial Arts and Biblical Studies",
        fr:"Certificat en arts ministériels et études bibliques"
    },
    p_W_O_2: {
        en:"Diploma in Divinity and Mission ",
        fr:"Diplôme en théologie et mission "
    },
    p_W_O_3: {
        en:"Advanced Diploma in Divinity and Mission",
        fr:"Diplôme supérieur en théologie et mission"
    },
    p_W_O_4: {
        en:"Advanced Diploma in theology, Church Management and MinisterialArts.",
        fr:"Diplôme supérieur en théologie, gestion des églises et arts ministériels."
    },
    licence_o_f: {
        en:"License obtained from this Institute can not be subject to fraud, due to the fact that every certificate has a QR code inprint which can only be scanned via the official website",
        fr:"La licence obtenue auprès de cet institut ne peut faire l'objet d'aucune fraude, étant donné que chaque certificat comporte un code QR qui ne peut être scanné que sur le site officiel."
    },
    campuses: {
        en:"CAMPUSES",
        fr:"CAMPUSES"
    },
    we_r_t_c: {
        en:"We run two campuses, one at Beraka - menontin for regular Classes and another at Gospel House - Sikecodji for Certificate class only on Mondays",
        fr:"Nous avons deux campus, l'un à Beraka - menontin pour les cours réguliers et l'autre à Gospel House - Sikecodji pour les cours de certificat uniquement le lundi."
    },
    beraka_m_m_c: {
        en:"BERAKA - MENONTIN MAIN CAMPUS",
        fr:"BERAKA - MENONTIN CAMPUS PRINCIPAL"
    },
    for_R_s: {
        en:"For Regular Students",
        fr:"Pour les étudiants réguliers"
    },
    duration: {
        en:"DURATION",
        fr:"DURATION"
    },
    thier_c_i_f: {
        en:"Their Courses is for three years ",
        fr:"Leurs cours sont d'une durée de trois ans "
    },
    the_m_a_r: {
        en:"The Minimum admission requirement for the courses is West African Examination certificate",
        fr:"La condition minimale d'admission à ces cours est un certificat d'examen d'Afrique de l'Ouest."
    },
    a_one_y: {
        en:"a one year intensive course ",
        fr:"un cours intensif d'un an "
    },
    CDC_1: {
        en:"DURATION - 10 months, (July to May of the following year) ",
        fr:"DURÉE - 10 mois, (de juillet à mai de l'année suivante) "
    },
    CDC_2: {
        en:"DAYS OF STUDIES :- Sunday evening (7:00pm – 10:00pm) ",
        fr:"JOURS D'ÉTUDES :- Dimanche soir (19h00 - 22h00) "
    },
    CDC_3: {
        en:"Students must attend our Thursdays Spiritual emphasis and Practical From 7:00pm to 10:00pm ",
        fr:"Les étudiants doivent assister à nos cours d'accentuation spirituelle et pratique du jeudi de 19h00 à 22h00. "
    },
    CDC_4: {
        en:"Students will be awarded Diploma in Divinity and Mission at the end of their Studies",
        fr:"Les étudiants recevront un diplôme en théologie et mission à la fin de leurs études."
    },
    First_YRS: {
        en:"FIRST YEAR REGULAR SCHOOL",
        fr:"PREMIÈRE ANNÉE D'ÉCOLE NORMALE"
    },
    FYRS_1: {
        en:"DURATION - 10 months, (July to May of the following year) ",
        fr:"DURÉE - 10 mois, (de juillet à mai de l'année suivante) "
    },
    FYRS_2: {
        en:"Classes runs every first week of the month Monday, Tuesday, Wednesday and Friday (7:00pm – 10:00pm) ",
        fr:"Les cours ont lieu chaque première semaine du mois, les lundi, mardi, mercredi et vendredi (de 19h00 à 22h00). "
    },
    FYRS_3: {
        en:"Thursdays Spiritual emphasis and Practical From 7:00pm to 10:00pm ",
        fr:"Les jeudis accent spirituel et pratique De 19h00 à 22h00 "
    },
    FYRS_4: {
        en:"Saturdays lectures from 9:00 am to 1:00 pm",
        fr:"Cours du samedi de 9h00 à 13h00"
    },
    second_YRS: {
        en:"SECOND YEAR REGULAR SCHOOL",
        fr:"DEUXIÈME ANNÉE D'ÉCOLE NORMALE"
    },
    third_YRS: {
        en:"THIRD YEAR REGULAR SCHOOL",
        fr:"TROISIÈME ANNÉE ÉCOLE NORMALE"
    },
    advanced_p_a_l: {
        en:"ADVANCED PASTORAL AND LEADERSHIP CLASS",
        fr:"COURS AVANCÉ DE PASTORALE ET DE LEADERSHIP"
    },
    lecture_o_in: {
        en:"Lecture, once in a day in a Month from (9:00am – 1:00 pm) ",
        fr:"Conférence, une fois par jour dans un mois de (9h00 - 13h00) "
    },
    for_m_t_w: {
        en:"For ministers that want to improve themselves as special vessels for the master ",
        fr:"Pour les ministres qui veulent s'améliorer en tant que récipients spéciaux pour le maître. "
    },
    compulsory_f_a_l: {
        en:"Compulsory for all the lecturers",
        fr:"Obligatoire pour tous les conférenciers"
    },
    Sikekigi_c: {
        en:"SIKECODJI CAMPUS",
        fr:"CAMPUS DE SIKECODJI"
    },
    being_f_a_t: {
        en:"being fully aware that there are people who are called and can be used to do exploit at their levels, but they were not opportune to have west African School Certificate which is our minimum admission requirement. And teaching such people will need a different approach, being that their rate of assimilation will not be the same with those with higher academic qualification, therefore; we decided to create a campus where we can meet this special need by helping such people get the same quality of training but at their level. at sikecodji campus. we have provided, one year Special course for them and at the end of which, they will be awarded with Certificate in Ministerial Arts and Biblical Studies, furthermore, those with distinguished calling and excellent performance may be helped to go to the level of getting a diploma. After screening",
        fr:"étant pleinement conscient qu'il y a des gens qui sont appelés et peuvent être utilisés pour faire des exploits à leurs niveaux, mais ils n'ont pas eu l'opportunité d'avoir le certificat scolaire ouest-africain qui est notre condition minimale d'admission. Et l'enseignement de ces personnes nécessitera une approche différente, étant donné que leur taux d'assimilation ne sera pas le même que celui des personnes ayant une qualification académique plus élevée, par conséquent, nous avons décidé de créer un campus où nous pouvons répondre à ce besoin spécial en aidant ces personnes à obtenir la même qualité de formation mais à leur niveau. au campus de sikecodji, nous avons prévu un cours spécial d'un an pour eux et à la fin duquel, ils seront récompensés par un certificat en arts ministériels et études bibliques, en outre, ceux qui ont une vocation distinguée et d'excellentes performances peuvent être aidés à aller au niveau de l'obtention d'un diplôme. Après la sélection"
    },
    special_training: {
        en:"SPECIAL TRAINING, CONFRENCES AND SEMINARS",
        fr:"FORMATIONS SPÉCIALES, CONFÉRENCES ET SÉMINAIRES"
    },
    school_o_leaders_a_t_m: {
        en:"SCHOOL OF LEADERS AND THE MIRACULOUS",
        fr:"ÉCOLE DES LEADERS ET DU MIRACULEUX"
    },
    this_is_a: {
        en:"This is a three day Ministers conference that holds first week of July every year to mark the beginning of the academic Year, attendants is open for all Ministers of the Gospel and compulsory for all Members of the Network",
        fr:"Il s'agit d'une conférence des ministres de trois jours qui se tient la première semaine de juillet chaque année pour marquer le début de l'année académique. La participation est ouverte à tous les ministres de l'Évangile et obligatoire pour tous les membres du réseau."
    },
    school_o_deliverance: {
        en:"SCHOOL OF DELIVERANCE",
        fr:"L'ÉCOLE DE LA DÉLIVRANCE"
    },
    this_i_s_three: {
        en:"This is a three day Spiritual warfare and prayer conference that holds on first week of November every year to mark the Matriculation ceremony of the new Students. It shall be a prayer conference for all Ministers of the Gospel and compulsory for all Members of the Network. At the end of this program registration of new students will end and all registered students will have their School badge with registration Number of the school",
        fr:"Il s'agit d'une conférence de trois jours sur le combat spirituel et la prière, qui se tient la première semaine de novembre de chaque année, à l'occasion de la cérémonie de remise des diplômes aux nouveaux étudiants. Il s'agit d'une conférence de prière pour tous les ministres de l'Évangile et obligatoire pour tous les membres du réseau. A la fin de ce programme, l'enregistrement des nouveaux étudiants sera terminé et tous les étudiants enregistrés auront leur badge d'école avec le numéro d'enregistrement de l'école."
    },
    special_classes: {
        en:"SPECIAL CLASSES",
        fr:"CLASSES SPÉCIALES"
    },
    benin_has_f: {
        en:"Benin has French Language as her official Language but is surrounded by English Speaking countries, with most of their citizens and other English Speaking foreigners in Benin for commercial activities. This has created the need to understanding English and French in Benin, not only for social life but also for the Spread of the gospel, for this reason IMES has created an opportunity for mission language classes in her curriculum English and French",
        fr:"Le Bénin a le français comme langue officielle, mais il est entouré de pays anglophones, dont la plupart des citoyens et autres étrangers anglophones sont au Bénin pour des activités commerciales. Ceci a créé le besoin de comprendre l'anglais et le français au Bénin, non seulement pour la vie sociale mais aussi pour la propagation de l'évangile, pour cette raison l'IMES a créé une opportunité pour des cours de langue de mission dans son programme d'études anglais et français."
    },
    resumption_date: {
        en:"RESUMPTION DATE IMES ACCADEMIC YEAR (second week of July)",
        fr:"DATE DE RESUME ANNÉE ACADÉMIQUE IMES (deuxième semaine de juillet)"
    },
    registration_forms_a: {
        en:"Registration forms and further enquiries about the school are obtainable at our ",
        fr:"Les formulaires d'inscription et d'autres informations sur l'école sont disponibles à l'adresse suivante "
    },
    administrative_o: {
        en:"administrative offices at BERAKA AND GOSPEL HOUSE",
        fr:"bureaux administratifs à BERAKA ET GOSPEL HOUSE"
    },
    Cancel: {
        en:"Cancel",
        fr:"Annuler"
    },
    members_o_t_gov: {
        en:"MEMBERS OF THE GOVERNING BOARD",
        fr:"MEMBRES DU CONSEIL D'ADMINISTRATION"
    },
    bishop_david: {
        en:"Bishop David and Deborah Rice",
        fr:"Évêque David et Deborah Rice"
    },
    international_pre: {
        en:"International President",
        fr:"Président international"
    },
    dr_paul: {
        en:"DR. Paul Uzoma",
        fr:"DR. Paul Uzoma"
    },
    international_dir: {
        en:"International Director",
        fr:"Directeur international"
    },
    prophetesse_e: {
        en:"Prophetesse Excel Paul Uzoma",
        fr:"Prophétesse Excel Paul Uzoma"
    },
    assistant_int: {
        en:"Assistant International Director",
        fr:"Directeur international adjoint"
    },
    reverend_lin: {
        en:"Reverend Line Eliel",
        fr:"Révérend Line Eliel"
    },
    Director_of_O : {
        en: "Director of Opperations",
        fr: "Directeur des opérations"
    },
    reverend_grce: {
        en:"Reverend Doctor Grace Lawani",
        fr:"Révérend Docteur Grace Lawani"
    },
    matron_o_t: {
        en:"Matron of the School",
        fr:"Matrone de l'école"
    },
    apostle_vin: {
        en:"Apostle Vincent Coovi Adjomatin",
        fr:"Apôtre Vincent Coovi Adjomatin"
    },
    President_of_the_S: {
        en: "President of the School",
        fr: "Président de l'école"
    },
    menbers_of_t_f: {
        en:"MEMBERS OF THE FACULTY",
        fr:"MEMBRES DE LA FACULTÉ"
    },
    past_anaurd: {
        en:"Pastor Anaurd",
        fr:"Pasteur Anaurd"
    },
    dine_o_s: {
        en:"Dine of studies",
        fr:"Dine d'études"
    },
    past_ann: {
        en:"Pastor Ann",
        fr:"Pasteur Ann"
    },
    licenced_stud: {
        en:"LICENCED STUDENTS",
        fr:"ÉTUDIANTS LICENCIÉS"
    },
    albums: {
        en:"Albums",
        fr:"Albums"
    },
    photos: {
        en:"Photos",
        fr:"Photos"
    },
    videos: {
        en:"Videos",
        fr:"Vidéos"
    },
    all: {
        en:"All",
        fr:"Tous"
    },
    private: {
        en:"Private",
        fr:"Privé"
    },
    graduation_photos: {
        en:"Graduation Photos",
        fr:"Photos de la remise des diplômes"
    },
    school_of_the_mir: {
        en:"School Of The Miraculous",
        fr:"L'école des Miraculeux"
    },
    place_certificate: {
        en:"Place Certificate Over the Scanner",
        fr:"Placez le certificat au-dessus du scanner"
    },


    // PORTAL TRANS
    header: {
        en:"IDENTIFICATION FORM",
        fr:"FICHE D’IDENTIFICATION"
    },
    fld_1: {
        en:"Name",
        fr:"Nom"
    },
    fld_2: {
        en:"Surname",
        fr:"Prénoms"
    },
    fld_3: {
        en:"Date of Birth",
        fr:"Date de Naissance"
    },
    fld_4: {
        en:"Accademic level",
        fr:"Niveau d’Etude"
    },
    fld_5: {
        en:"Profession",
        fr:"Profession"
    },
    fld_6: {
        en:"Email Adress",
        fr:"Adresse Email"
    },
    fld_6_: {
        en:"this Email would be verified",
        fr:""
    },
    fld_7: {
        en:"Phone Contacts",
        fr:"Contacts Téléphonique"
    },
    fld_8: {
        en:"City of Residence",
        fr:"Ville de Résidence"
    },
    fld_9: {
        en:"Country of residence ",
        fr:"Pays de Résidence"
    },
    fld_10: {
        en:"Are you legally married or cohabiting ? (Please check the box according to your choice)",
        fr:"Êtes-vous Marié(e) légalement ou en concubinage ? (Merci de cocher la case selon votre choix)"
    },
    fld_check_box_1: {
        en:"Legally married",
        fr:"Marié(e) légalement"
    },
    fld_check_box_1_sub: {
        en:"Spouse name and surname",
        fr:"Nom et Prénoms du conjoint"
    },
    fld_check_box_2: {
        en:"Concubinage",
        fr:"Concubinage"
    },
    fld_check_box_3: {
        en:"Single with no children",
        fr:"Célibataire sans enfants"
    },
    fld_check_box_4: {
        en:"Single with children",
        fr:"Célibataire avec enfants"
    },
    fld_check_box_5: {
        en:"Divorced / Widowed",
        fr:"Divorcé(e) / Veuf(ve)"
    },
    fld_check_box_6: {
        en:"Other (Please specify)",
        fr:"Autre (Merci de Préciser)"
    },
    fld_11: {
        en:"Number of children (Put the number otherwise put 0)",
        fr:"Nombre d'enfant (Mettez le nombre sinon mettez 0)"
    },
    rules_a_r_o_t_sch: {
        en:"RULES AND REGULATIONS OF IMES INSTITUTE",
        fr:"REGLEMENT INTERIEUR DE L’INSTITUT IMES"
    },
    Preamble: {
        en:"Preamble",
        fr:"Préambule"
    },
    R_pgph_1: {
        en:"The Institute of Mission and Strategic Studies (IMES) is a Bible school whose vision is to help God's servants, male and female, to fulfill their vision of being useful in God's hands.",
        fr:"L’Institut Missionnaire des Etudes Stratégiques (IMES) est une école Biblique dont la vision est d’aider les serviteurs de Dieu, homme et femme à accomplir leur vision qui est d’être utile dans les mains de Dieu."
    },
    R_pgph_2: {
        en:"It is a school that trains and equips men and women to be effective in ministry.",
        fr:"C’est une école qui forme et équipe des hommes et des femmes pour être efficace dans le ministère."
    },
    imes_will_help_you: {
        en:"IMES will help you understand God's will and call on your life.",
        fr:"IMES vous aidera à comprendre la volonté et l’appel de Dieu sur votre vie."
    },
    Item_1: {
        en:"Item 1",
        fr:"Article 1"
    },
    Item_1_p1: {
        en:"Enrolling in IMES does not mean that you are directly pastor.Although being a training whose vision is to make a generation of seasoned men and women of God, you may not be accepted to minister according to the testimony we will have of you. Becoming a servant of God is not just about getting a degree.",
        fr:"L’inscription à IMES ne signifie pas que vous êtes directement pasteur. Bien qu’étant une formation dont la vision est de faire une génération d’hommes et de femmes de Dieu aguerri, vous pouvez ne pas être accepté à exercer le ministère selon le témoignage que nous aurons de vous. Devenir serviteur de Dieu ne se résume pas à l’obtention d’un diplôme."
    },
    Item_1_p2: {
        en:"Likewise, this training is open to any Christian who is thirsty for the Word of God and who wishes to deepen his relationship with God",
        fr:"De même cette formation est ouverte à tout chrétien assoiffé de la Parole de Dieu et qui désire approfondir sa relation avec Dieu"
    },
    Item_1_a: {
        en:"The ministerial License is granted to those who are called into the ministry and approved by the network after study of the file by the international president.",
        fr:"La licence ministérielle est octroyée à ceux qui sont appelés dans le ministère et approuvé par le réseau après étude du dossier par le président international."
    },
    Item_1_b: {
        en:"All the licensed ministers, ordained pastor and consecrated apostles must belong to the  Eagles Wing Faith network of ministers",
        fr:"Tous les ministres licencés, pasteur ordonnés et apôtres consacrés doivent appartenir au réseau des ministres Eagles Wing Faith"
    },
    Item_1_c: {
        en:"The ordination of a minister occurs 5 years after his or her ministerial license and only for those who are leaders of ministries or who have the approval of their spiritual authority.",
        fr:"L’ordination d’un ministre survient 5 ans après sa licence ministérielle et uniquement pour ceux qui sont leaders de ministères ou qui ont l’approbation de leur autorité spirituelle."
    },
    Item_2: {
        en:"Item 2",
        fr:"Article 2"
    },
    Item_2_p1: {
        en:"The student agrees to be up to date with the participation fees before the start of the course according to the terms on the information sheet.",
        fr:"L’étudiant(e) s’engage à être à jour quant aux frais de participation avant le démarrage des cours selon les modalités sur la fiche de renseignement."
    },
    Item_2_a: {
        en:"The training fees are 800euro payable in two instalments 400 euros before September 1st and 400 euros at the end of January",
        fr:"Les Frais de formation sont de 800euro payable en deux tranches 400 euros avant le 1er Septembre et 400 euros à Fin Janvier"
    },
    Item_2_b: {
        en:"No refund will be made after payment.",
        fr:"Aucun remboursement ne se fera après le paiement."
    },
    Item_3: {
        en:"Item 3",
        fr:"Article 3"
    },
    Item_3_p1: {
        en:"The online training is exclusively executive courses. You will have one video per week. The student agrees to follow the courses and do the exercises.",
        fr:"La formation en ligne est exclusivement les cours de cadres. Vous aurez une vidéo par semaine. L’étudiant s’engage à suivre les cours et à faire les exercices."
    },
    Item_4: {
        en:"Item 4",
        fr:"Article 4"
    },
    Item_4_p1: {
        en:"The accademics sessions start in September and end in May of the following year. ",
        fr:" Les sessions académiques commencent en Septembre et finissent en Mai de l’année suivante."
    },
    Item_5: {
        en:"Item 5",
        fr:"Article 5"
    },
    Item_5_p1: {
        en:"IMES culture is based on Discipline, Integrity, Humility, Obedience and Loyalty. Every student who enrolls commits to cultivating these core values not only in school but also in ministry. ",
        fr:"La culture d’IMES est fondée sur la Discipline, l’Intégrité, l’Humilité, l’Obéissance et la Loyauté. Tout étudiant qui s’inscrit s’engage à cultiver ses valeurs fondamentales non seulement dans l’école mais aussi dans son ministère."
    },
    Item_6: {
        en:"Item 6",
        fr:"Article 6"
    },
    Item_6_p1: {
        en:"Outside of the student forum, it is strictly forbidden to create subgroups and insert other students into them.",
        fr:"En dehors du forum des étudiants, il est formellement interdit de créer des sous-groupes de discussions et d’y insérer d’autres étudiants. "
    },
    Item_6_p2: {
        en:"A student is not allowed to invite another student to preach in his church without the approval of Dr Paul Uzoma.",
        fr:"Un étudiant n’a pas le droit d’inviter un autre étudiant à prêcher dans son église sans l’approbation du Dr Paul Uzoma."
    },
    Item_6_p3: {
        en:"Any other form of association of persons that you make with other participants for personal purposes will only be binding on you.",
        fr:"Toute autre forme d’association de personnes que vous ferez avec d’autres participant(e) pour des fins personnelles n’engagera que vous."
    },
    Commitmen: {
        en:"Commitment",
        fr:"Engagement"
    },
    Commitmen_p: {
        en:"Each student will commit to the respect of these rules and regulations by signing them below with the mention.",
        fr:"Chaque étudiante s’engagera au respect du présent règlement intérieur en le signant ci-dessous avec la mention."
    },
    Commitmen_checkbox: {
        en:"I have read the present rules and regulations in their entirety and I undertake to respect them scrupulously",
        fr:"J’ai pris connaissance du présent règlement intérieur intégralement et je m’engage à son respect scrupuleux"
    },
    agree_to_the_rules: {
        en:"AGREE TO THE RULES AND REGULATIONS OF IMES INSTITUTE",
        fr:""
    },
    you_have_to_agree: {
        en:"you have to agree to the rules and regulations of IMES INSTITUTE before being able to register, you can find it above.",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
    ___: {
        en:"",
        fr:""
    },
}