import React from 'react'
// import logo from './logo.svg';
import './css/overall.css';
import Screens from './screens';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import { words } from '../trans'
import { Fct, HotlineArr } from './screens/navScreens/ministersNetwork/mgb';
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";

class ExtraInfo extends React.Component {

    openInNewTab(url) {
        var win = window.open(url, '_blank');
        win.focus();
        // ?subject=Subject&body=Body%20goes%20here
    }

    render() {
        const { showSpeech, webHeight, showQRCBox, trans, lang } = this.props.Data;
        const { style, clas } = this.props;
        return (
            <div class={clas} style={style}>


                {/* <div class="contact_info_container">
                    <p class="hotline_Header contact_info_header">{trans.get_i_t}</p>
                    <div class="contact_info_container">
                        <div class="mf">
                            {Fct.slice(0,3).map((item) =>
                                <div
                                    class="col-md-2 mgbCards_holder fct"
                                    // onClick={() => { this.forceShowInfo(item) }}
                                >
                                    <div class="mgbCards fct">
                                        <div class="mgbCardsIMG_holder fct">
                                            <div class="mgbCardsIMG fct noBorder cool_bg">
                                                <div class={"fctImag " + item.img}></div>
                                            </div>
                                        </div>
                                        <div class="writeup_holder fct">
                                            <h6>{lang === "en" ? item.name.en : item.name.fr}</h6>
                                            <p>{lang === "en" ? item.role.en : item.role.fr}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div> */}


                <div class="hotline_container">
                    <h3 class="hotline_Header">{trans.talk_t_s}</h3>
                    <p class="hotline_p">
                        {trans.you_can_now}
                    </p>
                    <div class="hotline_box">

                        {HotlineArr.slice(0, 3).map((item) =>
                            <a class="hotline_hotline" href="https://wa.ma/22996810072?text=hello,%20i%20was%20directed%20from%20the%20official%20imes%20website%20to%20your%20contact,%20i%20would%20like%20to%20make%20some%20inquiries">
                                <div class="hotline_hotline_dp" >
                                    <div class="mgbCardsIMG_holder fct">
                                        <div class="mgbCardsIMG fct noBorder cool_bg">
                                            <div class={"fctImag " + item.img}></div>
                                        </div>
                                    </div>
                                    {/* <i class="fa fa-user hotline_hotline_dp_image" ></i> */}
                                </div>
                                <p class="hotline_hotline_name_p">{lang === "en" ? item.name.en : item.name.fr}</p>
                                <p class="hotline_hotline_name">{lang === "en" ? item.role.en : item.role.fr}</p>
                                <p class="hotline_hotline2_name_pp">Click Here</p>
                            </a>
                        )}



                        {/* <a class="hotline_hotline" href="https://wa.ma/22997389848?text=hello,%20i%20was%20directed%20from%20the%20official%20imes%20website%20to%20your%20contact,%20i%20would%20like%20to%20make%20some%20inquiries">
                            <div class="hotline_hotline_dp" >
                                <i class="fa fa-user hotline_hotline_dp_image" ></i>
                            </div>
                            <p class="hotline_hotline_name_p">Reverend Line Eliel</p>
                            <p class="hotline_hotline_name">the Director of Operations</p>
                            <p class="hotline_hotline2_name_pp">Click Here</p>
                        </a>

                        <a class="hotline_hotline" href="https://wa.ma/22966645928?text=hello,%20i%20was%20directed%20from%20the%20official%20imes%20website%20to%20your%20contact,%20i%20would%20like%20to%20make%20some%20inquiries">
                            <div class="hotline_hotline_dp" >
                                <i class="fa fa-user hotline_hotline_dp_image" ></i>
                            </div>
                            <p class="hotline_hotline_name_p">Pastor Clement</p>
                            <p class="hotline_hotline_name">General Surveilant </p>
                            <p class="hotline_hotline2_name_pp">Click Here</p>
                        </a> */}

                    </div>
                </div>



                <div class="president_speech_container">
                    <h3 class="hotline_Header">{trans.about}</h3>
                    <div class="president_speech_button" onClick={() => { showSpeech() }}>
                        <div class="president_speech_DarkGlass"></div>
                        <a href="#" class="president_speech_a">{trans.click_h_t}</a>
                    </div>
                </div>


                <div class="contact_info_container">
                    <p class="hotline_Header contact_info_header">{trans.get_i_t}</p>
                    <div class="uline12" style={{ backgroundColor: "rgba(0,0,0,0.3)", width: "100%" }}></div>
                    <a href="#" class="contact_info_link">INSTITUT MISSIONNAIRE DES ETUDES STRATEGIQUESs</a><br />
                    <a href="#" class="contact_info_link"> {trans.contact_u_share_y}</a><br />
                    <a href="https://www.facebook.com/profile.php?id=100068713908029" class="contact_info_link"><i class="fa fa-facebook-square"></i> facebook: imes</a><br />
                    <a href="tel:22996255090" class="contact_info_link"><i class="fa fa-phone-square"></i> +229 96 25 50 90</a><br />
                    <a href="tel:22996810072" class="contact_info_link"><i class="fa fa-phone-square"></i> +229 96 81 00 72</a><br />
                    <a href="mailto:kingspeoplechurch@yahoo.com" class="contact_info_link"><i class="fa fa-envelope"></i> kingspeoplechurch@yahoo.com</a><br />
                    <a href="#" class="contact_info_link"><i class="fa fa-map-marker"></i> . Siège: Carré N°851 Fignon Sikecodji COTONOU RÉPUBLIQUE DU BÉNINe</a>
                    <div class="col-md-12 sub-info" style={{ padding: 0 }}>

                        <div class="col-md-3 sub-info-child" style={{ padding: 3 }} onClick={() => {this.openInNewTab('mailto:kingspeoplechurch@yahoo.com')}}>
                            <div class="sbInf-child-a">
                                <div class="sbInf-child-box">
                                    <i class="fa fa-envelope"></i>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 sub-info-child" style={{ padding: 3 }} onClick={() => {this.openInNewTab("https://www.facebook.com/profile.php?id=100068713908029")}}>
                            <div class="sbInf-child-a">
                                <div class="sbInf-child-box">
                                    <i class="fa fa-facebook-square"></i>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 sub-info-child" style={{ padding: 3 }} onClick={() => {this.openInNewTab("tel:22996810072")}}>
                            <div class="sbInf-child-a">
                                <div class="sbInf-child-box">
                                    <i class="fa fa-phone-square"></i>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 sub-info-child" style={{ padding: 3 }}>
                            <div class="sbInf-child-a">
                                <div class="sbInf-child-box">
                                    <i class="fa fa-map-marker"></i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>



                <p class="navigation-credits2">Copyright ©&nbsp;Umbrella 2021</p>
            </div>
        )
    }
}

export default windowSize(ExtraInfo);


// {/* <div class="hotline_box">

//     <a class="hotline_hotline" href="https://wa.ma/22996810072?text=hello,%20i%20was%20directed%20from%20the%20official%20imes%20website%20to%20your%20contact,%20i%20would%20like%20to%20make%20some%20inquiries">
//         <div class="hotline_hotline_dp" style={hotline_hotline_dp}>
//             <i class="fa fa-user hotline_hotline_dp_image" style={hotline_hotline_dp_image}></i>
//         </div>
//         <p class="hotline_hotline_name_p">Prophetesse Excel</p>
//         <p class="hotline_hotline_name">the Assistant Director</p>
//         <p class="hotline_hotline2_name_pp">Click Here</p>
//     </a>

//     <a class="hotline_hotline" href="https://wa.ma/22997389848?text=hello,%20i%20was%20directed%20from%20the%20official%20imes%20website%20to%20your%20contact,%20i%20would%20like%20to%20make%20some%20inquiries">
//         <div class="hotline_hotline_dp" style={hotline_hotline_dp}>
//             <i class="fa fa-user hotline_hotline_dp_image" style={hotline_hotline_dp_image}></i>
//         </div>
//         <p class="hotline_hotline_name_p">Reverend Line Eliel</p>
//         <p class="hotline_hotline_name">the Director of Operations</p>
//         <p class="hotline_hotline2_name_pp">Click Here</p>
//     </a>

//     <a class="hotline_hotline" href="https://wa.ma/22966645928?text=hello,%20i%20was%20directed%20from%20the%20official%20imes%20website%20to%20your%20contact,%20i%20would%20like%20to%20make%20some%20inquiries">
//         <div class="hotline_hotline_dp" style={hotline_hotline_dp}>
//             <i class="fa fa-user hotline_hotline_dp_image" style={hotline_hotline_dp_image}></i>
//         </div>
//         <p class="hotline_hotline_name_p">Pastor Clement</p>
//         <p class="hotline_hotline_name">General Surveilant </p>
//         <p class="hotline_hotline2_name_pp">Click Here</p>
//     </a>

// </div> */}



// {/* <div class="contact_info_container">
// <p class="hotline_Header contact_info_header">{trans.get_i_t}</p>
// <div class="uline12" style={{ backgroundColor: "rgba(0,0,0,0.3)", width: "100%" }}></div>
// <a href="#" class="contact_info_link"><i class="fa fa-envelope"></i> {trans.contact_u_share_y}</a><br />
// <a href="#" class="contact_info_link"><i class="fa fa-facebook-square"></i> www.facebook.com/...</a><br />
// <a href="#" class="contact_info_link"><i class="fa fa-phone-square"></i> +229 sample numbers</a><br />
// <a href="#" class="contact_info_link"><i class="fa fa-phone-square"></i> +229 sample numbers</a><br />
// {/* <a href="#" class="contact_info_link"><i class="fa fa-phone-square"></i> +229 sample numbers</a><br /> */}
// {/* <a href="#" class="contact_info_link"><i class="fa fa-phone-square"></i> +229 sample numbers</a><br /> */}
// {/* <a href="#" class="contact_info_link"><i class="fa fa-phone-square"></i> +229 sample numbers</a><br /> */}
// <a href="#" class="contact_info_link"><i class="fa fa-map-marker"></i> . C/851 FIGNON –Sikecodji vons cimenterie</a>
// </div> */}