import React from 'react'
// import logo from './logo.svg';
import '../css/overall.css';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
// import { MGB } from '../ministersNetwork/mgb'
import { words } from '../../trans'
import NavigationBar from '../navigationBar'
import ExtraInfo from '../extraInfo'
import { withRouter } from "react-router";
import { FedaCheckoutButton, FedaCheckoutContainer } from 'fedapay-reactjs';
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";



class Login extends React.Component {


    constructor() {
        super();
        this.state = {
            onScreen: false,
            showInfo: false,
            showInfo_ext: false,
            slideIn: false,
            item: [],
            showNave: true,
            showMore: false,
            accountVerified: false,
            amount: 0,
            plan: "No Plan Chosen",
            checkLoaded: false,
            loadingCOTdiv: true,
            feild_1: "",
            feild_2: "",
            regError: ""
        }
    }

    makeOnscreenTrue() {
        setTimeout(() => {
            this.setState({
                onScreen: true
            })
        }, 10);
        setTimeout(() => {
            this.setState({
                slideIn: true
            })
        }, 600);
    }

    showInfo(item) {
        const screenWidth = window.innerWidth
        if (screenWidth > 991) {
            this.setState({
                showInfo: true,
                showInfo_ext: true,
                item
            })
        }
    }

    hideInfo() {
        this.setState({
            showInfo_ext: false,
        })
        setTimeout(() => {
            this.setState({
                showInfo: false,
                item: []
            })
        }, 600);
    }

    componentDidMount() {
        this.makeOnscreenTrue();
        setTimeout(() => {
            this.showNave()
        }, 200);
    }

    register() {
        this.setState({
            regError: ""
        })
        fetch("https://www.imesbenin.com/server/login.php", { // URL
            body: JSON.stringify(this.state), // data you send.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // headers: {
            //   'content-type': 'application/json'
            // },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then((response) => response.json())
            .then(async (responseJson) => {
                // alert(responseJson);
                if (responseJson === "right") {
                    this.props.history.push("/");
                    const {userData} = this.props;
                    const data = {
                        user: this.state.feild_1
                    }
                    userData.updateUserData(data);
                    // alert(responseJson);
                } else if(responseJson === "wrong") {
                    this.setState({
                        regError: "Wrong Password"
                    })
                } else  {
                    this.setState({
                        regError: "Failed, could be Server Problem"
                    })
                }
            })
            .catch((error) => {
                // alert(error);
                this.setState({
                    regError: "Unable to LogIn, Check your internet Conection and retry"
                })
            });
    }

    startReg_pr() {
        const { feild_2, feild_1  } = this.state;
        if (feild_2 && feild_1) {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (re.test(feild_1)) {
                    this.register();
                    this.setState({
                        regError: ""
                    })
                }
                else {
                    this.setState({
                        regError: "invalid email address,"
                    })
                }
        } else {
            this.setState({
                regError: "Please fill all the input Fields"
            })
        }
    }

    showNave() {
        this.setState({
            showNave: !this.state.showNave
        })
    }

    showMore() {
        this.setState({
            showMore: !this.state.showMore
        })
    }

    setPlan = (data) => {
        const { amount, plan } = data;
        // plan: "Changing Plan",
        this.setState({
            checkLoaded: false,
            loadingCOTdiv: true,
        })
        setTimeout(() => {
            this.setState({
                amount,
                plan: "Changing Plan",
            })
        }, 400);

        setTimeout(() => {
            this.setState({
                amount,
                plan: "Checking Out..."
            })
        }, 1000);
        setTimeout(() => {
            this.setState({
                checkLoaded: true
            })
        }, 1300);

    }

    endCheckOutLoudLoading = () => {
        this.setState({
            loadingCOTdiv: false
        })
    }

    // updatevalue = (e, i) => {
    //     const value = e.target.value
    //     const { form } = this.state;
    //     const newF = form;
    //     newF.feild_1 = e.target.value;
    //     this.setState({ form: newF })
    // }

    render() {
        const { showSpeech, showRegBox, showMIFBox, windowHeight, lang, user, userData } = this.props
        const { onScreen, showInfo, showInfo_ext, slideIn, accountVerified, feild_1, feild_2, regError } = this.state
        const webHeight = window.innerWidth > 991 ? window.innerHeight - 60 : window.innerHeight - 0;
        const percent43 = webHeight * 43 / 100;
        const hotlineBoxHeight = percent43 * 60 / 100;
        const hotline_hotline_dpHeight = hotlineBoxHeight * 48 / 100
        const col_md = onScreen === true ? "col-md-10" : "col-md-7"
        const shifAnim = showInfo_ext === true ? "fadeIn" : "fadeOut"
        const slideInLeft = slideIn === true ? "slideInLeft" : ""
        const item2 = this.state.item
        const { showMore, showNave, } = this.state;
        const icnClass = showNave === true ? "moreOptionsHolder_Icon_rot" : "";
        const icnMore = showMore === true ? "moreOptionsHolder_Icon_rot2" : "";
        const navHeight = showNave === true ? webHeight : 40;
        const navOpacity = showNave === true ? 1 : 0;
        const navBlocker = showNave === true ? "none" : "flex";
        const topMr = showMore === true ? 0 : webHeight;
        const topMore = window.innerWidth > 991 ? 0 : topMr;
        const topOpac = showMore === true ? 1 : 0;
        const topOpacity = window.innerWidth > 991 ? 1 : topOpac;
        const spouseCss = this.state.martalSta === "Legally married" ? "fadeIn" : "fadeOut";
        const spouseHeight = this.state.martalSta === "Legally married" ? 60 : 0;
        const ckout1 = this.state.checkLoaded === false ? "zoomInLeft" : "zoomOutLeft";
        const ckout2 = this.state.checkLoaded === false ? "zoomOutRight" : "zoomInRight";

        const trans = {
            home: lang === "en" ? words.home.en : words.home.fr,
            Sp: lang === "en" ? words.Sp.en : words.Sp.fr,
            MN: lang === "en" ? words.MN.en : words.MN.fr,
            about: lang === "en" ? words.about.en : words.about.fr,
            onlineCls: lang === "en" ? words.onlineCls.en : words.onlineCls.fr,
            gallery: lang === "en" ? words.gallery.en : words.gallery.fr,
            copyrite: lang === "en" ? words.copyrite.en : words.copyrite.fr,
            establisd_y: lang === "en" ? words.establisd_y.en : words.establisd_y.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
            ___: lang === "en" ? words.___.en : words.___.fr,
        }

        return (
            <>
                <div class="extra_l cool_bg"></div>
                <div class="extra_l"></div>

                <div class="row body-row">
                    <div class="col-md-12 newPageCorrector">

                        <NavigationBar
                            Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
                            active={{ a: "", b: "", c: "", d: "", e: "" }}
                            clas="col-md-2 navigation-bar"
                            style={{ height: webHeight }}
                            user={user} userData={userData} 
                        />

                        <div className={"child-body animeTransition " + col_md + " finishAcountSt"} style={{ height: webHeight, alignItems: "center", }}>


                            <NavigationBar
                                Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
                                active={{ a: "", b: "active", c: "", d: "", e: "" }}
                                clas="col-md-2 navigation-bar-iPhone"
                                style={{ height: navHeight, opacity: navOpacity }}
                                user={user} userData={userData} 
                            />

                            <div>
                                <div class="checkOutDiv">

                                    <div class="col-md-6 chckOtDv_">

                                    </div>

                                    <div class="col-md-6 chckOtDv_2">
                                        <div class="chckOtDv_2_div real_login_">
                                            <h3>Log In</h3>
                                            <p>Email</p>
                                            <input class="log_in_box_input real_login" type="email" onChange={(e) => { this.setState({ feild_1: e.target.value }) }} value={feild_1}></input>
                                            <p>Password</p>
                                            <input class="log_in_box_input real_login" type="password" onChange={(e) => { this.setState({ feild_2: e.target.value }) }} value={feild_2}></input>
                                            <a onClick={() => {this.register()}} style={{padding: 10, backgroundColor: "rgba(0,0,0,0.6)", borderRadius: 6}}><h4>log in</h4></a>
                                            <p style={{ color: "red", fontSize: 14, marginTop: 10 }}>{regError}</p>
                                        </div>
                                        <div class="LGN__" style={{paddingTop: 0}}>
                                        <p style={{fontSize: 12, marginRight: 5}}>Don't have an account yet? </p>
                                        <Link to="/schoolPortal" class={"LGN__-item "} style={{fontSize: 12}}>SIGN UP</Link>
                                    </div>
                                        {/* <div style={{ width:"100%", padding: "10px"}}>
                                            <div style={{height:"80%", width:"100%", boxShadow: "0px 0px 14px 3px rgba(0,0,0,0.3)", backgroundColor: "rgba(255, 253, 253, 0.7)", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "10px", paddingTop: "55px", paddingBottom: "65px", borderRadius: "5px"}}>
                                                <h3>Vérifiez votre adresse e-mail</h3>
                                                <p style={{textAlign: "center", paddingTop: "16px", fontWeight:"bold", fontSize: "12px"}}>Merci de votre intérêt pour IMES</p>
                                                <p style={{textAlign: "center", fontSize:"12px"}}>Veuillez confirmer votre adresse email pour commencer à utiliser ton compt</p>
                                                <a style={{marginBottom:"30px", marginTop: "30px", textDecoration: "none", cursor: "pointer", padding: "10px", width:"80%", textAlign:"center", backgroundColor: "rgb(177, 6, 23)", color: "white", borderRadius: "5px"}} href="https://www.imesbenin.com/landingPage/^actn:*VE*,da:*$email*^">confirmer mon adresse email</a>
                                                <p style={{paddingTop: "16px", fontSize:"10px"}}>Si vous n'avez pas créé de compte sur FedaPay <a href="https://www.imesbenin.com/">contactez-nous</a></p>
                                                <p style={{width: "100%", textAlign: "right", fontSize:"11px", marginTop:"40px"}}>Cordialement,</p>
                                                <p style={{width: "100%", textAlign: "right", fontSize:"11px", fontWeight:"bold"}}>L'équipe IMES</p>
                                            </div>
                                            <p style={{color: "rgba(0,0,0,0.6)", fontSize: "8px", textAlign: "center", paddingTop: "20px"}}>Si vous ne parvenez pas à cliquer sur le bouton Confirmer mon compte, copiez et collez l'URL ci-dessous dans votre navigateur Web: http://localhost:3000/landingPage/^actn:*VE*,da:*$email*^</p>
                                        </div> */}
                                    </div>


                                </div>
                                {/* <FedaCheckoutContainer options={checkoutEmbedOptions}
                                    style={{ height: 500, width: "100%", backgroundColor: '#eee' }} /> */}
                            </div>

                        </div>



                    </div>
                </div>

                <ExtraInfo
                    Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
                    clas="col-md-3 extra-info"
                    style={{ height: webHeight, top: topMore, opacity: topOpacity, width: "100%" }}
                />


                <div class="moreOptionsHolder" style={{ boxShadow: "0px 0px 10px 10px rgba(0, 0, 0, 0.38)" }}>
                    <div class={"moreOptionsHolder_Icon " + icnClass + " andPlus"} onClick={() => { this.showNave() }}></div>
                    <p>|</p>
                    <div class={"moreOptionsHolder_Icon " + icnMore + " andMenu"} onClick={() => { this.showMore() }}></div>
                </div>

            </>
        );
    }

}

export default withRouter(windowSize(Login));