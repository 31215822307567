import React from 'react'
// import logo from './logo.svg';
import '../../../css/overall.css';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import { MGB, Std, Fct } from './mgb'
import QRCode from "react-qr-code";
import { words } from '../../../../trans'
import NavigationBar from '../../../navigationBar'
import ExtraInfo from '../../../extraInfo'
import LoginRegister from '../../../logInRegister';
const fn_link = "https://www.imesbenin.com/server/fetchStudents.php"
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";



class MinistersNetwork extends React.Component {

  constructor() {
    super();
    this.state = {
      onScreen: false,
      showInfo: false,
      showInfo_ext: false,
      item: [],
      showNave: true,
      showMore: false,
      Std_: []
    }
  }

  makeOnscreenTrue() {
    setTimeout(() => {
      this.setState({
        onScreen: true
      })
    }, 10);
  }

  showInfo(item) {
    const screenWidth = window.innerWidth
    if (screenWidth > 991) {
      this.setState({
        showInfo: true,
        showInfo_ext: true,
        item
      })
    }
  }

  forceShowInfo(item) {
    const screenWidth = window.innerWidth
    this.setState({
      showInfo: true,
      showInfo_ext: true,
      item
    })
  }

  hideInfo() {
    this.setState({
      showInfo_ext: false,
    })
    setTimeout(() => {
      this.setState({
        showInfo: false,
        item: []
      })
    }, 600);
  }

  componentDidMount() {
    this.fetchImages()
    this.makeOnscreenTrue();
    setTimeout(() => {
      this.showNave()
    }, 200);
  }

  fetchImages() {
    fetch(fn_link, { // URL
      body: JSON.stringify(this.state), // data you send.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // headers: {
      //   'content-type': 'application/json'
      // },
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      redirect: 'follow', // *manual, follow, error
      referrer: 'no-referrer', // *client, no-referrer
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        this.setState({
          Std_: responseJson
        })
        // alert(JSON.stringify(services))
      })
      .catch((error) => {
        // alert(error);
      });
  }

  showNave() {
    this.setState({
      showNave: !this.state.showNave
    })
  }

  showMore() {
    this.setState({
      showMore: !this.state.showMore
    })
  }

  render() {
    const { showSpeech, showRegBox, showMIFBox, windowHeight, lang, user, userData } = this.props
    const { onScreen, showInfo, showInfo_ext, Std_ } = this.state
    const webHeight = window.innerWidth > 991 ? window.innerHeight - 60 : window.innerHeight - 0;
    const percent43 = webHeight * 43 / 100;
    const hotlineBoxHeight = percent43 * 60 / 100;
    const hotline_hotline_dpHeight = hotlineBoxHeight * 48 / 100
    const stdImgSmall = hotline_hotline_dpHeight - 25
    const stdImgBig = hotline_hotline_dpHeight - 15
    const hotline_hotline_dp = {
      height: hotline_hotline_dpHeight,
      width: hotline_hotline_dpHeight
    }
    const hotline_hotline_dp_image = {
      fontSize: hotline_hotline_dpHeight - 15,
    }
    const stdImg = {
      fontSize: window.innerWidth > 991 ? stdImgBig : stdImgSmall,
    }
    const col_md = onScreen === true ? "col-md-10" : "col-md-7"
    const shifAnim = showInfo_ext === true ? "fadeIn" : "fadeOut"
    const item2 = this.state.item

    const { showMore, showNave } = this.state;
    const icnClass = showNave === true ? "moreOptionsHolder_Icon_rot" : "";
    const icnMore = showMore === true ? "moreOptionsHolder_Icon_rot2" : "";
    const navHeight = showNave === true ? webHeight : 40;
    const navOpacity = showNave === true ? 1 : 0;
    const navBlocker = showNave === true ? "none" : "flex";
    const topMr = showMore === true ? 0 : webHeight;
    const topMore = window.innerWidth > 991 ? 0 : topMr;
    const topOpac = showMore === true ? 1 : 0;
    const topOpacity = window.innerWidth > 991 ? 1 : topOpac;

    const trans = {
      home: lang === "en" ? words.home.en : words.home.fr,
      Sp: lang === "en" ? words.Sp.en : words.Sp.fr,
      MN: lang === "en" ? words.MN.en : words.MN.fr,
      about: lang === "en" ? words.about.en : words.about.fr,
      onlineCls: lang === "en" ? words.onlineCls.en : words.onlineCls.fr,
      gallery: lang === "en" ? words.gallery.en : words.gallery.fr,
      copyrite: lang === "en" ? words.copyrite.en : words.copyrite.fr,
      establisd_y: lang === "en" ? words.establisd_y.en : words.establisd_y.fr,
      talk_t_s: lang === "en" ? words.talk_t_s.en : words.talk_t_s.fr,
      you_can_now: lang === "en" ? words.you_can_now.en : words.you_can_now.fr,
      click_h_t: lang === "en" ? words.click_h_t.en : words.click_h_t.fr,
      get_i_t: lang === "en" ? words.get_i_t.en : words.get_i_t.fr,
      contact_u_share_y: lang === "en" ? words.contact_u_share_y.en : words.contact_u_share_y.fr,
      members_o_t_gov: lang === "en" ? words.members_o_t_gov.en : words.members_o_t_gov.fr,
      menbers_of_t_f: lang === "en" ? words.menbers_of_t_f.en : words.menbers_of_t_f.fr,
      licenced_stud: lang === "en" ? words.licenced_stud.en : words.licenced_stud.fr,
    }

    return (
      <>
        <div class="extra_l cool_bg"></div>
        <div class="extra_l"></div>

        <div class="row body-row">
          <div class="col-md-12 newPageCorrector">

            <NavigationBar
              Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
              active={{ a: "", b: "", c: "active", d: "", e: "" }}
              clas="col-md-2 navigation-bar"
              style={{ height: webHeight }}
              user={user} userData={userData} 
            />

            <div className={"child-body animeTransition " + col_md} style={{ height: webHeight, alignItems: "center" }}>

              <NavigationBar
                Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
                active={{ a: "", b: "", c: "active", d: "", e: "" }}
                clas="col-md-2 navigation-bar-iPhone"
                style={{ height: navHeight, opacity: navOpacity }}
                user={user} userData={userData} 
              />

              {/* <p style={{ opacity: 0 }}>.</p> */}
              {
                user === "" ?
                <LoginRegister words={words} style={{marginBottom: 4}}/>
                :
                null
              }
              <div class="uline12 mgb_line"></div>
              <h4 class="mgb_h">{trans.members_o_t_gov}</h4>
              <div class="uline12 mgb_line"></div>
              <div class="row"></div>

              {MGB.map((item) =>
                <div class="col-md-4 mgbCards_holder" onClick={() => { this.showInfo(item) }}>
                  <div class="mgbCards">
                    <div class="mgbCardsIMG_holder">
                      <div class="mgbCardsIMG noBorder cool_bg">
                        <div class={"mgbCardsIMG " + item.img}></div>
                      </div>
                    </div>
                    <div class="writeup_holder">
                      <h6>{lang === "en" ? item.name.en : item.name.fr}</h6>
                      <p>{lang === "en" ? item.role.en : item.role.fr}</p>
                      <p class="mgb_info s_hide">{item.about}</p>
                      {/* <a class="hide_">more info</a> */}
                      <br />
                      {item.weblink ?
                        <><a className="weblink s_hide" href={item.weblink}>website : {item.weblink}</a></>
                        :
                        null
                      }
                      {item.fcblink ?
                        <><a className="fcblink s_hide" href={item.fcblink}>facebook : {item.fcblink}</a></>
                        :
                        null
                      }
                      {item.fcbpage ?
                        <><a className="fcbpage s_hide" href={item.fcbpage}>fcb page : {item.fcbpage}</a></>
                        :
                        null
                      }
                    </div>
                  </div>
                </div>
              )}

              <div class="row"></div>
              <p style={{ opacity: 0 }}>.</p>
              <div class="uline12 mgb_line"></div>
              <h4 class="mgb_h">{trans.menbers_of_t_f}</h4>
              <div class="uline12 mgb_line"></div>
              {/* <p style={{opacity: 0}}>.</p> */}

              <div class="mf_container">
                <div class="mf">
                  {Fct.map((item) =>
                    <div class="col-md-2 mgbCards_holder fct" onClick={() => { this.forceShowInfo(item) }}>
                      <div class="mgbCards fct">
                        <div class="mgbCardsIMG_holder fct">
                          <div class="mgbCardsIMG fct noBorder cool_bg">
                            <div class={"fctImag " + item.img}></div>
                          </div>
                        </div>
                        <div class="writeup_holder fct">
                          <h6>{lang === "en" ? item.name.en : item.name.fr}</h6>
                          <p>{lang === "en" ? item.role.en : item.role.fr}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div class="row"></div>
              <p style={{ opacity: 0 }}>.</p>
              <div class="uline12 mgb_line"></div>
              <h4 class="mgb_h">{trans.licenced_stud}</h4>
              <div class="uline12 mgb_line"></div>

              <div class="mf_container">
                <div class="mf">
                  {Std_.map((item) =>
                    <div class="col-md-2 stdCards_holder" onClick={() => { this.forceShowInfo(item) }}>
                      <div class="mgbCards stdCards">
                        <div class="mgCardsIMG_holder" style={{ display: 'flex', justifyContent: "center" }}>
                          {/* <div class={"stdCardsIMG " + item.img}></div> */}
                          {item.verified === "true" ?
                            <div class="qrCode">
                              <QRCode
                                value={item.name}
                                size={45}
                                bgColor="rgba(0,0,0,0)"
                                fgColor="rgb(10,10,10)"
                              />
                            </div>
                            :
                            <i class="fa fa-user stdCardsIMG" style={stdImg}></i>
                          }
                        </div>
                        <div class="writeup_holder">
                          <h6>{item.name}</h6>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

            </div>

            <ExtraInfo
                    Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
                    clas="col-md-3 extra-info"
                    style={{ height: webHeight, top: topMore, opacity: topOpacity }}
                />
            <div class="moreOptionsHolder">
              <div class={"moreOptionsHolder_Icon " + icnClass + " andPlus"} onClick={() => { this.showNave() }}></div>
              <p>|</p>
              <div class={"moreOptionsHolder_Icon " + icnMore + " andMenu"} onClick={() => { this.showMore() }}></div>
            </div>

          </div>
        </div>

        {showInfo === true ?
          <div className={"showInfo animated " + shifAnim} onClick={() => { this.hideInfo() }}>
            <div class="mgbCardsIMG_holder2">
              <div class="mgbCardsIMG2 noBorder cool_bg">
                <div class={"mgbCardsIMG2 " + item2.img}></div>
              </div>
            </div>
            <div class="writeup_holder2">
              {item2.name.en ? <h3>{lang === "en" ? item2.name.en : item2.name.fr}</h3> : <h3>{item2.name}</h3>}
              {item2.role ? <p>{lang === "en" ? item2.role.en : item2.role.fr}</p> : null}
              <p class="mgb_info">{item2.about}</p>
              <br />
              {item2.weblink ?
                <><a className="weblink" href={item2.weblink}>website : {item2.weblink}</a></>
                :
                null
              }
              {item2.fcblink ?
                <><a className="fcblink" href={item2.fcblink}>facebook : {item2.fcblink}</a></>
                :
                null
              }
              {item2.fcbpage ?
                <><a className="fcbpage" href={item2.fcbpage}>fcb page : {item2.fcbpage}</a></>
                :
                null
              }
            </div>
          </div>
          :
          null
        }
      </>
    );
  }

}

export default windowSize(MinistersNetwork);