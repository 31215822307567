import React from 'react';
import { render } from 'react-dom'
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";
import Homepage from './homepage'
import MinistersNetwork from './screens/navScreens/ministersNetwork/ministersNetwork'
import OnlineClasses from './screens/navScreens/onlineClasses/onlineClasses'
import SchoolPortal from './screens/navScreens/portals/portals'
import Gallery from './screens/navScreens/gallery/gallery'
import FinishSetingAccount from './screens/finishSetingAccount'
import Login from './screens/logIn'
import LandingPage from './screens/landingPage'
import PresdentsSpeech from './presdentsSpeech'
import RegisterBox from './registerBox'
import MoreinfoBox from './moreinfoBox'
import QrCodeScanner from './QrCodeScanner'
import AddUser from './screens/AddUser';

export default class Home extends React.Component {

    constructor() {
        super();
        this.state = {
            show_PresidentSpeech: false,
            show_RegisterBox: false,
            show_MoreinfoBox: false,
            show_QrCodeScanner: false,
        }
    }

    componentDidMount() {

        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "371276ed-c672-4cb3-9915-3fc40ca31215";

        (function () {
            var d = document;
            var s = d.createElement("script");
            s.src = "https://client.crisp.chat/l.js"; s.async = 1;
            d.getElementsByTagName("head")[0].appendChild(s);
        })
            ();
    }

    show_PresidentSpeech = () => {
        this.setState({
            show_PresidentSpeech: true
        })
    }

    hide_PresidentSpeech = () => {
        this.setState({
            show_PresidentSpeech: false
        })
    }

    show_RegisterBox = () => {
        this.setState({
            show_RegisterBox: true
        })
    }

    hide_RegisterBox = () => {
        this.setState({
            show_RegisterBox: false
        })
    }

    show_MoreinfoBox = () => {
        this.setState({
            show_MoreinfoBox: true
        })
    }

    hide_MoreinfoBox = () => {
        this.setState({
            show_MoreinfoBox: false
        })
    }

    show_QrCodeScanner = () => {
        this.setState({
            show_QrCodeScanner: true
        })
    }

    hide_QrCodeScanner = () => {
        this.setState({
            show_QrCodeScanner: false
        })
    }

    render() {
        const { show_PresidentSpeech, show_RegisterBox, show_MoreinfoBox, show_QrCodeScanner } = this.state;
        return (
            <>
                <Router>
                    <Switch>
                        <Route exact path="/" >
                            <>
                                <Homepage
                                    showSpeech={this.show_PresidentSpeech}
                                    showRegBox={this.show_RegisterBox}
                                    showMIFBox={this.show_MoreinfoBox}
                                    showQRCBox={this.show_QrCodeScanner}
                                    lang={this.props.lang}
                                    user={this.props.user}
                                    userData={this.props.userData}
                                />
                                {
                                    show_PresidentSpeech ?
                                        <PresdentsSpeech
                                            hideSpeech={this.hide_PresidentSpeech}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_RegisterBox ?
                                        <RegisterBox
                                            hideRegBox={this.hide_RegisterBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_MoreinfoBox ?
                                        <MoreinfoBox
                                            hideMIFBox={this.hide_MoreinfoBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_QrCodeScanner ?
                                        <QrCodeScanner
                                            hideQRCBox={this.hide_QrCodeScanner}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                            </>
                        </Route>
                        <Route path="/ministersNetwork">
                            <>
                                <MinistersNetwork
                                    showSpeech={this.show_PresidentSpeech}
                                    showRegBox={this.show_RegisterBox}
                                    showMIFBox={this.show_MoreinfoBox}
                                    showQRCBox={this.show_QrCodeScanner}
                                    lang={this.props.lang}
                                    user={this.props.user}
                                    userData={this.props.userData}
                                />
                                {
                                    show_PresidentSpeech ?
                                        <PresdentsSpeech
                                            hideSpeech={this.hide_PresidentSpeech}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_RegisterBox ?
                                        <RegisterBox
                                            hideRegBox={this.hide_RegisterBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_MoreinfoBox ?
                                        <MoreinfoBox
                                            hideMIFBox={this.hide_MoreinfoBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_QrCodeScanner ?
                                        <QrCodeScanner
                                            hideQRCBox={this.hide_QrCodeScanner}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                            </>
                        </Route>
                        <Route path="/onlineClasses">
                            <>
                                <OnlineClasses
                                    showSpeech={this.show_PresidentSpeech}
                                    showRegBox={this.show_RegisterBox}
                                    showMIFBox={this.show_MoreinfoBox}
                                    showQRCBox={this.show_QrCodeScanner}
                                    lang={this.props.lang}
                                    user={this.props.user}
                                    userData={this.props.userData}
                                />
                                {
                                    show_PresidentSpeech ?
                                        <PresdentsSpeech
                                            hideSpeech={this.hide_PresidentSpeech}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_RegisterBox ?
                                        <RegisterBox
                                            hideRegBox={this.hide_RegisterBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_MoreinfoBox ?
                                        <MoreinfoBox
                                            hideMIFBox={this.hide_MoreinfoBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_QrCodeScanner ?
                                        <QrCodeScanner
                                            hideQRCBox={this.hide_QrCodeScanner}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                            </>
                        </Route>
                        <Route path="/schoolPortal">
                            <>
                                <SchoolPortal
                                    showSpeech={this.show_PresidentSpeech}
                                    showRegBox={this.show_RegisterBox}
                                    showMIFBox={this.show_MoreinfoBox}
                                    showQRCBox={this.show_QrCodeScanner}
                                    lang={this.props.lang}
                                    user={this.props.user}
                                    userData={this.props.userData}
                                />
                                {
                                    show_PresidentSpeech ?
                                        <PresdentsSpeech
                                            hideSpeech={this.hide_PresidentSpeech}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_RegisterBox ?
                                        <RegisterBox
                                            hideRegBox={this.hide_RegisterBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_MoreinfoBox ?
                                        <MoreinfoBox
                                            hideMIFBox={this.hide_MoreinfoBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_QrCodeScanner ?
                                        <QrCodeScanner
                                            hideQRCBox={this.hide_QrCodeScanner}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                            </>
                        </Route>
                        <Route path="/gallery">
                            <>
                                <Gallery
                                    showSpeech={this.show_PresidentSpeech}
                                    showRegBox={this.show_RegisterBox}
                                    showMIFBox={this.show_MoreinfoBox}
                                    showQRCBox={this.show_QrCodeScanner}
                                    lang={this.props.lang}
                                    user={this.props.user}
                                    userData={this.props.userData}
                                />
                                {
                                    show_PresidentSpeech ?
                                        <PresdentsSpeech
                                            hideSpeech={this.hide_PresidentSpeech}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_RegisterBox ?
                                        <RegisterBox
                                            hideRegBox={this.hide_RegisterBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_MoreinfoBox ?
                                        <MoreinfoBox
                                            hideMIFBox={this.hide_MoreinfoBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_QrCodeScanner ?
                                        <QrCodeScanner
                                            hideQRCBox={this.hide_QrCodeScanner}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                            </>
                        </Route>
                        <Route path="/finishSetingAccount">
                            <>
                                <FinishSetingAccount
                                    showSpeech={this.show_PresidentSpeech}
                                    showRegBox={this.show_RegisterBox}
                                    showMIFBox={this.show_MoreinfoBox}
                                    showQRCBox={this.show_QrCodeScanner}
                                    lang={this.props.lang}
                                    user={this.props.user}
                                    userData={this.props.userData}
                                />
                                {
                                    show_PresidentSpeech ?
                                        <PresdentsSpeech
                                            hideSpeech={this.hide_PresidentSpeech}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_RegisterBox ?
                                        <RegisterBox
                                            hideRegBox={this.hide_RegisterBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_MoreinfoBox ?
                                        <MoreinfoBox
                                            hideMIFBox={this.hide_MoreinfoBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_QrCodeScanner ?
                                        <QrCodeScanner
                                            hideQRCBox={this.hide_QrCodeScanner}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                            </>
                        </Route>
                        <Route path="/login">
                            <>
                                <Login
                                    showSpeech={this.show_PresidentSpeech}
                                    showRegBox={this.show_RegisterBox}
                                    showMIFBox={this.show_MoreinfoBox}
                                    showQRCBox={this.show_QrCodeScanner}
                                    lang={this.props.lang}
                                    user={this.props.user}
                                    userData={this.props.userData}
                                />
                                {
                                    show_PresidentSpeech ?
                                        <PresdentsSpeech
                                            hideSpeech={this.hide_PresidentSpeech}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_RegisterBox ?
                                        <RegisterBox
                                            hideRegBox={this.hide_RegisterBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_MoreinfoBox ?
                                        <MoreinfoBox
                                            hideMIFBox={this.hide_MoreinfoBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_QrCodeScanner ?
                                        <QrCodeScanner
                                            hideQRCBox={this.hide_QrCodeScanner}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                            </>
                        </Route>
                        <Route path="/addUser">
                            <AddUser
                                showSpeech={this.show_PresidentSpeech}
                                showRegBox={this.show_RegisterBox}
                                showMIFBox={this.show_MoreinfoBox}
                                showQRCBox={this.show_QrCodeScanner}
                                lang={this.props.lang}
                                user={this.props.user}
                                userData={this.props.userData}
                            />
                        </Route>
                        <Route path="/landingPage/:id">
                            <>
                                <LandingPage
                                    showSpeech={this.show_PresidentSpeech}
                                    showRegBox={this.show_RegisterBox}
                                    showMIFBox={this.show_MoreinfoBox}
                                    showQRCBox={this.show_QrCodeScanner}
                                    lang={this.props.lang}
                                    user={this.props.user}
                                    userData={this.props.userData}
                                />
                                {
                                    show_PresidentSpeech ?
                                        <PresdentsSpeech
                                            hideSpeech={this.hide_PresidentSpeech}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_RegisterBox ?
                                        <RegisterBox
                                            hideRegBox={this.hide_RegisterBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_MoreinfoBox ?
                                        <MoreinfoBox
                                            hideMIFBox={this.hide_MoreinfoBox}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                                {
                                    show_QrCodeScanner ?
                                        <QrCodeScanner
                                            hideQRCBox={this.hide_QrCodeScanner}
                                            lang={this.props.lang}
                                        />
                                        :
                                        null
                                }
                            </>
                        </Route>
                        <Redirect from='*' to='/finishSetingAccount' />
                    </Switch>
                </Router>

            </>
        )
    }
}