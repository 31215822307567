import React from 'react'
// import logo from './logo.svg';
import '../../../css/overall.css';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import { Gallery, Item } from 'react-photoswipe-gallery'
import Thumbnail from './thumbnail'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
const link = "https://www.imesbenin.com"
// import { MGB } from './mgb'
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";



class ExtraAlbum extends React.Component {

    constructor() {
        super();
        this.state = {
            albumFiles: [],
            mediaFiles: [],
            loading: "true",
            speed: 1,
        }
    }

    fetchAlbums() {
        const ftdata = { albumName: this.props.albumName }
        fetch(link + "/server/ftcFromAlbum.php", { // URL
            body: JSON.stringify(ftdata), // data you send.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // headers: {
            //   'content-type': 'application/json'
            // },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then((response) => response.json())
            .then(async (responseJson) => {
                // alert(responseJson);
                this.setState({
                    albumFiles: responseJson,
                })
            })
            .catch((error) => {
                // alert(error);
            });
    }

    fetchImages() {
        fetch(link + "/server/feeds.php", { // URL
            body: JSON.stringify(this.state), // data you send.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // headers: {
            //   'content-type': 'application/json'
            // },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then((response) => response.json())
            .then(async (responseJson) => {
                this.setState({
                    mediaFiles: responseJson
                })
                // alert(JSON.stringify(services))
            })
            .catch((error) => {
                // alert(error);
            });
    }

    timeOutSpinAmin() {
        setTimeout(() => {
            this.setState({
                loading: "false"
            })
        }, 7000);
    }

    componentDidMount() {
        this.timeOutSpinAmin()

        const { mediaFiles } = this.props;
        setTimeout(() => {
            const newData = mediaFiles;
            this.setState({
                mediaFiles: newData,
                loading: "false"
            })
        }, 400);
    }

    render() {
        const { webHeight, Header, type, activeTab, goBack } = this.props
        const { albumFiles, mediaFiles, loading, speed } = this.state
        const GrdPcs = mediaFiles.filter(i => i.type === "graduation")
        const SchlOM = mediaFiles.filter(i => i.type === "som")
        const Privte = mediaFiles.filter(i => i.type === "private")
        const staticF = activeTab  === 5 ? Privte : activeTab  === 6 ? GrdPcs : SchlOM;
        const files = type === "static" ? staticF : albumFiles

        return (
            <>
                <div class="row TabScreen" style={{ height: webHeight, overflowY: "scroll" }}>
                    <div class="backbutton" onClick={() => { goBack() }}>
                        <i class="fa fa-arrow-left"></i>
                        <p>Albums</p>
                    </div>
                    {
                        files.length >= 1 ?
                            <Gallery>
                                {files.map((item) =>
                                    <Thumbnail item={item} link={link} />
                                )}
                            </Gallery>
                            :
                            <div class="loadingMedia_">
                                {
                                    loading === "true" ?
                                        <i class="fa fa-spinner LM_icon " style={{ animation: `spin ${speed}s linear infinite` }}></i>
                                        :
                                        <div class="LM_child">
                                            <i class="fa fa-gears LM_icon"></i>
                                            <p class="LM_p">{Header} Not Found</p>
                                        </div>
                                }
                            </div>
                    }
                </div>
            </>
        );
    }

}

export default windowSize(ExtraAlbum);