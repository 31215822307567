import React from 'react'
// import logo from './logo.svg';
import '../../../css/overall.css';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import Album from './albums'
import Photo from './photos'
import Video from './video'
import All from './all'
import ExtraAlbum from './extraAlbum'
import { words } from '../../../../trans'
import NavigationBar from '../../../navigationBar'
import ExtraInfo from '../../../extraInfo'
import LoginRegister from '../../../logInRegister';
// const fn_link = "http://imesbenin.com/imakerdbFolder/example/test/stats/alpha.php";
const fn_link = "https://www.imesbenin.com/server/feeds.php"
// import { MGB } from './mgb'
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";



class Gallery extends React.Component {

  constructor() {
    super();
    this.state = {
      onScreen: false,
      showInfo: false,
      showInfo_ext: false,
      item: [],
      showNave: true,
      showMore: false,
      textTshoe: "Fetching Images...",
      activeTab: 1,
      mediaFiles: [],
      type: "",
      albumName: "",
    }
  }

  showAlbums() {
    this.setState({
      activeTab: 1,
    })
  }

  showPhotos() {
    this.setState({
      activeTab: 2,
    })
  }

  showVideos() {
    this.setState({
      activeTab: 3,
    })
  }

  showAll() {
    this.setState({
      activeTab: 4,
    })
  }

  showExtraAlbum(config) {
    const { type, albumName, activeTab } = config
    this.setState({ type, albumName, activeTab })
  }

  animateText() {
    setTimeout(() => {
      this.setState({
        textTshoe: "Fetching Images"
      })
      setTimeout(() => {
        this.setState({
          textTshoe: "Fetching Images."
        })
        setTimeout(() => {
          this.setState({
            textTshoe: "Fetching Images.."
          })
          setTimeout(() => {
            this.setState({
              textTshoe: "Fetching Images..."
            })
            setTimeout(() => {
              this.setState({
                textTshoe: "Fetching Images"
              })
              setTimeout(() => {
                this.setState({
                  textTshoe: "Fetching Images."
                })
                setTimeout(() => {
                  this.setState({
                    textTshoe: "Fetching Images.."
                  })
                  setTimeout(() => {
                    this.setState({
                      textTshoe: "Fetching Images..."
                    })
                    setTimeout(() => {
                      this.setState({
                        textTshoe: "Fetching Images"
                      })
                      setTimeout(() => {
                        this.setState({
                          textTshoe: "Fetching Images."
                        })
                        setTimeout(() => {
                          this.setState({
                            textTshoe: ""
                            // Poor Internet or DataBase Connection
                          })
                        }, 600);
                      }, 600);
                    }, 600);
                  }, 600);
                }, 600);
              }, 600);
            }, 600);
          }, 600);
        }, 600);
      }, 600);
    }, 600);
  }

  makeOnscreenTrue() {
    setTimeout(() => {
      this.setState({
        onScreen: true
      })
    }, 10);
  }

  showInfo(item) {
    const screenWidth = window.innerWidth
    if (screenWidth > 991) {
      this.setState({
        showInfo: true,
        showInfo_ext: true,
        item
      })
    }
  }

  hideInfo() {
    this.setState({
      showInfo_ext: false,
    })
    setTimeout(() => {
      this.setState({
        showInfo: false,
        item: []
      })
    }, 600);
  }

  fetchImages() {
    fetch(fn_link, { // URL
      body: JSON.stringify(this.state), // data you send.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // headers: {
      //   'content-type': 'application/json'
      // },
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      redirect: 'follow', // *manual, follow, error
      referrer: 'no-referrer', // *client, no-referrer
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        // alert(responseJson);
        // const services = Object.values(responseJson)
        this.setState({
          mediaFiles: responseJson
        })
        // alert(JSON.stringify(services))
      })
      .catch((error) => {
        // alert(error);
      });
  }

  componentDidMount() {
    // alert(JSON.stringify(this.props.match.params))
    this.makeOnscreenTrue();
    setTimeout(() => {
      this.showNave()
      this.animateText()
    }, 200);
    this.fetchImages()
  }

  showNave() {
    this.setState({
      showNave: !this.state.showNave
    })
  }

  showMore() {
    this.setState({
      showMore: !this.state.showMore
    })
  }

  render() {
    const { showSpeech, showRegBox, showMIFBox, windowHeight, lang, user, userData  } = this.props
    const { type, albumName, onScreen, showInfo, showInfo_ext, activeTab, mediaFiles } = this.state
    const webHeight = window.innerWidth > 991 ? window.innerHeight - 60 : window.innerHeight - 0;
    const newPaddingTop = window.innerWidth > 991 ? 0 : 40;
    const percent43 = webHeight * 43 / 100;
    const hotlineBoxHeight = percent43 * 60 / 100;
    const hotline_hotline_dpHeight = hotlineBoxHeight * 48 / 100
    const hotline_hotline_dp = {
      height: hotline_hotline_dpHeight,
      width: hotline_hotline_dpHeight
    }
    const hotline_hotline_dp_image = {
      fontSize: hotline_hotline_dpHeight - 15,
    }
    const col_md = onScreen === true ? "col-md-10" : "col-md-7"
    const shifAnim = showInfo_ext === true ? "fadeIn" : "fadeOut"
    const item2 = this.state.item

    const { showMore, showNave } = this.state;
    const icnClass = showNave === true ? "moreOptionsHolder_Icon_rot" : "";
    const icnMore = showMore === true ? "moreOptionsHolder_Icon_rot2" : "";
    const navHeight = showNave === true ? webHeight : 40;
    const navOpacity = showNave === true ? 1 : 0;
    const navBlocker = showNave === true ? "none" : "flex";
    const topMr = showMore === true ? 0 : webHeight;
    const topMore = window.innerWidth > 991 ? 0 : topMr;
    const topOpac = showMore === true ? 1 : 0;
    const topOpacity = window.innerWidth > 991 ? 1 : topOpac;
    const MGB = [];
    const albumAc = activeTab === 1 ? "active" : "";
    const photoAc = activeTab === 2 ? "active" : "";
    const videoAc = activeTab === 3 ? "active" : "";
    const allAc = activeTab === 4 ? "active" : "";
    const Header = activeTab === 1 ? "Albums" : activeTab === 2 ? "Photos" : activeTab === 3 ? "Videos" : activeTab === 4 ? "All" : activeTab === 5 ? "Private Media" : activeTab === 6 ? "Graduation Media" : activeTab === 7 ? "School of the Miraculous Media" : " Media";

    const trans = {
      home: lang === "en" ? words.home.en : words.home.fr,
      Sp: lang === "en" ? words.Sp.en : words.Sp.fr,
      MN: lang === "en" ? words.MN.en : words.MN.fr,
      about: lang === "en" ? words.about.en : words.about.fr,
      onlineCls: lang === "en" ? words.onlineCls.en : words.onlineCls.fr,
      gallery: lang === "en" ? words.gallery.en : words.gallery.fr,
      copyrite: lang === "en" ? words.copyrite.en : words.copyrite.fr,
      establisd_y: lang === "en" ? words.establisd_y.en : words.establisd_y.fr,
      talk_t_s: lang === "en" ? words.talk_t_s.en : words.talk_t_s.fr,
      you_can_now: lang === "en" ? words.you_can_now.en : words.you_can_now.fr,
      click_h_t: lang === "en" ? words.click_h_t.en : words.click_h_t.fr,
      get_i_t: lang === "en" ? words.get_i_t.en : words.get_i_t.fr,
      contact_u_share_y: lang === "en" ? words.contact_u_share_y.en : words.contact_u_share_y.fr,
      albums: lang === "en" ? words.albums.en : words.albums.fr,
      photos: lang === "en" ? words.photos.en : words.photos.fr,
      videos: lang === "en" ? words.videos.en : words.videos.fr,
      all: lang === "en" ? words.all.en : words.all.fr,
      private: lang === "en" ? words.private.en : words.private.fr,
      graduation_photos: lang === "en" ? words.graduation_photos.en : words.graduation_photos.fr,
      school_of_the_mir: lang === "en" ? words.school_of_the_mir.en : words.school_of_the_mir.fr,
      // ___: lang === "en" ? words.___.en : words.___.fr,
    }

    return (
      <>
        <div class="extra_l cool_bg"></div>
        <div class="extra_l"></div>

        <div class="row body-row">
          <div class="col-md-12 newPageCorrector">

            <NavigationBar
              Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
              active={{ a: "", b: "", c: "", d: "", e: "active" }}
              clas="col-md-2 navigation-bar"
              style={{ height: webHeight }}
              user={user} userData={userData} 
            />

            <div className={"child-body glry animeTransition " + col_md} style={{ height: webHeight, alignItems: "center", padding: 0, paddingLeft: 0, paddingRight: 0, overflowY: "hidden", paddingTop: newPaddingTop }}>

              <NavigationBar
                Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
                active={{ a: "", b: "", c: "active", d: "", e: "" }}
                clas="col-md-2 navigation-bar-iPhone"
                style={{
                  height: navHeight,
                  opacity: navOpacity,
                  position: "absolute",
                  zIndex: 1,
                  backgroundColor: "rgba(0,0,0,0.8)"
                }}
                user={user} userData={userData} 
              />

              {
                user === "" ?
                <LoginRegister words={words} style={{marginBottom: 4}}/>
                :
                null
              }
              


              <div class="glr_nav_bar">
                <div class={"glr_nav " + albumAc} onClick={() => { this.showAlbums() }}>
                  <i class="fa fa-film GNIcon"></i>
                  <p class="GNText">{trans.albums}</p>
                </div>
                <div class={"glr_nav " + photoAc} onClick={() => { this.showPhotos() }}>
                  <i class="fa fa-image GNIcon"></i>
                  <p class="GNText">{trans.photos}</p>
                </div>
                <div class={"glr_nav " + videoAc} onClick={() => { this.showVideos() }}>
                  <i class="fa fa-video-camera GNIcon"></i>
                  <p class="GNText">{trans.videos}</p>
                </div>
                <div class={"glr_nav gnEnd " + allAc} onClick={() => { this.showAll() }}>
                  <i class="fa fa-th-large GNIcon"></i>
                  <p class="GNText">{trans.all}</p>
                </div>
              </div>

              {/* { window.innerWidth < 991 ? 
                <h4 class="mgb_h">{Header}</h4>
                :
                <div></div>
              } */}

              {activeTab === 1 ?
                <Album
                  activeTab={activeTab}
                  mediaFiles={mediaFiles}
                  webHeight={webHeight}
                  Header={Header}
                  showAlbums={() => { this.showAlbums() }}
                  showPhotos={() => { this.showPhotos() }}
                  showVideos={() => { this.showVideos() }}
                  showAll={() => { this.showAll() }}
                  showExtraAlbum={(config) => { this.showExtraAlbum(config) }}
                  albums_={trans.albums}
                  photos_={trans.photos}
                  videos_={trans.videos}
                  all_={trans.all}
                  private_={trans.private}
                  graduation_photos_={trans.graduation_photos}
                  school_of_the_mir_={trans.school_of_the_mir}
                />
                :
                activeTab === 2 ?
                  <Photo activeTab={activeTab} mediaFiles={mediaFiles} webHeight={webHeight} Header={Header} />
                  :
                  activeTab === 3 ?
                    <Video activeTab={activeTab} mediaFiles={mediaFiles} webHeight={webHeight} Header={Header} />
                    :
                    activeTab === 4 ?
                      <All activeTab={activeTab} mediaFiles={mediaFiles} webHeight={webHeight} Header={Header} />
                      :
                      <ExtraAlbum
                        activeTab={activeTab}
                        mediaFiles={mediaFiles}
                        webHeight={webHeight}
                        Header={Header}
                        goBack={() => { this.showAlbums() }}
                        type={type} albumName={albumName}
                      />
              }





              {/* {MGB.map((item) =>
                <div class="col-md-4 mgbCards_holder" onClick={() => { this.showInfo(item) }}>
                  <div class="mgbCards">
                    <div class="mgbCardsIMG_holder">
                      <div class="mgbCardsIMG cool_bg"></div>
                    </div>
                    <div class="writeup_holder">
                      <h6>{item.name}</h6>
                      <p>{item.role}</p>
                      <p class="mgb_info s_hide">{item.about}</p>
                      <a class="hide_">more info</a>
                      <br />
                      {item.weblink ?
                        <><a className="weblink s_hide" href={item.weblink}>website : {item.weblink}</a></>
                        :
                        null
                      }
                      {item.fcblink ?
                        <><a className="fcblink s_hide" href={item.fcblink}>facebook : {item.fcblink}</a></>
                        :
                        null
                      }
                      {item.fcbpage ?
                        <><a className="fcbpage s_hide" href={item.fcbpage}>fcb page : {item.fcbpage}</a></>
                        :
                        null
                      }
                    </div>
                  </div>
                </div>
              )} */}

              {/* <div class="row"></div>
              <p style={{ opacity: 0 }}>.</p>
              <div class="uline12 mgb_line"></div>
              <h4 class="mgb_h">MEMBERS OF THE FACULTY</h4>
              <div class="uline12 mgb_line"></div>
              {/* <p style={{opacity: 0}}>.</p> */}

              {/* <div class="mf_container">
                <div class="mf"></div>
              </div> */}

              {/* <div class="row"></div>
              <p style={{ opacity: 0 }}>.</p>
              <div class="uline12 mgb_line"></div>
              <h4 class="mgb_h">LICENCED STUDENTS</h4>
              <div class="uline12 mgb_line"></div> */}

              {/* <div class="mf_container">
                <div class="mf"></div>
              </div>  */}

            </div>

            <ExtraInfo
              Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
              clas="col-md-3 extra-info"
              style={{ height: webHeight, top: topMore, opacity: topOpacity }}
            />
            <div class="moreOptionsHolder">
              <div class={"moreOptionsHolder_Icon " + icnClass + " andPlus"} onClick={() => { this.showNave() }}></div>
              <p>|</p>
              <div class={"moreOptionsHolder_Icon " + icnMore + " andMenu"} onClick={() => { this.showMore() }}></div>
            </div>

          </div>
        </div>

        {showInfo === true ?
          <div className={"showInfo animated " + shifAnim} onClick={() => { this.hideInfo() }}>
            <div class="mgbCardsIMG_holder2">
              <div class="mgbCardsIMG2 cool_bg"></div>
            </div>
            <div class="writeup_holder2">
              <h3>{item2.name}</h3>
              <p>{item2.role}</p>
              <p class="mgb_info">{item2.about}</p>
              <br />
              {item2.weblink ?
                <><a className="weblink" href={item2.weblink}>website : {item2.weblink}</a></>
                :
                null
              }
              {item2.fcblink ?
                <><a className="fcblink" href={item2.fcblink}>facebook : {item2.fcblink}</a></>
                :
                null
              }
              {item2.fcbpage ?
                <><a className="fcbpage" href={item2.fcbpage}>fcb page : {item2.fcbpage}</a></>
                :
                null
              }
            </div>
          </div>
          :
          null
        }
      </>
    );
  }

}

export default windowSize(Gallery);