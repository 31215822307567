export const CLASSES_ = [
    {
        id: "1",
        title: "Lesson 1: Example",
        role: "International President",
        img: "assets/img/img/zimes5.jpg",
        about: "Information about thsi board member would appear here",
        weblink: "https://askDaddy.com",
        fcblink: "https://askDaddy.com",
        fcbpage: "https://askDaddy.com",
    },
    {
        id: "1",
        title: "Lesson 1: Example",
        role: "International Director",
        img: "assets/img/img/zimes4.jpg",
        about: "Information about thsi board member would appear here",
        weblink: "https://askDaddy.com",
        fcblink: "https://askDaddy.com",
        fcbpage: "https://askDaddy.com",
    },
    {
        id: "1",
        title: "Lesson 1: Example",
        role: "Assistant International Director",
        img: "assets/img/img/zimes3.jpg",
        about: "Information about thsi board member would appear here",
        weblink: "https://askDaddy.com",
        fcblink: "https://askDaddy.com",
        fcbpage: "https://askDaddy.com",
    },
    {
        id: "1",
        title: "Lesson 1: Example",
        role: "Director of Opperations",
        img: "assets/img/img/zimes2.jpg",
        about: "Information about thsi board member would appear here",
        weblink: "https://askDaddy.com",
        fcblink: "https://askDaddy.com",
        fcbpage: "https://askDaddy.com",
    },
    {
        id: "1",
        title: "Lesson 1: Example",
        role: "Matron of the School",
        img: "assets/img/img/imes335.jpg",
        about: "Information about thsi board member would appear here",
        weblink: "https://askDaddy.com",
        fcblink: "https://askDaddy.com",
        fcbpage: "https://askDaddy.com",
    },
    {
        id: "1",
        title: "Lesson 1: Example",
        role: "President of the School",
        img: "assets/img/img/zimes1.jpg",
        about: "Information about thsi board member would appear here",
        weblink: "https://askDaddy.com",
        fcblink: "https://askDaddy.com",
        fcbpage: "https://askDaddy.com",
    },
]