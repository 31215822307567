import React from 'react'
// import logo from './logo.svg';
import './css/overall.css';
import Screens from './screens';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import { words } from '../trans'
import NavigationBar from './navigationBar'
import ExtraInfo from './extraInfo'
import { withRouter } from "react-router";
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";

class Homepage extends React.Component {
  constructor() {
    super();
    this.state = {
      showNave: true,
      showMore: false
    }
  }

  showNave() {
    this.setState({
      showNave: !this.state.showNave
    })
  }

  showMore() {
    this.setState({
      showMore: !this.state.showMore
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.showNave()
    }, 200);
    // let id = this.props.match.params;
        // alert(JSON.stringify(id))
  }

  render() {
    const { showSpeech, showRegBox, showMIFBox, windowHeight, lang, showQRCBox, user, userData  } = this.props
    const webHeight = window.innerWidth > 991 ? window.innerHeight - 60 : window.innerHeight - 0;
    const newstyle = window.innerWidth > 991 ? { zIndex: 0 } : { padding: 3, margin: 0 };
    const percent43 = webHeight * 43 / 100;
    const hotlineBoxHeight = percent43 * 60 / 100;
    const hotline_hotline_dpHeight = hotlineBoxHeight * 48 / 100
    const hotline_hotline_dp = {
      height: hotline_hotline_dpHeight,
      width: hotline_hotline_dpHeight
    }
    const hotline_hotline_dp_image = {
      fontSize: hotline_hotline_dpHeight - 15,
    }

    const { showMore, showNave } = this.state;
    const icnClass = showNave === true ? "moreOptionsHolder_Icon_rot" : "";
    const icnMore = showMore === true ? "moreOptionsHolder_Icon_rot2" : "";
    const navHeight = showNave === true ? webHeight : 40;
    const navOpacity = showNave === true ? 1 : 0;
    const navBlocker = showNave === true ? "none" : "flex";
    const topMr = showMore === true ? 0 : webHeight;
    const topMore = window.innerWidth > 991 ? 0 : topMr;
    const topOpac = showMore === true ? 1 : 0;
    const topOpacity = window.innerWidth > 991 ? 1 : topOpac;

    const trans = {
      home: lang === "en" ? words.home.en : words.home.fr,
      Sp: lang === "en" ? words.Sp.en : words.Sp.fr,
      MN: lang === "en" ? words.MN.en : words.MN.fr,
      about: lang === "en" ? words.about.en : words.about.fr,
      onlineCls: lang === "en" ? words.onlineCls.en : words.onlineCls.fr,
      gallery: lang === "en" ? words.gallery.en : words.gallery.fr,
      copyrite: lang === "en" ? words.copyrite.en : words.copyrite.fr,
      establisd_y: lang === "en" ? words.establisd_y.en : words.establisd_y.fr,
      talk_t_s: lang === "en" ? words.talk_t_s.en : words.talk_t_s.fr,
      you_can_now: lang === "en" ? words.you_can_now.en : words.you_can_now.fr,
      click_h_t: lang === "en" ? words.click_h_t.en : words.click_h_t.fr,
      get_i_t: lang === "en" ? words.get_i_t.en : words.get_i_t.fr,
      contact_u_share_y: lang === "en" ? words.contact_u_share_y.en : words.contact_u_share_y.fr,
    }

    return (
      <>
        {/* <div class="extra_l cool_bg"></div> */}
        {/* <div class="extra_l"></div> */}

        <div class="row body-row">
          <div class="col-md-12" style={newstyle}>

            <NavigationBar
              Data={{ showSpeech, trans, showQRCBox, webHeight }}
              active={{ a: "active", b: "", c: "", d: "", e: "" }}
              clas="col-md-2 navigation-bar"
              style={{ height: webHeight }}
              user={user} userData={userData} 
            />

            <div class="col-md-7 child-body" style={{ height: webHeight }}>
              <NavigationBar
                Data={{ showSpeech, trans, showQRCBox, webHeight }}
                active={{ a: "active", b: "", c: "", d: "", e: "" }}
                clas="col-md-2 navigation-bar-iPhone"
                style={{ height: navHeight, opacity: navOpacity, width: "100%" }}
                user={user} userData={userData} 
              />


              <Screens showRegBox={showRegBox} showMIFBox={showMIFBox} lang={lang} user={user} userData={userData}  />
            </div>

            <ExtraInfo
              Data={{ showSpeech, trans, showQRCBox, webHeight, lang }}
              clas="col-md-3 extra-info"
              style={{ height: webHeight, top: topMore, opacity: topOpacity }}
            />


            <div class="moreOptionsHolder">
              <div class={"moreOptionsHolder_Icon " + icnClass + " andPlus"} onClick={() => { this.showNave() }}></div>
              <p>|</p>
              <div class={"moreOptionsHolder_Icon " + icnMore + " andMenu"} onClick={() => { this.showMore() }}></div>
            </div>

          </div>
        </div>
      </>
    );
  }

}

export default withRouter(windowSize(Homepage));