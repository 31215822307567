import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import WebHome from "./webHome/index"
import Page1 from "./webHome/page1"
import Page2 from "./webHome/page2"
import Page3 from "./webHome/page3"
import Page4 from "./webHome/page4"

export default class Screens extends React.Component{
    render() {
        return (
            // <Router>
            //   <Switch>
            //     <Route exact path="/" >
            //         <WebHome showRegBox={this.props.showRegBox} showMIFBox={this.props.showMIFBox} lang={this.props.lang}/>
            //     </Route>
            //     <Route path="/page1">
            //         <Page1 />
            //     </Route>
            //     <Route path="/page2">
            //         <Page2 />
            //     </Route>
            //     <Route path="/page3">
            //         <Page3 />
            //     </Route>
            //     <Route path="/page4">
            //         <Page4 />
            //     </Route>
            //   </Switch>
            // </Router>
            <WebHome showRegBox={this.props.showRegBox} showMIFBox={this.props.showMIFBox} lang={this.props.lang} user={this.props.user} userData={this.props.userData} />
          );
    }
}
