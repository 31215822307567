export const MGB = [
    {
        id: "1",
        name: {
            en:"Bishop David and Deborah Rice",
            fr:"Évêque David et Deborah Rice"
        },
        role: {
            en:"International President",
            fr:"Président international"
        },
        img: "im1",
        about: "",
        weblink: "",
        fcblink: "",
        fcbpage: "",
    },
    {
        id: "1",
        name: {
            en:"DR. Paul Uzoma",
            fr:"DR. Paul Uzoma"
        },
        role: {
            en:"International Director",
            fr:"Directeur international"
        },
        img: "im2",
        about: "",
        weblink: "",
        fcblink: "",
        fcbpage: "",
    },
    {
        id: "1",
        name: {
            en:"Prophetesse Excel Paul Uzoma",
            fr:"Prophétesse Excel Paul Uzoma"
        },
        role: {
            en:"Assistant International Director",
            fr:"Directeur international adjoint"
        },
        img: "im3",
        about: "",
        weblink: "",
        fcblink: "",
        fcbpage: "",
    },
    {
        id: "1",
        name: {
            en:"Reverend Line Eliel",
            fr:"Révérend Line Eliel"
        },
        role: {
            en: "Director of Opperations",
            fr: "Directeur des opérations"
        },
        img: "im4",
        about: "",
        weblink: "",
        fcblink: "",
        fcbpage: "",
    },
    {
        id: "1",
        name: {
            en:"Reverend Doctor Grace Lawani",
            fr:"Révérend Docteur Grace Lawani"
        },
        role: {
            en:"Matron of the School",
            fr:"Matrone de l'école"
        },
        img: "im5",
        about: "",
        weblink: "",
        fcblink: "",
        fcbpage: "",
    },
    {
        id: "1",
        name: {
            en:"Apostle Vincent Coovi Adjomatin",
            fr:"Apôtre Vincent Coovi Adjomatin"
        },
        role: {
            en: "President of the School",
            fr: "Président de l'école"
        },
        img: "im6",
        about: "",
        weblink: "",
        fcblink: "",
        fcbpage: "",
    },
]

export const Fct = [
    {
        id: "1",
        name: {
            en:"DR. Paul Uzoma",
            fr:"DR. Paul Uzoma"
        },
        role: {
            en:"International Director",
            fr:"Directeur international"
        },
        img: "im2",
        about: "",
        weblink: "",
        fcblink: "",
        fcbpage: "",
    },
    {
        id: "1",
        name: {
            en:"DR. Paul Uzoma",
            fr:"DR. Paul Uzoma"
        },
        role: {
            en:"International Director",
            fr:"Directeur international"
        },
        img: "im8",
        about: "",
        weblink: "",
        fcblink: "",
        fcbpage: "",
    },
    {
        id: "1",
        name: {
            en:"Reverend Line Eliel",
            fr:"Révérend Line Eliel"
        },
        role: {
            en: "Director of Opperations",
            fr: "Directeur des opérations"
        },
        img: "im9",
        about: "",
        weblink: "",
        fcblink: "",
        fcbpage: "",
    },
    {
        id: "1",
        name: {
            en:"Pastor Anaurd",
            fr:"Pasteur Anaurd"
        },
        role: {
            en:"Dine of studies",
            fr:"Dine d'études"
        },
        img: "im10",
        about: "",
        weblink: "",
        fcblink: "",
        fcbpage: "",
    },
    {
        id: "1",
        name: {
            en:"Pastor Ann",
            fr:"Pasteur Ann"
        },
        role: "-",
        img: "im11",
        about: "",
        weblink: "",
        fcblink: "",
        fcbpage: "",
    },
]

export const HotlineArr = [
    {
        id: "1",
        name: {
            en:"Prophetesse Excel ",
            fr:"Prophetesse Excel "
        },
        role: {
            en:"The Assistant Director",
            fr:"Le Directeur Assistant"
        },
        img: "im8",
        about: "",
        weblink: "",
        fcblink: "",
        fcbpage: "",
    },
    {
        id: "1",
        name: {
            en:"Reverend Line Eliel",
            fr:"Révérend Line Eliel"
        },
        role: {
            en: "Director of Opperations",
            fr: "Directeur des opérations"
        },
        img: "im9",
        about: "",
        weblink: "",
        fcblink: "",
        fcbpage: "",
    },
    {
        id: "1",
        name: {
            en:"Pastor Anaurd",
            fr:"Pasteur Anaurd"
        },
        role: {
            en:"Dine of studies (IMES)",
            fr:"Dine d'études (IMES)"
        },
        img: "im10",
        about: "",
        weblink: "",
        fcblink: "",
        fcbpage: "",
    },
]

export const Std = [
    {
        id: "1",
        name: "AYOITO COMLAN LEOPOLD",
        verified: "false",
        img: "blankprofile",
    },
    {
        id: "2",
        name: "TOKLO TCHEGNON TANGUY",
        verified: "false",
        img: "blankprofile",
    },
    {
        id: "3",
        name: "FIAGBENOU DAVID STEPHANE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "4",
        name: "ORUSON ARMSTRONG DANIEL",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "5",
        name: "GBASSI ANDRE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "6",
        name: "GBEHOU AMEDE JONAS",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "7",
        name: "ATINYOLOSSOG NI JOSUE JEAN CHRISTOPHE S.",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "8",
        name: "GNANSSOUNOU SIMON",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "9",
        name: "HOUEDANOU MAHUGNON JEAN - JACQUES DIEU DONNEE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "10",
        name: "AGBEMEHEIN VICTOIRE TEKO",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "11",
        name: "ADJOMATIN COOVI VINCENT",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "12",
        name: "NOUATCHI KINHA VIDEVA ESTELLE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "13",
        name: "KINHA MODELE KPONDEHOU",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "14",
        name: "ATIOUKPE THOMAS CAROLINE HERMINE YELIAN EPSE MESSENON",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "15",
        name: "ERUKA CHENEMEREM MOSES",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "16",
        name: "ORIAKU STANLEY CHIZURUM",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "17",
        name: "KELANI FAOSSATOU GRACE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "18",
        name: "DOGBE MITRONOUGNAN KOASSI",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "19",
        name: "BINDI KOWADA ALBAN MAURICE.",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "20",
        name: "MESSENON YEMALIN ROMEO",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "21",
        name: "NWAIGBE MICHAEL",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "22",
        name: "EMEGBUE HYGINUS IFEANYICHUKWU",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "23",
        name: "ATCHETOUNWE ASSOGBA KOUESSI MAX -ROLAND",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "24",
        name: "KOUNOU MAXIMIN S. PATRICE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "25",
        name: "TONOUMI CLAUDE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "26",
        name: "HANDJEMEDJI BAMISSIN OLUWA ENOCK",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "27",
        name: "OMAKA IKECHUKWU JEHOSHAPHAT",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "28",
        name: "AZU AKANU JAMES",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "29",
        name: "GBAGUIDI SONDJO JACQUELINE LUCIE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "30",
        name: "ODOH ONYEMAECHI JUDE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "31",
        name: "OLAFA MEHINTON POLYXENA ADERONKE BENEDICTA",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "32",
        name: "BEAU KASARACHI LOIS",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "33",
        name: "EGWUATU ISRAEL EDWIN",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "34",
        name: "EKE UBA GIDEON",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "35",
        name: "ONYEMACHI COLLINS EMEKA",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "36",
        name: "NDUKWE PRECIOUS",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "37",
        name: "BIOKOU MAHOUGBE MICHEL",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "38",
        name: "JERRY PAUL NOBLE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "39",
        name: "CHUKWU BEYOND EMENIKE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "40",
        name: "GNANIH LEA AFFIAVI",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "41",
        name: "EBE JOSEPHINE NKISI",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "42",
        name: "OBETEN EKPOR SAMSON",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "43",
        name: "ONYENEKE NDIDI PRISCILLIA",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "44",
        name: "SOKE HONORINE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "45",
        name: "TOKOFAI KOMLAN DITOMA",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "46",
        name: "MBAKWE LIOUS CHINEDU",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "47",
        name: "DOHOU CAROLINE OLAKOULEGNY",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "48",
        name: "BILICHUKWU CHEMEZIE THEOPHILLIUS",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "49",
        name: "MISSONH ENYONAM ESSI ALPHONSINE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "50",
        name: "GNINOU ACHILLE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "51",
        name: "DEGBE FREDY DE DIEU",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "52",
        name: "SOSSOU A. ANNE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "53",
        name: "TOGBEDJI B. GLWASDYS",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "54",
        name: "KOTOR YAWO KUMA ELOM",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "55",
        name: "ADAKPAM K.MENSAH",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "56",
        name: "IGWAH C. EMMANUEL",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "57",
        name: "TEGNIKA EMMANUEL",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "58",
        name: "MAWUGNON SERGE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "59",
        name: "MONTIE MENGAH RAPHAËL",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "60",
        name: "OMONSSOFOU A.ELISEE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "61",
        name: "ADJOKANNON MADOCHE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "62",
        name: "ADOHOUANNON Jacques Martin",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "63",
        name: "ATCHADE A. JEAN MARIE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "64",
        name: "GNAHOUI TOUSSAINT",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "65",
        name: "AGBOGBO PATRICK",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "66",
        name: "MENSAH DORIS épouse FIAGBENOU",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "67",
        name: "TOUTOU PASCAL",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "68",
        name: "OJI JESSICA EBERE",
        verified: "false",
        img: "blankprofile",
    },
    
    {
        id: "69",
        name: "HOUENOU KOFFI ABEL",
        verified: "false",
        img: "blankprofile",
    },
    
]