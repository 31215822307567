import React from 'react'
// import logo from './logo.svg';
import '../../../css/overall.css';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import { MGB } from '../ministersNetwork/mgb'
import { words } from '../../../../trans'
import NavigationBar from '../../../navigationBar'
import ExtraInfo from '../../../extraInfo'
import { withRouter } from "react-router";
// import { useHistory } from "react-router-dom";

// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";



class SchoolPortal extends React.Component {

    constructor() {
        super();
        this.state = {
            onScreen: false,
            showInfo: false,
            showInfo_ext: false,
            slideIn: false,
            item: [],
            showNave: true,
            showMore: false,
            feild_1: "",
            feild_2: "",
            feild_3: "",
            feild_4: "",
            feild_5: "",
            feild_6: "",
            feild_7: "",
            feild_8: "",
            feild_9: "",
            feild_s: "",
            martalSta: "",
            RulesAgreed: false,
            password: "",
            retypePassword: "",
            regError: "",
            regLoading: false
        }
    }

    makeOnscreenTrue() {
        setTimeout(() => {
            this.setState({
                onScreen: true
            })
        }, 10);
        setTimeout(() => {
            this.setState({
                slideIn: true
            })
        }, 600);
    }

    showInfo(item) {
        const screenWidth = window.innerWidth
        if (screenWidth > 991) {
            this.setState({
                showInfo: true,
                showInfo_ext: true,
                item
            })
        }
    }

    hideInfo() {
        this.setState({
            showInfo_ext: false,
        })
        setTimeout(() => {
            this.setState({
                showInfo: false,
                item: []
            })
        }, 600);
    }

    componentDidMount() {
        // alert(JSON.stringify(this.props.location.state))
        // let id = this.props.match.params;
        // alert(JSON.stringify(id))
        this.makeOnscreenTrue();
        setTimeout(() => {
            this.showNave()
        }, 200);
    }

    register() {
        this.setState({
            regError: ""
        })
        fetch("https://www.imesbenin.com/server/createUser.php", { // URL
            body: JSON.stringify(this.state), // data you send.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // headers: {
            //   'content-type': 'application/json'
            // },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then((response) => response.json())
            .then(async (responseJson) => {
                // alert(responseJson);
                if (responseJson === "done") {
                    this.props.history.push("/finishSetingAccount");
                    const { userData } = this.props;
                    const data = {
                        user: this.state.feild_6
                    }
                    userData.updateUserData(data);
                    // alert(responseJson);
                } else if (responseJson === "email exists") {
                    this.setState({
                        regError: "Email already taken",
                        regLoading:false
                    })
                } else {
                    this.setState({
                        regError: "Failed, could be Server Problem",
                        regLoading:false
                    })
                }
            })
            .catch((error) => {
                // alert(error);
                this.setState({
                    regError: "Unable to register, Check your internet Conection and retry",
                    regLoading:false
                })
            });
    }

    startReg_pr() {
        this.setState({
            regError: "",
            regLoading:true
        })
        const { showMore, showNave, feild_8, feild_2, feild_1,
            feild_7, feild_6, feild_3, feild_4, feild_5,
            feild_9, martalSta, password, retypePassword
        } = this.state;
        setTimeout(() => {
            if (feild_8 && feild_2 && feild_1 && feild_7 && feild_6 && feild_3 && feild_4 && feild_5 && feild_9 && password && martalSta) {
                if (password === retypePassword) {
                    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (re.test(feild_6)) {
                        this.register();
                        this.setState({
                            regError: "",
                        })
                    }
                    else {
                        this.setState({
                            regError: "invalid email address,",
                            regLoading:false
                        })
                    }
                } else {
                    this.setState({
                        regError: "Passwords do not match",
                        regLoading:false
                    })
                }
            } else {
                this.setState({
                    regError: "Please fill all the input Fields",
                    regLoading:false
                })
            }
        }, 600);
    }

    showNave() {
        this.setState({
            showNave: !this.state.showNave
        })
    }

    showMore() {
        this.setState({
            showMore: !this.state.showMore
        })
    }

    // updatevalue = (e, i) => {
    //     const value = e.target.value
    //     const { form } = this.state;
    //     const newF = form;
    //     newF.feild_1 = e.target.value;
    //     this.setState({ form: newF })
    // }

    render() {
        const { showSpeech, showRegBox, showMIFBox, windowHeight, lang, user, userData } = this.props
        const { onScreen, showInfo, showInfo_ext, slideIn, RulesAgreed, regError, regLoading } = this.state
        const webHeight = window.innerWidth > 991 ? window.innerHeight - 60 : window.innerHeight - 0;
        const percent43 = webHeight * 43 / 100;
        const hotlineBoxHeight = percent43 * 60 / 100;
        const hotline_hotline_dpHeight = hotlineBoxHeight * 48 / 100
        const hotline_hotline_dp = {
            height: hotline_hotline_dpHeight,
            width: hotline_hotline_dpHeight
        }
        const hotline_hotline_dp_image = {
            fontSize: hotline_hotline_dpHeight - 15,
        }
        const col_md = onScreen === true ? "col-md-10" : "col-md-7"
        const shifAnim = showInfo_ext === true ? "fadeIn" : "fadeOut"
        const slideInLeft = slideIn === true ? "slideInLeft" : ""
        const item2 = this.state.item

        const { showMore, showNave, feild_8, feild_2, feild_1,
            feild_7, feild_6, feild_3, feild_4, feild_5,
            feild_9, martalSta, password, retypePassword, feild_s
        } = this.state;
        const icnClass = showNave === true ? "moreOptionsHolder_Icon_rot" : "";
        const icnMore = showMore === true ? "moreOptionsHolder_Icon_rot2" : "";
        const navHeight = showNave === true ? webHeight : 40;
        const navOpacity = showNave === true ? 1 : 0;
        const navBlocker = showNave === true ? "none" : "flex";
        const topMr = showMore === true ? 0 : webHeight;
        const topMore = window.innerWidth > 991 ? 0 : topMr;
        const topOpac = showMore === true ? 1 : 0;
        const topOpacity = window.innerWidth > 991 ? 1 : topOpac;
        const spouseCss = this.state.martalSta === "Legally married" ? "fadeIn" : "fadeOut";
        const spouseHeight = this.state.martalSta === "Legally married" ? 60 : 0;

        const trans = {
            home: lang === "en" ? words.home.en : words.home.fr,
            Sp: lang === "en" ? words.Sp.en : words.Sp.fr,
            MN: lang === "en" ? words.MN.en : words.MN.fr,
            about: lang === "en" ? words.about.en : words.about.fr,
            onlineCls: lang === "en" ? words.onlineCls.en : words.onlineCls.fr,
            gallery: lang === "en" ? words.gallery.en : words.gallery.fr,
            copyrite: lang === "en" ? words.copyrite.en : words.copyrite.fr,
            establisd_y: lang === "en" ? words.establisd_y.en : words.establisd_y.fr,
            talk_t_s: lang === "en" ? words.talk_t_s.en : words.talk_t_s.fr,
            you_can_now: lang === "en" ? words.you_can_now.en : words.you_can_now.fr,
            click_h_t: lang === "en" ? words.click_h_t.en : words.click_h_t.fr,
            get_i_t: lang === "en" ? words.get_i_t.en : words.get_i_t.fr,
            contact_u_share_y: lang === "en" ? words.contact_u_share_y.en : words.contact_u_share_y.fr,
            header: lang === "en" ? words.header.en : words.header.fr,
            fld_1: lang === "en" ? words.fld_1.en : words.fld_1.fr,
            fld_2: lang === "en" ? words.fld_2.en : words.fld_2.fr,
            fld_3: lang === "en" ? words.fld_3.en : words.fld_3.fr,
            fld_4: lang === "en" ? words.fld_4.en : words.fld_4.fr,
            fld_5: lang === "en" ? words.fld_5.en : words.fld_5.fr,
            fld_6: lang === "en" ? words.fld_6.en : words.fld_6.fr,
            fld_6_: lang === "en" ? words.fld_6_.en : words.fld_6_.fr,
            fld_7: lang === "en" ? words.fld_7.en : words.fld_7.fr,
            fld_8: lang === "en" ? words.fld_8.en : words.fld_8.fr,
            fld_9: lang === "en" ? words.fld_9.en : words.fld_9.fr,
            fld_10: lang === "en" ? words.fld_10.en : words.fld_10.fr,
            fld_11: lang === "en" ? words.fld_11.en : words.fld_11.fr,
            fld_check_box_1: lang === "en" ? words.fld_check_box_1.en : words.fld_check_box_1.fr,
            fld_check_box_1_sub: lang === "en" ? words.fld_check_box_1_sub.en : words.fld_check_box_1_sub.fr,
            fld_check_box_2: lang === "en" ? words.fld_check_box_2.en : words.fld_check_box_2.fr,
            fld_check_box_3: lang === "en" ? words.fld_check_box_3.en : words.fld_check_box_3.fr,
            fld_check_box_4: lang === "en" ? words.fld_check_box_4.en : words.fld_check_box_4.fr,
            fld_check_box_5: lang === "en" ? words.fld_check_box_5.en : words.fld_check_box_5.fr,
            fld_check_box_6: lang === "en" ? words.fld_check_box_6.en : words.fld_check_box_6.fr,
            rules_a_r_o_t_sch: lang === "en" ? words.rules_a_r_o_t_sch.en : words.rules_a_r_o_t_sch.fr,
            Preamble: lang === "en" ? words.Preamble.en : words.Preamble.fr,
            R_pgph_1: lang === "en" ? words.R_pgph_1.en : words.R_pgph_1.fr,
            R_pgph_2: lang === "en" ? words.R_pgph_2.en : words.R_pgph_2.fr,
            imes_will_help_you: lang === "en" ? words.imes_will_help_you.en : words.imes_will_help_you.fr,
            Item_1: lang === "en" ? words.Item_1.en : words.Item_1.fr,
            Item_1_p1: lang === "en" ? words.Item_1_p1.en : words.Item_1_p1.fr,
            Item_1_p2: lang === "en" ? words.Item_1_p2.en : words.Item_1_p2.fr,
            Item_1_a: lang === "en" ? words.Item_1_a.en : words.Item_1_a.fr,
            Item_1_b: lang === "en" ? words.Item_1_b.en : words.Item_1_b.fr,
            Item_1_c: lang === "en" ? words.Item_1_c.en : words.Item_1_c.fr,
            Item_2: lang === "en" ? words.Item_2.en : words.Item_2.fr,
            Item_2_p1: lang === "en" ? words.Item_2_p1.en : words.Item_2_p1.fr,
            Item_2_a: lang === "en" ? words.Item_2_a.en : words.Item_2_a.fr,
            Item_2_b: lang === "en" ? words.Item_2_b.en : words.Item_2_b.fr,
            Item_3: lang === "en" ? words.Item_3.en : words.Item_3.fr,
            Item_3_p1: lang === "en" ? words.Item_3_p1.en : words.Item_3_p1.fr,
            Item_4: lang === "en" ? words.Item_4.en : words.Item_4.fr,
            Item_4_p1: lang === "en" ? words.Item_4_p1.en : words.Item_4_p1.fr,
            Item_5: lang === "en" ? words.Item_5.en : words.Item_5.fr,
            Item_5_p1: lang === "en" ? words.Item_5_p1.en : words.Item_5_p1.fr,
            Item_6: lang === "en" ? words.Item_6.en : words.Item_6.fr,
            Item_6_p1: lang === "en" ? words.Item_6_p1.en : words.Item_6_p1.fr,
            Item_6_p2: lang === "en" ? words.Item_6_p2.en : words.Item_6_p2.fr,
            Item_6_p3: lang === "en" ? words.Item_6_p3.en : words.Item_6_p3.fr,
            Commitmen: lang === "en" ? words.Commitmen.en : words.Commitmen.fr,
            Commitmen_p: lang === "en" ? words.Commitmen_p.en : words.Commitmen_p.fr,
            Commitmen_checkbox: lang === "en" ? words.Commitmen_checkbox.en : words.Commitmen_checkbox.fr,
            you_have_to_agree: lang === "en" ? words.you_have_to_agree.en : words.you_have_to_agree.fr,
            agree_to_the_rules: lang === "en" ? words.agree_to_the_rules.en : words.agree_to_the_rules.fr,
            register: lang === "en" ? words.register.en : words.register.fr,
        }

        return (
            <>
                <div class="extra_l cool_bg"></div>
                <div class="extra_l"></div>

                <div class="row body-row">
                    <div class="col-md-12 newPageCorrector">

                        <NavigationBar
                            Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
                            active={{ a: "", b: "active", c: "", d: "", e: "" }}
                            clas="col-md-2 navigation-bar"
                            style={{ height: webHeight }}
                            user={user} userData={userData}
                        />

                        <div className={"child-body ptls animeTransition " + col_md + " responsiveScroll"} style={{ height: webHeight, alignItems: "center", }}>

                            <div class="lightGlass_ptls"></div>

                            <NavigationBar
                                Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
                                active={{ a: "", b: "active", c: "", d: "", e: "" }}
                                clas="col-md-2 navigation-bar-iPhone"
                                style={{ height: navHeight, opacity: navOpacity }}
                                user={user} userData={userData}
                            />

                            <div className={"col-md-6 animated regBox2 slideInUp fadeIn"}>
                                <h4 style={{ color: "black" }}>{trans.rules_a_r_o_t_sch}</h4>
                                <h4 style={{ color: "black", textTransform: "capitalize" }}>{trans.Preamble}</h4>
                                <p>{trans.R_pgph_1}</p>
                                <p>{trans.R_pgph_2} {trans.imes_will_help_you}</p>

                                <p style={{ opacity: 0 }}>.</p>

                                <h5 style={{ color: "black", textTransform: "capitalize" }}>{trans.Item_1}</h5>
                                <p>{trans.Item_1_p1}</p>
                                <p>{trans.Item_1_p2}</p>
                                <ul>
                                    <li>{trans.Item_1_a}</li>
                                    <li>{trans.Item_1_b}</li>
                                    <li>{trans.Item_1_c}</li>
                                </ul>

                                <p style={{ opacity: 0 }}>.</p>

                                <h5 style={{ color: "black", textTransform: "capitalize" }}>{trans.Item_2}</h5>
                                <p>{trans.Item_2_p1}</p>
                                <ul>
                                    <li style={{ color: "red" }}>{trans.Item_2_a}</li>
                                    <li style={{ color: "red" }}>{trans.Item_2_b}</li>
                                </ul>

                                <h5 style={{ color: "black", textTransform: "capitalize" }}>{trans.Item_3}</h5>
                                <p>{trans.Item_3_p1}</p>

                                <h5 style={{ color: "black", textTransform: "capitalize" }}>{trans.Item_4}</h5>
                                <p>{trans.Item_4_p1}</p>

                                <h5 style={{ color: "black", textTransform: "capitalize" }}>{trans.Item_5}</h5>
                                <p>{trans.Item_5_p1}</p>

                                <h5 style={{ color: "black", textTransform: "capitalize" }}>{trans.Item_6}</h5>
                                <p>{trans.Item_6_p1}</p>
                                <p>{trans.Item_6_p2}</p>
                                <p>{trans.Item_6_p3}</p>

                                <p style={{ opacity: 0 }}>.</p>
                                <div style={{ display: "flex", marginBottom: 20 }}>
                                    <input class="radio_" type="checkbox" id="e" name="fav_language" value="Divorced / Widowed" onChange={() => { this.setState({ RulesAgreed: !this.state.RulesAgreed }) }} />
                                    <label style={{ fontSize: "smaller" }} for="javascript">&nbsp; {trans.Commitmen_checkbox}</label> <br />
                                </div>

                            </div>

                            <div className={"col-md-6 animated regBox fadeIn"}>
                                <div className={"col-md-12 animated regBox_ fadeIn"}>
                                    <h4>{trans.header}</h4>
                                    <div class="log_in_box_holder">
                                        <p class="log_in_box_text">{trans.fld_1}</p>
                                        <input class="log_in_box_input" type="text" onChange={(e) => { this.setState({ feild_1: e.target.value }) }} value={feild_1}></input>
                                        <p class="log_in_box_text" style={{ paddingLeft: 5 }}>{trans.fld_2}</p>
                                        <input class="log_in_box_input" type="text" onChange={(e) => { this.setState({ feild_2: e.target.value }) }} value={feild_2}></input>
                                    </div>
                                    <p>{trans.fld_3}</p>
                                    <input class="log_in_box_input" type="date" onChange={(e) => { this.setState({ feild_3: e.target.value }) }} value={feild_3}></input>
                                    <p style={{ opacity: 0 }}>.</p>
                                    <p>{trans.fld_4}</p>
                                    <input class="log_in_box_input" type="text" onChange={(e) => { this.setState({ feild_4: e.target.value }) }} value={feild_4}></input>
                                    <p>{trans.fld_5}</p>
                                    <input class="log_in_box_input" type="text" onChange={(e) => { this.setState({ feild_5: e.target.value }) }} value={feild_5}></input>

                                    <p>{trans.fld_7}</p>
                                    <input class="log_in_box_input" type="text" onChange={(e) => { this.setState({ feild_7: e.target.value }) }} value={feild_7}></input>
                                    <div class="log_in_box_holder">
                                        <div>
                                            <p style={{ fontSize: 10 }}>{trans.fld_8}</p>
                                            <input class="log_in_box_input" type="text" onChange={(e) => { this.setState({ feild_8: e.target.value }) }} value={feild_8}></input>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: 10, paddingLeft: 5 }}>{trans.fld_9}</p>
                                            <input class="log_in_box_input" type="text" onChange={(e) => { this.setState({ feild_9: e.target.value }) }} value={feild_9}></input>
                                        </div>
                                    </div>
                                    <p style={{ opacity: 0 }}>.</p>
                                    <p style={{ opacity: 0 }}>.</p>
                                    <p style={{ opacity: 0.9 }}>{trans.fld_10}</p>
                                    <form>
                                        <br />
                                        <input class="radio_" type="radio" id="a" name="fav_language" value="Legally married" onChange={(e) => { this.setState({ martalSta: e.target.value }) }} />
                                        <label for="html">&nbsp; {trans.fld_check_box_1}</label> <br />
                                        <div class={"animated " + spouseCss} style={{ transition: "0.6s", height: spouseHeight, overflow: "hidden" }}>
                                            <p>{trans.fld_check_box_1_sub}</p>
                                            <input class="log_in_box_input" type="text" onChange={(e) => { this.setState({ feild_s: e.target.value }) }} value={feild_s}></input>
                                        </div>
                                        <input class="radio_" type="radio" id="b" name="fav_language" value="Concubinage" onChange={(e) => { this.setState({ martalSta: e.target.value }) }} />
                                        <label for="css">&nbsp; {trans.fld_check_box_2}</label> <br />
                                        <input class="radio_" type="radio" id="c" name="fav_language" value="Single with no children" onChange={(e) => { this.setState({ martalSta: e.target.value }) }} />
                                        <label for="javascript">&nbsp; {trans.fld_check_box_3}</label> <br />
                                        <input class="radio_" type="radio" id="d" name="fav_language" value="Single with children" onChange={(e) => { this.setState({ martalSta: e.target.value }) }} />
                                        <label for="javascript">&nbsp; {trans.fld_check_box_4}</label> <br />
                                        <input class="radio_" type="radio" id="e" name="fav_language" value="Divorced / Widowed" onChange={(e) => { this.setState({ martalSta: e.target.value }) }} />
                                        <label for="javascript">&nbsp; {trans.fld_check_box_5}</label> <br />
                                    </form>
                                    <p style={{ opacity: 0 }}>.</p>
                                    <p>{trans.fld_6}</p>
                                    <p style={{ fontSize: 10, }}>{trans.fld_6_}</p>
                                    <input class="log_in_box_input" type="email" onChange={(e) => { this.setState({ feild_6: e.target.value }) }} value={feild_6}></input>
                                    <p>Password</p>
                                    <input class="log_in_box_input" type="password" onChange={(e) => { this.setState({ password: e.target.value }) }} value={password}></input>
                                    <p>Retype Password</p>
                                    <input class="log_in_box_input" type="password" onChange={(e) => { this.setState({ retypePassword: e.target.value }) }} value={retypePassword}></input>
                                    <p style={{ opacity: 0 }}>.</p>
                                    <p style={{ opacity: 0 }}>.</p>
                                    
                                    <div class="a reg_a" onClick={() => { this.startReg_pr() }} style={{ cursor: "pointer" }}>
                                    {
                                        regLoading === true ?
                                        <i class="fa fa-circle-o-notch " style={{ animation: `spin ${1}s linear infinite` }}></i>
                                        :
                                        <p style={{ color: "white"}}>{trans.register}</p>
                                    }
                                    </div>

                                    <p style={{ color: "red", fontSize: 14, marginTop: 10 }}>{regError}</p>
                                    <div class="LGN__" style={{paddingTop: 15}}>
                                        <p style={{fontSize: 12, marginRight: 5}}>Already have an account? </p>
                                        <Link to="/login" class={"LGN__-item "} style={{fontSize: 12}}>LOG IN</Link>
                                    </div>
                                    <p style={{fontSize: 8, color: "rgba(0,0,0,0.6)", marginTop:5}}>
                                        This Registration Form would be emailed to you once you complete your Registration, and Finalise your payment.
                                        Therefore ensure that all the informations provided are accurate most especially your email address, as it would beforeour means of interraction and
                                        for your personal validations and future configurations.
                                    </p>
                                </div>
                                {/* <input class="radio_" type="radio" id="f" name="fav_language"value="JavaScript" onChange={(e) => { this.setState({ martalSta: e.target.value }) } }/>
                                <label for="javascript">&nbsp; {trans.fld_check_box_6}</label> */}

                                {
                                    RulesAgreed === false ?
                                        <div class="col-md-12 formBlocker">
                                            <h6>{trans.agree_to_the_rules}</h6>
                                            <p>{trans.you_have_to_agree}</p>
                                        </div>
                                        :
                                        null
                                }
                            </div>


                        </div>



                    </div>
                </div>

                <ExtraInfo
                    Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
                    clas="col-md-3 extra-info"
                    style={{ height: webHeight, top: topMore, opacity: topOpacity, width: "100%" }}
                />


                <div class="moreOptionsHolder">
                    <div class={"moreOptionsHolder_Icon " + icnClass + " andPlus"} onClick={() => { this.showNave() }}></div>
                    <p>|</p>
                    <div class={"moreOptionsHolder_Icon " + icnMore + " andMenu"} onClick={() => { this.showMore() }}></div>
                </div>

                {/* {showInfo === true ?
                    <div className={"showInfo animated " + shifAnim} onClick={() => { this.hideInfo() }}>
                        <div class="mgbCardsIMG_holder2">
                            <div class="mgbCardsIMG2 cool_bg"></div>
                        </div>
                        <div class="writeup_holder2">
                            <h3>{item2.name}</h3>
                            <p>{item2.role}</p>
                            <p class="mgb_info">{item2.about}</p>
                            <br />
                            {item2.weblink ?
                                <><a className="weblink" href={item2.weblink}>website : {item2.weblink}</a></>
                                :
                                null
                            }
                            {item2.fcblink ?
                                <><a className="fcblink" href={item2.fcblink}>facebook : {item2.fcblink}</a></>
                                :
                                null
                            }
                            {item2.fcbpage ?
                                <><a className="fcbpage" href={item2.fcbpage}>fcb page : {item2.fcbpage}</a></>
                                :
                                null
                            }
                        </div>
                    </div>
                    :
                    null
                } */}
            </>
        );
    }

}

export default withRouter(windowSize(SchoolPortal));