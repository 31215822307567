import React, { useState, useEffect } from 'react'
// import logo from './logo.svg';
import '../../css/overall.css';
import './styles.css';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
// import { MGB } from '../ministersNetwork/mgb'
import { words } from '../../../trans'
import { withRouter } from "react-router";
import { FedaCheckoutButton, FedaCheckoutContainer } from 'fedapay-reactjs';
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";



class AddUser extends React.Component {


    constructor() {
        super();
        this.state = {
            showPassView: true,
            showAddUser: false,
            showRemoveUser: false,
            showUploadCourse: false,
        }
    }

    setShowPassView = (value) => {
        this.setState({ showPassView: value })
    }
    setShowAddUser = (value) => {
        this.setState({ showAddUser: value })
    }
    setShowRemoveUser = (value) => {
        this.setState({ showRemoveUser: value })
    }
    setShowUploadCourse = (value) => {
        this.setState({ showUploadCourse: value })
    }

    render() {
        const { showSpeech, showRegBox, showMIFBox, windowHeight, lang, user, userData } = this.props;
        const { showPassView, showAddUser, showRemoveUser, showUploadCourse } = this.state;

        const trans = {
            home: lang === "en" ? words.home.en : words.home.fr,
        }

        return (
            <>
                <div className='settings_container'>
                    <div className='container-row'>
                        <div className='imgBG'></div>
                        <div className='imgBG_gradient'></div>
                        <div className='col-md-6'></div>
                        <div className='col-md-6 withButtons'>
                            <div className='buttonsContainer'>
                                <h1>Workspace</h1>
                                <h2>Control the back-end of the mobile app system</h2>
                                <div className='quickActionsContainer'>
                                    <p>Quick Actions </p>
                                    <div className='quickActionsContainer_'>
                                        <div className='quickActions scaleOnHover clickIn' onClick={() => { this.setShowAddUser(true) }}>
                                            <AddUserIcon />
                                            <p>Add Student</p>
                                        </div>
                                        <div className='quickActions scaleOnHover clickIn' onClick={() => { this.setShowRemoveUser(true) }}>
                                            <RemoveUserIcon />
                                            <p>Remove Student</p>
                                        </div>
                                        <div className='quickActions scaleOnHover clickIn' onClick={() => { this.setShowUploadCourse(true) }}>
                                            <UploadCourses />
                                            <p>Upload Courses</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='quickActionsContainer'>
                                    <SpiralChart />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showPassView && <SecurePage setShowPassView={this.setShowPassView} />}
                {showAddUser && <AddUserComponent setShowAddUser={this.setShowAddUser} />}
                {showRemoveUser && <RemoveUserComponent setShowRemoveUser={this.setShowRemoveUser} />}
                {showUploadCourse && <UploadCourseComponent setShowUploadCourse={this.setShowUploadCourse} />}
            </>
        );
    }

}



const SecurePage_ = (value) => {
    const [feild_1, setfeild_1] = useState("");
    const [showBG, setShowBG] = useState(true);
    const [showCOM, setShowCOM] = useState(true);
    const BgClass = showBG ? "fadeIn" : "fadeOut";
    const ComClass = showCOM ? "bounceIn" : "bounceOut";

    const closeWindow = () => {
        setShowBG(false);
        setShowCOM(false);
        setTimeout(() => {
            value.setShowPassView(false)
        }, 500);
    }
    return (
        <div className={'blocker_container ' + BgClass}>
            <div className={'box ' + ComClass}>
                <h1 className='bounceIn'>MOTS DE PASS</h1>
                <input
                    class="log_in_box_input real_login"
                    type="password" onChange={(e) => { setfeild_1(e.target.value) }} placeholder='tapez le mots de pass ici'
                    value={feild_1}></input>
                    <div className='button scaleOnHover clickIn' onClick={() => {closeWindow()}}>
                        <p>Continue</p>
                    </div>
            </div>
        </div>
    )
}


const SpiralChart = () => {
    const yearsData = [
        { label: 'First Year', value: 12 },
        { label: 'Second Year', value: 4 },
        { label: 'Third Year', value: 18 },
        { label: 'Cours de Cadre', value: 7 },
    ];
    const [students, setStudents] = useState([]);

    const getStudents = () => {
        const data = { view: "none" }
        fetch("https://www.imesbenin.com/directory/stats/getUsers.php", {
            body: JSON.stringify({ data }),
            cache: 'no-cache',
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST',
            mode: 'cors',
            redirect: 'follow',
            referrer: 'no-referrer',
        })
            .then((response) => response.json())
            .then(async (responseJson) => {
                // alert(JSON.stringify(responseJson))
                setStudents(responseJson);
            })
            .catch((error) => {
                // giveFeedBack(false, "Unable to register, Check your internet Conection and retry");
            });
    }

    useEffect(() => {
        getStudents();
    }, [])

    const calculateNoOfStudents = (level) => {
        const val = students;
        // const val = [{ "idd": "1", "id": "d", "first_name": "d", "last_name": null, "email": "d", "password": "s", "imgUri": "", "level": "2" }, { "idd": "2", "id": "sd", "first_name": "d", "last_name": null, "email": "sd", "password": "d", "imgUri": "", "level": "1" }]
        if (students.length >= 1) {
            if (level === "First Year") {
                return val.filter(value => value.level === "1");
            } else if (level === "Second Year") {
                return val.filter(value => value.level === "2");
            } else if (level === "Third Year") {
                return val.filter(value => value.level === "3");
            } else {
                return val.filter(value => value.level === "c");
            }
        } else {
            return [];
        }
    }

    const calculateSpiralPoints = () => {
        // Calculate points for the spiral based on the data
        // This is a very basic example; you might want to adjust this logic for better representation
        const points = [];
        let angle = 0;
        let radius = 10;

        yearsData.forEach((year, index) => {
            const x = radius * Math.cos(angle);
            const y = radius * Math.sin(angle);
            points.push({ x, y, label: year.label, value: year.value });

            angle += Math.PI / 4; // Adjust the angle for spacing
            radius += 10; // Adjust the radius for spacing
        });

        return points;
    };

    return (
        <div>
            <p style={{ fontWeight: "bold" }}>Stats of Students </p>
            <div className='spiralStateContainer'>
                <div className='statsContainer'>
                    {yearsData.map((value) => {
                        return (
                            <div className='stats'>
                                <div className={"statsBubble " + value.label}>
                                    <p>{calculateNoOfStudents(value.label).length}</p>
                                </div>
                                <p>{value.label}</p>
                            </div>
                        )
                    })}
                    <p>{students.length} total number of student{students.length !== 1 && "s"}</p>
                </div>
            </div>
        </div>
    );
};

const AddUserIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -64 640 640" width="1em" height="1em" fill="currentColor">
            <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"></path>
        </svg>
    )
}
const RemoveUserIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -64 640 640" width="1em" height="1em" fill="currentColor">
            <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7H514.3c3.9 0 7.6-.7 11-2.1l-261-205.6z"></path>
        </svg>
    )
}
const UploadCourses = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" fill="currentColor">
            <path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"></path>
        </svg>
    )
}
const CheckedIcon = (tintColor) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" fill="green">
            <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path>
        </svg>
    )
}
const InfoIcon = (tintColor) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" fill="brown">
            <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"></path>
        </svg>
    )
}





const SecurePage = (value) => {
    const pass = "0000";
    // const pass = "valueImes#10@2024";
    const [feild_1, setfeild_1] = useState("");
    const [showBG, setShowBG] = useState(true);
    const [showCOM, setShowCOM] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [failed, setFailed] = useState(false);
    const BgClass = showBG ? "animation fadeIn" : "animation fadeOut";
    const ComClass = showCOM ? "animation bounceIn" : "animation bounceOut";
    const showPassClass = showPassword ? "showPass_focus" : "showPass_blur"
    const feildFailedClass = failed ? "animation shake" : "animation pulse";

    const closeWindow = () => {
        setShowBG(false);
        setShowCOM(false);
        setTimeout(() => {
            value.setShowPassView(false)
        }, 500);
    }

    const checkPass = () => {
        if (pass === feild_1) {
            animateSuccess();
        } else {
            animateFailure();
        }
    }

    const animateSuccess = () => {
        setTimeout(() => {
            closeWindow()
        }, 200);
    }
    const animateFailure = () => {
        setFailed(true);
        setTimeout(() => {
            setFailed(false);
        }, 600);
    }
    return (
        <div className={'blocker_container ' + BgClass}>
            <div className={'box ' + ComClass}>
                <h1 className='bounceIn'>Entrer le code secret</h1>
                <div className='sub_logoContainer'>
                    <div className='logo_holder'>
                        <div class="logo logo_"></div>
                    </div>
                    <div>
                        <p className='blocker_h1'>IMES BENIN</p>
                        <p className='blocker_h6'>Ce mot de passe est unique et ne peut être modifié par aucun individu</p>
                    </div>
                </div>
                <input
                    class={"log_in_box_input real_login " + feildFailedClass}
                    type={showPassword ? "text" : "password"} onChange={(e) => { setfeild_1(e.target.value) }} placeholder='tapez le mots de pass ici'
                    value={feild_1}></input>
                <div className='button_holder'>
                    <div className='button scaleOnHover clickIn' onClick={() => { checkPass() }}>
                        <p>Continue</p>
                    </div>
                    <div className={'showPass_ scaleOnHover clickIn ' + showPassClass} onClick={() => { setShowPassword(!showPassword) }}></div>
                </div>
                <p className='blocker_p'>Si vous êtes un membre du personnel ou un collaborateur et avez oublié ce code, nous vous suggérons de contacter le développeur.</p>
            </div>
        </div>
    )
}

const AddUserComponent = (value) => {
    const screenWidth = window.innerWidth * 0.9
    const [feild_1, setfeild_1] = useState("");
    const [feild_2, setfeild_2] = useState("");
    const [feild_3, setfeild_3] = useState("");
    const [feild_4, setfeild_4] = useState("");
    const [feild_5, setfeild_5] = useState("");
    const [feild_6, setfeild_6] = useState("");
    const [feild_7, setfeild_7] = useState("");
    const [feedBackClass, setFeedBackClass] = useState("");
    const [feedBack, setFeedBack] = useState("");
    const [feedbackType, setFeedbackType] = useState("");
    const [showBG, setShowBG] = useState(true);
    const [showCOM, setShowCOM] = useState(true);
    const [translateX, setTranslateX] = useState(0);
    const BgClass = showBG ? "animation fadeIn" : "animation fadeOut";
    const ComClass = showCOM ? "animation duration300 fadeInUp" : "animation fadeOutDown";

    const closeWindow = () => {
        setShowBG(false);
        setShowCOM(false);
        setTimeout(() => {
            value.setShowAddUser(false)
        }, 500);
    }
    const goBack = (index) => {
        const translayeValue = screenWidth * index;
        setTranslateX(translateX + screenWidth);
    }
    const goNext = (index) => {
        const translayeValue = screenWidth * index;
        setTranslateX(translateX - screenWidth);
    }
    const addUsertoDB = () => {
        const data = {
            first_name: feild_1,
            last_name_: feild_2,
            email: feild_3,
            password: feild_5,
            level: feild_7,
        }
        fetch("https://www.imesbenin.com/directory/stats/addUser.php", {
            body: JSON.stringify(data),
            cache: 'no-cache',
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST',
            mode: 'cors',
            redirect: 'follow',
            referrer: 'no-referrer',
        })
            .then((response) => response.json())
            .then(async (responseJson) => {
                if (responseJson === "done") {
                    giveFeedBack(true);
                } else if (responseJson === "email exists") {
                    giveFeedBack(false, "Email address already taken");
                } else {
                    giveFeedBack(false, responseJson);
                }
            })
            .catch((error) => {
                giveFeedBack(false, "Unable to register, Check your internet Conection and retry");
            });
    }
    const giveFeedBack = (positiveFeedback, message) => {
        const statement = message ? message : positiveFeedback === true ? "Student added sucessfully" : "Adding Student was not successfull"
        const fdbType = positiveFeedback === true ? "positive" : "negative"
        setShowCOM(false);
        setFeedbackType(fdbType);
        setTimeout(() => {
            setFeedBack(statement);
            setFeedBackClass("bounceIn");
            setTimeout(() => {
                setFeedBackClass("bounceOut");
                positiveFeedback === true && closeWindow();
                setTimeout(() => {
                    setFeedBack("");
                    setFeedbackType("");
                    setShowCOM(true);
                }, 500);
            }, 2000);
        }, 200);
    }

    return (
        <div className={'AddUserComponent_container ' + BgClass}>
            {!feedBack && <div className={'AddUserComponent ' + ComClass}>
                <div className='Screens_AU_container' style={{ transform: `translateX(${translateX}px)` }}>
                    {
                        screens_AU.map((data, index) => {
                            return (
                                <Screens_AU
                                    data={data}
                                    index={index}
                                    closeWindow={closeWindow}
                                    goBack={goBack}
                                    goNext={goNext}
                                    feild_1={feild_1} setfeild_1={setfeild_1}
                                    feild_2={feild_2} setfeild_2={setfeild_2}
                                    feild_3={feild_3} setfeild_3={setfeild_3}
                                    feild_4={feild_4} setfeild_4={setfeild_4}
                                    feild_5={feild_5} setfeild_5={setfeild_5}
                                    feild_6={feild_6} setfeild_6={setfeild_6}
                                    feild_7={feild_7} setfeild_7={setfeild_7}
                                    addUsertoDB={addUsertoDB}
                                />
                            )
                        })
                    }
                </div>
            </div>
            }
            {feedBack && <div className={'AddUserComponent animation ' + feedBackClass}>
                <div className="feedBackClass">
                    {feedbackType === "positive" ?
                        <CheckedIcon />
                        :
                        <InfoIcon />
                    }
                    <p>{feedBack}</p>
                </div>
            </div>
            }
        </div>
    )
}

const Screens_AU = (value) => {
    const {
        data, closeWindow, index, goBack, goNext,
        feild_1, setfeild_1, feild_2, setfeild_2, feild_3, setfeild_3,
        feild_4, setfeild_4, feild_5, setfeild_5, feild_6, setfeild_6,
        feild_7, setfeild_7, addUsertoDB,
    } = value;
    const [error, setError] = useState("");

    const negativeNav = () => {
        if (index === 0) {
            closeWindow();
        } else {
            goBack(index);
        }
    }

    const positiveNav = () => {
        if (index === (screens_AU.length - 1)) {
            amp_feild_check2();
        } else {
            if (index === 1) {
                amp_feild_check1()
            } else {
                goNext(index);
            }
        }
    }

    const amp_feild_check1 = () => {
        if (feild_1 && feild_2 && feild_3) {
            if (feild_3 === feild_4) {
                goNext(index);
            } else {
                setError("The Emails provided does't correspond ");
                setTimeout(() => {
                    setError("");
                }, 5000);
            }
        } else {
            setError("Please make sure you fill all the fields");
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }

    const amp_feild_check2 = () => {
        if (feild_7) {
            if (feild_5 === feild_6) {
                addUsertoDB(index);
            } else {
                setError("Passwords does't match ");
                setTimeout(() => {
                    setError("");
                }, 5000);
            }
        } else {
            setError("Please make sure you select the students level");
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }


    return (
        <div className={"AddUser AddUser_" + data.screen}>

            {data.screen === "screen3" && <div className='imageBG_AU_'></div>}

            <div className={'Screens_AU Screens_AU_' + data.screen}>
                <div className={'S_AU_head S_AU_head_' + data.screen}>
                    {data.screen === "intro" && <div className='introProfilePic'>
                        <div className='introProfilePic_'></div></div>}
                    <div>
                        {data.title && <h1 className='animation delay100 fadeInUp'>{data.title}</h1>}
                        {data.subTitle && <p className='h4 animation delay150 fadeInUp'>{data.subTitle}</p>}
                    </div>
                </div>
                {error && <p
                    style={{ fontWeight: 'bold', fontSize: 12, color: "red", paddingTop: 10, textAlign: "left", width: "100%" }}
                    className="animation shake"
                >
                    {error}
                </p>}
                {data.text && <p className='p animation delay200 fadeInUp'>{data.text}</p>}

                {data.confirmEmail && <>
                    <div className={'feildContainer'}>
                        <p>{data.feild1}</p>
                        <input
                            class={"feildContainer_input "}
                            type={"text"} onChange={(e) => { setfeild_1(e.target.value) }} placeholder={"write " + data.feild1 + " here"}
                            value={feild_1}></input>

                        <p>{data.feild2}</p>
                        <input
                            class={"feildContainer_input "}
                            type={"text"} onChange={(e) => { setfeild_2(e.target.value) }} placeholder={"write " + data.feild2 + " here"}
                            value={feild_2}></input>
                    </div>
                    <div className={'feildContainer'}>
                        <p style={{ paddingTop: 20, paddingBottom: 0, fontWeight: "bold" }}>Next, your email address</p>
                        <p>{data.feild3}</p>
                        <input
                            class={"feildContainer_input "}
                            type="email" onChange={(e) => { setfeild_3(e.target.value) }} placeholder={data.feild3 + " here"}
                            value={feild_3}></input>

                        <p>{data.feild4}</p>
                        <input
                            class={"feildContainer_input "}
                            type={"text"} onChange={(e) => { setfeild_4(e.target.value) }} placeholder={data.feild4 + " here"}
                            value={feild_4}></input>
                    </div>
                    <p style={{ color: "black", fontSize: 11, paddingTop: 10 }}>The email address provided above would be used to log the user into the app</p>
                </>}

                {data.confirmPassCode && <>
                    <div className={'feildContainer'}>
                        <p>{data.feild1}</p>
                        <input
                            class={"feildContainer_input "}
                            type="password" onChange={(e) => { setfeild_5(e.target.value) }} placeholder={"write " + data.feild1 + " here"}
                            value={feild_5}></input>

                        <p>{data.feild2}</p>
                        <input
                            class={"feildContainer_input "}
                            type="password" onChange={(e) => { setfeild_6(e.target.value) }} placeholder={"write " + data.feild2 + " here"}
                            value={feild_6}></input>
                    </div>
                    <form className='AU_radio_btns'>
                        <p>Select students level:</p>
                        <input class="radio_" type="radio" id="b" name="fav_language" value="1" onChange={(e) => { setfeild_7(e.target.value) }} />
                        <label for="css">&nbsp; First Year</label> <br />
                        <input class="radio_" type="radio" id="c" name="fav_language" value="2" onChange={(e) => { setfeild_7(e.target.value) }} />
                        <label for="javascript">&nbsp; Second Year</label> <br />
                        <input class="radio_" type="radio" id="d" name="fav_language" value="3" onChange={(e) => { setfeild_7(e.target.value) }} />
                        <label for="javascript">&nbsp; Third Year</label> <br />
                        <input class="radio_" type="radio" id="e" name="fav_language" value="c" onChange={(e) => { setfeild_7(e.target.value) }} />
                        <label for="javascript">&nbsp; Cours de Cadres</label> <br />
                    </form>
                </>}

            </div>

            {data.screen === "screen2" && <div className='imageBG_AU'></div>}
            <div className='screens_navigation'>
                {data.prevButton && <div onClick={() => { negativeNav() }} className='navButton negativeNav scaleOnHover clickIn'>
                    <p>{data.prevButton}</p>
                </div>}
                {data.nextButton && <div onClick={() => { positiveNav() }} className='navButton positiveNav scaleOnHover clickIn'>
                    <p>{data.nextButton}</p>
                </div>}
            </div>
        </div>
    )
}

const RemoveUserComponent = (value) => {
    const [feild_1, setfeild_1] = useState("");
    const [showBG, setShowBG] = useState(true);
    const [showCOM, setShowCOM] = useState(true);
    const BgClass = showBG ? "fadeIn" : "fadeOut";
    const ComClass = showCOM ? "bounceIn" : "bounceOut";

    const closeWindow = () => {
        setShowBG(false);
        setShowCOM(false);
        setTimeout(() => {
            value.setShowPassView(false)
        }, 500);
    }
    return (
        <div className={'blocker_container ' + BgClass}>
            <div className={'box ' + ComClass}>
            </div>
        </div>
    )
}

const UploadCourseComponent = (value) => {
    const [feild_1, setfeild_1] = useState("");
    const [showBG, setShowBG] = useState(true);
    const [showCOM, setShowCOM] = useState(true);
    const BgClass = showBG ? "fadeIn" : "fadeOut";
    const ComClass = showCOM ? "bounceIn" : "bounceOut";

    const closeWindow = () => {
        setShowBG(false);
        setShowCOM(false);
        setTimeout(() => {
            value.setShowPassView(false)
        }, 500);
    }
    return (
        <div className={'blocker_container ' + BgClass}>
            <div className={'box ' + ComClass}>
            </div>
        </div>
    )
}

const screens_AU = [
    {
        screen: "intro",
        title: "Add A Student",
        subTitle: "Create a table for a student, which would give him access to courses and other school mterials",
        text: `Please note that you should have the correct and accurate information of this student who is about to
         be added, after this step corrections may proove to be futile or delayed except you are granted special access 
         to more admin priviledges by the developer`,
        prevButton: "cancel",
        nextButton: "continue",
    },
    {
        screen: "screen2",
        title: "Personal information",
        subTitle: "Fill the users personal information in the feilds below, accurately.",
        feild1: "First Name",
        feild2: "Last Name",
        displayName: true,
        feild3: "Email Address",
        feild4: "Confirm Email Address",
        confirmEmail: true,
        prevButton: "previous",
        nextButton: "next",
    },
    {
        screen: "screen3",
        title: "Security",
        subTitle: "Create a reliable Passcode.",
        feild1: "Password",
        feild2: "Confirm Password",
        displayName: true,
        confirmPassCode: true,
        prevButton: "previous",
        nextButton: "next",
    }
]

export default withRouter(windowSize(AddUser));