import React from 'react'
// import logo from './logo.svg';
import '../../../css/overall.css';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import { Gallery, Item } from 'react-photoswipe-gallery'
import Thumbnail from './thumbnail'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
const link = "https://www.imesbenin.com"
// import { MGB } from './mgb'
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";



class Video extends React.Component {

  constructor() {
    super();
    this.state = {
      mediaFiles: [],
      loading: "true",
      speed: 1,
    }
  }

  timeOutSpinAmin() {
    setTimeout(() => {
        this.setState({
            loading: "false"
          })
    }, 7000);
  }

  componentDidMount() {
    this.timeOutSpinAmin()

    const { mediaFiles } = this.props;
    setTimeout(() => {
      const newData = mediaFiles.filter(item => item.format === "vid");
      this.setState({
        mediaFiles: newData,
        loading: "false"
      })
    }, 400);
  }

  render() {
    const { webHeight, Header } = this.props
    const { mediaFiles, loading, speed } = this.state

    return (
      <>
        <div class="row TabScreen" style={{ height: webHeight, overflowY: "scroll" }}>
          {
            mediaFiles.length >= 1 ?
              <Gallery>
                {mediaFiles.map((item) =>
                  <Thumbnail item={item} link={link} />
                )}
              </Gallery>
              :
              <div class="loadingMedia_">
                {
                  loading === "true" ?
                  <i class="fa fa-spinner LM_icon " style={{ animation: `spin ${speed}s linear infinite`}}></i>
                    :
                    <div class="LM_child">
                      <i class="fa fa-gears LM_icon"></i>
                      <p class="LM_p">No {Header} Found</p>
                    </div>
                }
              </div>
          }
        </div>
      </>
    );
  }

}

export default windowSize(Video);