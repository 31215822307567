import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import { withRouter } from "react-router";
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import '../css/overall.css';
import { words } from '../../trans'
import Comments from "./comments";
import NextToPlay from "./nextToPlay";
import VideoPlugin from "./VideoPlugin";
import Vimeo from '@u-wave/react-vimeo';

class LandingPage extends React.Component {
  constructor() {
    super();
    this.state = {
      page: "",
      pageLoading: true,
      da: "",
      text: "",
      subtext: "",
      playing: "false",
      playingProgress: 0,
      duration: 0,
      video: null,
    }
    this.WV_VRef = null;
  }
  componentDidMount() {
    let id = this.props.match.params;
    if (id.id) {
      const rV = id.id.replace(/\[/g, '"'); const rV_ = rV.replace(/\]/g, '"');
      const doom = JSON.parse(rV_); const config = { actn: doom.actn, da: doom.da }
      this.startProcess(config);
    } else {
      this.setTo404();
    }
  }
  componentWillUnmount() {
    this.clearInterval()
  }
  clearInterval = () => {
    if (this.progressInterval) {
      clearInterval(this.progressInterval)
    }
  }
  fancyTimeFormate = (value) => {
    var hrs = ~ ~(value / 3600);
    var mins = ~ ~((value % 3600) / 60);
    var secs = ~ ~value % 60;

    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;

  }

  setTo404 = () => {
    this.setState({
      page: "is404",
      pageLoading: false,
    })
  }
  startProcess = (config) => {
    const { actn, da } = config;
    if (actn === "VE") {
      this.startVEmail(da);
    } else if (actn === "WV") {
      this.startWVideo(da);
      this.getVprogress()
      document.body.onkeyup = (e) => {
        if (e.keyCode == 32) {
          this.watch_Video()
        }
      }
    } else if (actn === "QR") {
      this.fetchStudent(da)
    }
  }

  fetchStudent = (e) => {
    const value = e
    if (value.slice(0,7) === "License") {
      const certType = value.slice(0,7);
      const nameID = value.substring(7);
      const da = {certType, nameID}
      this.oppr_addCertsToDB(da);
    } 
  }

  oppr_addCertsToDB = (e) => {
    const {certType, nameID} = e;
    alert("certificate dataBase: " + certType + ", name id: " + nameID);
  }

  startVEmail = (e) => {
    this.setState({
      pageLoading: true,
      page: "isVEP",
      da: e,
    })
    setTimeout(() => {
      this.verifyEmail(e);
    }, 600);
  }
  verifyEmail = (e) => {
    fetch("https://www.imesbenin.com/server/ve.php", { // URL
      body: JSON.stringify(this.state), // data you send.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // headers: {
      //   'content-type': 'application/json'
      // },
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      redirect: 'follow', // *manual, follow, error
      referrer: 'no-referrer', // *client, no-referrer
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        // alert(responseJson);
        if (responseJson === "email not found") {
          this.setState({
            pageLoading: false,
            text: "Sorry, This Email doesn't belong to any account",
            subtext: "be sure you inputed the right link. This could also occur if the account, which was registered with this email, no longer exist ",
          })
        } else if (responseJson === "done") {
          this.setState({
            pageLoading: false,
            text: "Email Verified",
            subtext: "congratulations on securing your account, now you can have access to account settings. Click one of the links bellow, of your choice, to continue ",
          })
        } else {
          this.setState({
            pageLoading: false,
            text: "Encountered some errors",
            subtext: "This could be a problem on our end, or your link got broken",
          })
        }
      })
      .catch((error) => {
        this.setState({
          pageLoading: false,
          text: "Unable to verify Email",
          subtext: "Make sure you have internet connection, or your link isn't broken",
        })
      });
  }

  startWVideo = (v) => {
    this.setState({
      pageLoading: true,
      page: "isWVP",
      da: v,
    })
    setTimeout(() => {
      this.getVideoInfo(v)
    }, 2000);
    // setTimeout(() => {
    //   this.watch_Video(v);
    // }, 600);
  }
  getVideoInfo = (e) => {
    fetch("https://www.imesbenin.com/server/getVideoInfo.php", { // URL
      body: JSON.stringify(this.state), // data you send.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // headers: {
      //   'content-type': 'application/json'
      // },
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      redirect: 'follow', // *manual, follow, error
      referrer: 'no-referrer', // *client, no-referrer
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        // alert(JSON.stringify(responseJson));
        this.setState({
          video: responseJson
        })
      })
      .catch((error) => {
        // alert(error);
        // })
        this.getVideoInfo()
      });
  }
  watch_Video = (v) => {
    const { playing } = this.state
    if (playing === "false") {
      this.setState({
        playing: "true"
      })
      this.WV_VRef.play()
    } else if (playing === "true") {
      this.setState({
        playing: "false"
      })
      this.WV_VRef.pause()
    }
  }
  getVprogress = () => {
    if (this.progressInterval) {
      
    } else {
      this.progressInterval = setInterval(() => {
        if (this.WV_VRef) {
          this.setState({
            playingProgress: this.WV_VRef.currentTime
          })
          if (this.WV_VRef.ended) {
            this.setState({
              playing: "false",
            })
          }
        } else {
          this.clearInterval()
        }
      }, 100);
    }
  }
  setVprogress = (actn) => {
    const { playingProgress } = this.state
    if (actn === "foward") {
      if (this.WV_VRef) {
        this.WV_VRef.currentTime = playingProgress + 10;
      }
    } else if (actn === "backward") {
      if (this.WV_VRef) {
        this.WV_VRef.currentTime = playingProgress - 10;
      }
    }
  }


  render404 = () => {
    return (
      <>
        <h1>404</h1>
        <p>page not found</p>
      </>
    )
  }
  renderVEP = (config) => {
    const { pageLoading, text, subtext } = this.state;
    const { trans, user, userData, showSpeech } = config;
    return (
      <div class="lndP_box">
        {pageLoading === true ?
          <i class="fa fa-spinner LM_icon " style={{ animation: `spin ${1}s linear infinite` }}></i>
          :
          <>
            <h3>{text}</h3>
            <p>{subtext}</p>
            <div class="leavePage_links">
              <Link to="/" class="leavePage_l ">{trans.home}</Link>
              {user === "" ? <Link to="/schoolPortal" class="leavePage_l important">{trans.Sp}</Link> : null}
              <Link to="/ministersNetwork" class="leavePage_l ">{trans.MN}</Link>
              <a class="leavePage_l" onClick={() => { showSpeech() }}>{trans.about}</a>
              {user === "" ? null : <Link to="/onlineClasses" class="leavePage_l important">{trans.onlineCls}</Link>}
              <Link to="/gallery" class="leavePage_l ">{trans.gallery}</Link>
            </div>
          </>
        }
      </div>
    )
  }
  renderWVP = () => {
    const { playing, playingProgress, duration, video } = this.state;
    const vid = video ? video : [];
    return (
      <div class="lndP_box_WV">

        <div class="col-md-9 WV_box">
          <div class="WV_video_holder">
            <div class="WV_video_">
              {
                video ?
                  // <video
                  //   class="clsCardsIMG_img"
                  //   id="myVideo"
                  //   style={{
                  //     objectFit: "contain",
                  //     backgroundColor: "black",
                  //     padding: 0, paddingBottom: 0, paddingTop: 0,
                  //     transition: "0.4s", 
                  //   }}
                  //   onLoadedMetadata={e => {
                  //     this.setState({
                  //       duration: e.target.duration
                  //     });
                  //     this.getVprogress()
                  //   }}
                  //   ref={vidRef => this.WV_VRef = vidRef}
                  // controls
                  // >
                  //   <source src={"https://www.imesbenin.com" + vid.url} type="video/mp4" />
                  // </video>
                  <Vimeo video="609871656" 
                  // autoplay={true} 
                   style={{ width: 2000, height: 400}}/>
                  :
                  <div
                    style={{
                      height: "100%", width: "100%", display: "flex",
                      justifyContent: "center", alignItems: "center"
                    }}
                  >
                    <i class="fa fa-spinner LM_icon " style={{ animation: `spin ${1}s linear infinite` }}></i>
                  </div>
              }
              {/* <div style={dStyle.video_vid2}></div> */}
            </div>
            {/* <VideoPlugin
              playing={playing} playingProgress={playingProgress}
              watch_Video={() => { this.watch_Video() }} duration={duration}
              setVprogress={this.setVprogress}
            /> */}
          </div>

          <div class="WV_video_title">
            <h2>{vid.title}</h2>
          </div>
          <div class="WV_video_sub_title">
            <p>{vid.description}</p>
            {/* <p>{this.fancyTimeFormate(playingProgress)} of {this.fancyTimeFormate(duration)}</p> */}
          </div>

          <div class="WV_comnt_holder">
            <Comments />
          </div>

        </div>


        <div class="col-md-3 WV_box">
          <NextToPlay />
        </div>

      </div>
    )
  }

  render() {
    const { lang, user, userData, showSpeech } = this.props;
    const { page, pageLoading } = this.state
    const height = window.innerHeight
    const trans = {
      home: lang === "en" ? words.home.en : words.home.fr,
      Sp: lang === "en" ? words.Sp.en : words.Sp.fr,
      MN: lang === "en" ? words.MN.en : words.MN.fr,
      about: lang === "en" ? words.about.en : words.about.fr,
      onlineCls: lang === "en" ? words.onlineCls.en : words.onlineCls.fr,
      gallery: lang === "en" ? words.gallery.en : words.gallery.fr,
      copyrite: lang === "en" ? words.copyrite.en : words.copyrite.fr,
      establisd_y: lang === "en" ? words.establisd_y.en : words.establisd_y.fr,
      talk_t_s: lang === "en" ? words.talk_t_s.en : words.talk_t_s.fr,
      you_can_now: lang === "en" ? words.you_can_now.en : words.you_can_now.fr,
      click_h_t: lang === "en" ? words.click_h_t.en : words.click_h_t.fr,
      get_i_t: lang === "en" ? words.get_i_t.en : words.get_i_t.fr,
      contact_u_share_y: lang === "en" ? words.contact_u_share_y.en : words.contact_u_share_y.fr,
    }
    const config = { trans, user, userData, showSpeech };
    return (
      <div class="landingPage" style={{ height }}>
        {
          page === "is404" ?
            this.render404(config)
            :
            page === "isVEP" ?
              this.renderVEP(config)
              :
              page === "isWVP" ?
                this.renderWVP(config)
                :
                null
        }
      </div>
    );
  }
}

export default withRouter(windowSize(LandingPage));

const dStyle = {
  video_vid: {
    height: "75%",
    width: "97%",
    position: "absolute",
  },
  video_vid2: {
    height: "65%",
    width: "96%",
    // backgroundColor: "rgba(0,0,0,0.7)",
    // backgroundColor: "red",
    position: "absolute",
    top: 37,
    zIndex: 3
  }
}