import React from 'react'
// import logo from './logo.svg';
import '../../../css/overall.css';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import { words } from '../../../../trans'
import NavigationBar from '../../../navigationBar'
import ExtraInfo from '../../../extraInfo'
import { withRouter } from "react-router";
import Vimeo from '@u-wave/react-vimeo';
import * as _ from 'lodash'
import { CLASSES_ } from './classes'
const fn_link = "https://www.imesbenin.com/server/fetchClasses.php"

const screenWidth = window.innerWidth
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";



class OnlineClasses extends React.Component {

    constructor() {
        super();
        this.state = {
            onScreen: false,
            showInfo: false,
            showInfo_ext: false,
            showInfo_: false,
            showInfo_ext_: false,
            item: [],
            item_: [],
            showNave: true,
            showMore: false,
            CLASSES: [],
            paid: false,
            animateAlbumTitle: "",
            title: ""
        }
    }

    makeOnscreenTrue() {
        setTimeout(() => {
            this.setState({
                onScreen: true
            })
        }, 10);
    }

    showInfo(item) {
        if (screenWidth > 991) {
            // this.setState({
            //     showInfo: true,
            //     showInfo_ext: true,
            //     item
            // })
        }
        this.setState({
            showInfo: true,
            showInfo_ext: true,
            item
        })
    }

    hideInfo() {
        this.setState({
            showInfo_ext: false,
        })
        setTimeout(() => {
            this.setState({
                showInfo: false,
                item: []
            })
        }, 600);
    }

    showInfo_(item_, title) {
        this.setState({
            showInfo_: true,
            showInfo_ext_: true,
            item_,
            title
        })
        alert("hmmm")
    }

    hideInfo_() {
        this.setState({
            showInfo_ext_: false,
        })
        setTimeout(() => {
            this.setState({
                showInfo_: false,
                item_: [],
                title: ""
            })
        }, 600);
    }

    componentDidMount() {
        this.makeOnscreenTrue();
        // this.fetchImages()
        this.getInfo()
        setTimeout(() => {
            this.showNave()
        }, 200);
    }

    getInfo = (e) => {
        const { user, userData } = this.props
        const data = { da: user };
        fetch("https://www.imesbenin.com/server/getInfo.php", { // URL
            body: JSON.stringify(data), // data you send.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // headers: {
            //   'content-type': 'application/json'
            // },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then((response) => response.json())
            .then(async (responseJson) => {
                // alert(JSON.stringify(responseJson));
                // this.setState({
                //     userInfo: responseJson
                // })
                if (responseJson === "") {
                    userData.logOut()
                    this.props.history.push("/schoolPortal");
                } else {
                    this.setState({
                        userInfo: responseJson
                    })
                    if (responseJson.plan === "") {
                        this.setState({
                            paid: false
                        })
                    } else {
                        this.setState({
                            paid: true
                        })
                    }
                }
            })
            .catch((error) => {
                // alert(error);
                // })
            });
    }

    fetchImages() {
        fetch(fn_link, { // URL
            body: JSON.stringify(this.state), // data you send.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // headers: {
            //   'content-type': 'application/json'
            // },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then((response) => response.json())
            .then(async (responseJson) => {
                const responseJson_ = responseJson
                const _data = _.chain(responseJson_).
                    groupBy((item) => {
                        const groupBy = item.common;
                        return groupBy
                    })
                    .map((value, key) => ({ title: key, data: value }))
                    .value()
                this.setState({
                    CLASSES: _data
                })
                // alert(JSON.stringify(services))
            })
            .catch((error) => {
                // alert(error);
                const responseJson_ = CLASSES_
                const _data = _.chain(responseJson_).
                    groupBy((item) => {
                        const groupBy = item.common;
                        return groupBy
                    })
                    .map((value, key) => ({ title: key, data: value }))
                    .value()
                this.setState({
                    CLASSES: _data
                })
            });
    }

    showNave() {
        this.setState({
            showNave: !this.state.showNave
        })
    }

    showMore() {
        this.setState({
            showMore: !this.state.showMore
        })
    }

    makeImage = (url) => {
        const string = url;
        const madeJPG = string.replace(".mp4", ".png");
        const uri = "https://www.imesbenin.com" + madeJPG;
        return uri;
    }

    getOut = () => {
        this.props.history.push("/login");
    }

    renderClasses = (DATA_) => {
        const DATA = DATA_
        const { showMore, showNave, paid, showInfo_ext_, title } = this.state;
        const shifAnim = showInfo_ext_ === true ? "bounceInUp" : "bounceOutDown"
        return (
            <div class={"cls_showInfo_ animated " + shifAnim}>
                <div class="row"></div>
                
                <div class="row"></div>
            </div>
        )
    }

    setAnimateAlbum = (title) => {
        this.setState({
            animateAlbumTitle: title
        })
    }
    outAnimateAlbum = (title) => {
        this.setState({
            animateAlbumTitle: ""
        })
    }

    render() {
        const { showSpeech, showRegBox, showMIFBox, windowHeight, lang, user, userData } = this.props
        const { onScreen, showInfo, showInfo_ext, CLASSES } = this.state
        const webHeight = window.innerWidth > 991 ? window.innerHeight - 60 : window.innerHeight - 0;
        const percent43 = webHeight * 43 / 100;
        const hotlineBoxHeight = percent43 * 60 / 100;
        const hotline_hotline_dpHeight = hotlineBoxHeight * 48 / 100
        const hotline_hotline_dp = {
            height: hotline_hotline_dpHeight,
            width: hotline_hotline_dpHeight
        }
        const hotline_hotline_dp_image = {
            fontSize: hotline_hotline_dpHeight - 15,
        }
        const col_md = onScreen === true ? "col-md-10" : "col-md-7"
        const shifAnim = showInfo_ext === true ? "fadeIn" : "fadeOut"
        const item2 = this.state.item

        const { showMore, showNave, paid, animateAlbumTitle, showInfo_, item_ } = this.state;
        const icnClass = showNave === true ? "moreOptionsHolder_Icon_rot" : "";
        const icnMore = showMore === true ? "moreOptionsHolder_Icon_rot2" : "";
        const aPercentage = window.innerHeight * 20 / 100
        const navHeight = showNave === true ? webHeight - aPercentage : 10;
        const navOpacity = showNave === true ? 1 : 0;
        const navBlocker = showNave === true ? "none" : "flex";
        const topMr = showMore === true ? -30 : webHeight;
        const topMore = window.innerWidth > 991 ? 0 : topMr;
        const topOpac = showMore === true ? 1 : 0;
        const topOpacity = window.innerWidth > 991 ? 1 : topOpac;
        const marginTop = window.innerWidth > 991 ? 30 : 0;


        const trans = {
            home: lang === "en" ? words.home.en : words.home.fr,
            Sp: lang === "en" ? words.Sp.en : words.Sp.fr,
            MN: lang === "en" ? words.MN.en : words.MN.fr,
            about: lang === "en" ? words.about.en : words.about.fr,
            onlineCls: lang === "en" ? words.onlineCls.en : words.onlineCls.fr,
            gallery: lang === "en" ? words.gallery.en : words.gallery.fr,
            copyrite: lang === "en" ? words.copyrite.en : words.copyrite.fr,
            establisd_y: lang === "en" ? words.establisd_y.en : words.establisd_y.fr,
            talk_t_s: lang === "en" ? words.talk_t_s.en : words.talk_t_s.fr,
            you_can_now: lang === "en" ? words.you_can_now.en : words.you_can_now.fr,
            click_h_t: lang === "en" ? words.click_h_t.en : words.click_h_t.fr,
            get_i_t: lang === "en" ? words.get_i_t.en : words.get_i_t.fr,
            contact_u_share_y: lang === "en" ? words.contact_u_share_y.en : words.contact_u_share_y.fr,
        }

        if (!user) {
            return (
                <>
                    <div class="extra_l cool_bg"></div>
                    <div class="extra_l"></div>

                    <div class="row" style={{ marginTop }}>
                        <div class="col-md-12 newPageCorrector">

                            <NavigationBar
                                Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
                                active={{ a: "", b: "", c: "", d: "active", e: "" }}
                                clas="col-md-2 navigation-bar"
                                style={{ height: webHeight }}
                                user={user} userData={userData}
                            />

                            <div className={"child-body cls_child_body animeTransition " + col_md} style={{ height: webHeight, alignItems: "center", overflowY: "scroll" }}>

                                <NavigationBar
                                    Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
                                    active={{ a: "", b: "", c: "", d: "active", e: "" }}
                                    clas="col-md-2 navigation-bar-iPhone"
                                    style={{
                                        height: navHeight, opacity: navOpacity,
                                        backgroundColor: "rgba(0,0,0,0.9)",
                                    }}
                                    user={user} userData={userData}
                                />


                                <div class="cls_search_holder">
                                    <div class="col-md-6 cls_search_holder_1">
                                        <h4 style={{ textTransform: "uppercase" }}>{trans.onlineCls}</h4>
                                    </div>
                                    <div class="col-md-6 cls_search_holder_2">
                                        <div class="textInput"></div>
                                        <div class="searchButton">
                                            <div class="searchIcon searchpng"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row"></div>
                                <h4 style={{ color: "white", opacity: 0.6, marginBottom: 0, paddingBottom: 0 }}>
                                    Online Lectures would appear here
                                </h4>
                                <p style={{ color: "white", opacity: 0.6, fontSize: 12 }}>
                                    classes begings first week of august
                                </p>
                                <div
                                    class="uline12 mgb_line"
                                    style={{
                                        backgroundColor: "silver", width: "100%", opacity: 0.4,
                                        marginTop: 6,
                                        marginBottom: 20,
                                    }}></div>

                                <div class="row"></div>
                                {CLASSES.map((item) =>
                                    <div class="col-md-3 clsCards_holder"
                                        onClick={() => { this.showInfo_(item.data, item.title) }}
                                        onMouseOver={() => { this.setAnimateAlbum(item.title) }}
                                        onMouseOut={() => { this.outAnimateAlbum(item.title) }}
                                        style={{height: screenWidth >= 991 ? null : screenWidth * 0.43, zIndex: 1000}}
                                    >
                                        <div class="clsCards" style={{
                                            display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",
                                            backgroundColor: "rgba(255,255,255,1)"
                                        }}>

                                            <div style={{ height: 65, width: 65, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                {
                                                    animateAlbumTitle === item.title ?
                                                        <img style={{ height: 64, width: 64 }} src="assets/img/music_library.gif" />
                                                        :
                                                        <img style={{ height: 60, width: 60 }} src="assets/img/music_library.png" />
                                                }
                                            </div>
                                            <div class="clswriteup_holder">
                                                <h6 style={{ color: "black" }}>{item.title}</h6>
                                                <p style={{ color: "black", textAlign: "center", marginTop: 10 }}>{item.data.length}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div class="row"></div>
                                {/* <div class="col-md-6 clsCards_holder" style={{ height: 300, }}>
                                    <div class="clsCards">
                                        <div class="clsCardsIMG_holder" style={{ height: "80%", }}>
                                            <div class="clsCardsIMG cool_bg">
                                                <video
                                                    class="clsCardsIMG_img"
                                                    id="myVideo"
                                                    style={{
                                                        objectFit: "none",
                                                        backgroundColor: "black"
                                                    }}
                                                    controls
                                                >
                                                    <source src="assets/img/vid1.mp4" type="video/mp4" />
                                                </video>
                                            </div>
                                        </div>
                                        <div class="clswriteup_holder" style={{ height: "20%", }}>
                                            <h6>Most Recent Event</h6>
                                            <p>IMES 2021 Graduation and consecration Event</p>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div id="videoPanel">
                                    <video class="videomove" id="myVideo">
                                        <source src="semaya.mp4" type="video/mp4" />
                                    </video>
                                </div> */}

                                <div class="row"></div>
                                <div
                                    class="uline12 mgb_line"
                                    style={{
                                        backgroundColor: "silver", width: "100%", opacity: 0.1,
                                        marginTop: 20,
                                        marginBottom: 20,
                                    }}></div>

                                {/* <h1 style={{ color: "white", opacity: 0.6, textAlign: "center" }}>
                                    Lectures would soon begin
                                </h1>
                                <h1 style={{ color: "white", opacity: 0.6, textAlign: "center" }}>
                                    .
                                </h1> */}



                            </div>



                            <ExtraInfo
                                Data={{ showSpeech, trans, showQRCBox: this.props.showQRCBox, webHeight }}
                                clas="col-md-3 extra-info"
                                style={{ height: webHeight + 60, top: topMore, opacity: topOpacity, }}
                            />
                            <div class="moreOptionsHolder">
                                <div class={"moreOptionsHolder_Icon " + icnClass + " andPlus"} onClick={() => { this.showNave() }}></div>
                                <p>|</p>
                                <div class={"moreOptionsHolder_Icon " + icnMore + " andMenu"} onClick={() => { this.showMore() }}></div>
                            </div>

                        </div>
                    </div>

                    {showInfo_ ? this.renderClasses(item_) : null}

                    {showInfo === true ?
                        <div className={"cls_showInfo animated " + shifAnim} style={{ overflowY: "scroll" }}>
                            <div class="col-md-12 clsCards2 clsCards3">
                                <div class="clsCardsIMG_holder2" style={{ height: screenWidth * 1 }}>
                                    {/* <div class="clsCardsIMG2 cool_bg">
                                        <img class="clsCardsIMG_img2" src={item2.img} />
                                        <div class="clsPlayIcon2">
                                            <ReactImageTint
                                                src="assets/img/androidPlay.png"
                                                color="white"
                                            />
                                        </div>
                                    </div> */}
                                    {
                                        showInfo === true ?
                                            <Vimeo video={item2.id_}
                                                // autoplay={true} 
                                                class="clsCardsIMG2 cool_bg" />
                                            :
                                            null
                                    }
                                </div>
                                <a onClick={() => { this.hideInfo() }} style={{ cursor: "pointer" }} class="a">Click Here To Close</a>
                            </div>
                            {/* <div class="col-md-6 logIn_holder clsCards3_">
                                {/* <h4>You must be logged-in before you can access this video</h4>
                                <div class="log_in_box">
                                    <p>Username</p>
                                    <div class="log_in_box_input"></div>
                                    <p>Password</p>
                                    <div class="log_in_box_input"></div>
                                    <div class="submit">
                                        <a class="logIn">Log In</a>
                                    </div>
                                </div>
                                <p>Don't have an account yet ? <Link to="/schoolPortal" class="span">Register</Link></p> */}
                            {/*<h4>{item2.title}</h4>
                                <div class="log_in_box">
                                    <p>Description</p>
                                    <p style={{ fontSize: "smaller" }}>{item2.description}</p>
                                </div>
                                <a onClick={() => { this.hideInfo() }} style={{ cursor: "pointer" }} class="a">Click Here To Close</a>
                            </div> */}
                        </div>
                        :
                        null
                    }
                </>
            );
        } else {
            // this.getOut()
            return (
                <>
                    <div class="extra_l cool_bg"></div>
                    <div class="extra_l"></div>
                    <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <h1>This Page Can't be Accessable to this User</h1>
                    </div>
                </>
            )
        }
    }

}

export default withRouter(windowSize(OnlineClasses));