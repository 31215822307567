import React from 'react'
// import logo from './logo.svg';
import '../css/overall.css';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import TimeAgo from 'timeago-react';
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";
const cmts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
class VideoPlugin extends React.Component {
    constructor() {
        super();
        this.state = {
            feild_1: "",
            progressWidth: null
        }
    }
    componentDidMount(){
        const progressWidth = this.progressRef.offsetWidth;
        this.setState({
            progressWidth
        })
    }
    fancyTimeFormate = (value) => {
      var hrs = ~ ~(value / 3600);
      var mins = ~ ~((value % 3600) / 60);
      var secs = ~ ~value % 60;
  
      var ret = "";
  
      if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
      }
      ret += "" + mins + ":" + (secs < 10 ? "0" : "");
      ret += "" + secs;
      return ret;
  
    }
    render() {
        const { feild_1, progressWidth } = this.state
        const { playing, watch_Video, playingProgress, duration, setVprogress } = this.props
        const progWidth = progressWidth ? progressWidth : 100
        const progPerct = progressWidth ? null : "%"
        const progress_ = playingProgress / duration * progWidth;
        const progress = Math.round(progress_);
        const fa = playing === "true" ? "pause" : "play"
        return (
            <div class="WV_controls">
                <div class="WV_controls_btn">
                    <div class="WV_controls_btn" style={{display:"flex", justifyContent:"flex-start", width: "40%"}}>
                    <div class="WV_player_btn" onClick={() => { watch_Video() }}>
                        <i class={"fa fa-" + fa + "-circle"}></i>
                    </div>
                    <div class="setVprogress" onClick={() => {setVprogress("backward")}}><p>10</p><i class="fa fa-undo"></i></div>
                    <div class="setVprogress" onClick={() => {setVprogress("foward")}}><i class="fa fa-repeat"></i><p>10</p></div>
                    </div>
                    <p>{this.fancyTimeFormate(playingProgress)} : {this.fancyTimeFormate(duration)}</p>
                </div>
                <div ref={ref => this.progressRef = ref} class="progressHolder">
                    <div class="WV_progress" style={{width: progress+progPerct}}></div>
                </div>
            </div>
        )
    }
}

export default windowSize(VideoPlugin);

