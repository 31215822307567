import React from 'react'
// import logo from './logo.svg';
import './css/overall.css';
import Screens from './screens';
import windowSize from 'react-window-size';
import { ReactImageTint } from 'react-image-tint'
import IconTint from 'react-icon-tint'
import { Link } from "react-router-dom";
import { words } from '../trans'
// import "./https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.min.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.css";
// import "./https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";

class NavigationBar extends React.Component {
    render() {
        const { showSpeech, webHeight, showQRCBox, trans } = this.props.Data;
        const { a, b, c, d, e } = this.props.active;
        const {style, clas} = this.props;
        const user= this.props.user 
        const userData= this.props.userData 
        return(
            <div class={clas} style={style}>
            <div class="logo"></div>
            <p class="navigation-credits alt">{trans.establisd_y}</p>
            <Link to="/" class={"navigation-item " + a}>{trans.home}</Link>
            {user === "" ? <Link to="/schoolPortal" class={"navigation-item important " + b}>{trans.Sp}</Link> : null}
            <Link to="/ministersNetwork" class={"navigation-item " + c}>{trans.MN}</Link>
            <a class="navigation-item" onClick={() => { showSpeech() }}>{trans.about}</a>
            {/* {user === "" ? null : <Link to="/onlineClasses" class={"navigation-item important " + d}>{trans.onlineCls}</Link>} */}
            <Link to="/onlineClasses" class={"navigation-item important " + d}>{trans.onlineCls}</Link>
            <Link to="/gallery" class={"navigation-item " + e}>{trans.gallery}</Link>
            <a href="#" class="navigation-item_" onClick={() => { showQRCBox() }}>
              <ReactImageTint
                src="assets/img/qrCode.png"
                color="white"
                class="barCode"
                maxWidth="30"
                maxHeight="30"
              />
            </a>

            <p class="navigation-credits">{trans.copyrite}</p>
          </div>
        )
    }
}

export default windowSize(NavigationBar);